import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import WebViewer from '@pdftron/webviewer';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  Grid,
} from "@mui/material";
import moment from "moment";
import VersionsSelect from "components/SetsView/versionsSelect";
import FileVisibilityDialog from "./FileVisibilityDialog";
import Draggable from "react-draggable";
import CompareFilesStyles from "./CompareFilesStyles";
import "./comparefile.css"
import ZoomButtons from "components/ZoomButtons";
import { APRYSE_L_KEY } from "api";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const CompareFiles = ({ open, filesToCompare, handleClose, setCompareFiles, selectedFiles, callFrom, credential, viewFolderFiles }) => {
  const [showZoomActions, setShowZoomAction] = useState(false);
  const [syncButton, setSyncButton] = useState(true);
  const [comparedDocuments, setComparedDocuments] = useState(selectedFiles);
  const [sheetsForReplace, setSheetsForReplace] = useState(comparedDocuments);
  const containerRef = React.useRef(null);
  const containerSide = React.useRef(null);
  const [sideBy, setSideBy] = useState(false);
  const [showViewer, setShowViewer] = useState(true);
  const [replaceDocument, setReplaceDocument] = useState(false);
  const [openFileVisibility, setOpenFileVisibility] = useState(false);
  const [instance, setInstance] = useState();
  const [currentZoomLevel, setCurrentZoomLevel] = useState(1);
  const [currentZoomLevel1, setCurrentZoomLevel1] = useState(1);
  const handleFileVisibilityDialogClose = () => {
    setOpenFileVisibility(!openFileVisibility);
  };
  const openFileVisibilityDraggable = () => {
    setOpenFileVisibility(true);
  };
  useEffect(() => {
    setTimeout(async () => {
      if (sideBy) {
        _loadFileside(comparedDocuments);
        setOpenFileVisibility(false);
      } else {
        _loadFile(comparedDocuments);
        setOpenFileVisibility(true);
      }
    }, 100);
  }, [sideBy]);
  useEffect(() => {
    setShowViewer(true);
  }, [comparedDocuments]);
  // load first file
  const _loadFile = async (comparedDocuments) => {
    setCurrentZoomLevel(false);
    if (containerRef.current) {
      const disElement = ['header', 'toolbarGroup-View',
        'toolsHeader',
        'toolbarGroup-Annotate',
        'toolbarGroup-Shapes',
        'toolbarGroup-Insert',
        'toolbarGroup-Measure',
        'toolbarGroup-Edit',
        'toolbarGroup-FillAndSign',
        'toolbarGroup-Forms',
        'toolbarGroup-Redact'];
      await WebViewer(
        {
          fullAPI: true,
          disabledElements: disElement,
          path: "/webviewer/public",
          licenseKey: APRYSE_L_KEY, // sign up to get a free trial key at https://dev.apryse.com,
          disableObjectURLBlobs: true,
          backendType: WebViewer.BackendTypes.ASM,
        },
        containerRef.current
      ).then(async (instance) => {
        setInstance(instance);
        setShowZoomAction(true);
        instance.UI.disableElements(disElement);
        const { PDFNet, documentViewer, Tools } = instance.Core;
        documentViewer.addEventListener("zoomUpdated", (zoom) => {
          setCurrentZoomLevel(zoom);
        });
        await PDFNet.initialize();
        const scrollView = documentViewer.getScrollViewElement();
        const simulateControlScroll = (event) => {
          if (documentViewer.getToolMode().name === "Pan") {
            scrollView.style.overflow = 'hidden';
            if(!event.ctrlKey){
              // Create a new wheel event with the Control key pressed
              const newEvent = new WheelEvent('wheel', {
                deltaX: event.deltaX,
                deltaY: event.deltaY,
                deltaZ: event.deltaZ,
                deltaMode: event.deltaMode,
                clientX: event.clientX,
                clientY: event.clientY,
                screenX: event.screenX,
                screenY: event.screenY,
                pageX: event.pageX,
                pageY: event.pageY,
                ctrlKey: true,  // Simulate Control key pressed
                shiftKey: event.shiftKey,
                altKey: event.altKey,
                metaKey: event.metaKey,
                bubbles: event.bubbles,
                cancelable: event.cancelable,
                composed: event.composed
              });
              scrollView.dispatchEvent(newEvent);
            }
          }else {
            scrollView.style.overflow = 'auto';
          }
        };
        // Use capturing phase to ensure we catch the event early
        scrollView.addEventListener('wheel', simulateControlScroll, { capture: true, passive: false });
        const getDocument = async (url) => {
          console.log("url ::", url);
          const newDoc = await instance.Core.createDocument(url);
          return await newDoc.getPDFDoc();
        };

        const [doc1, doc2] = await Promise.all([
          getDocument(comparedDocuments[0].url),
          getDocument(comparedDocuments[1].url)
        ])
        const getPageArray = async (doc) => {
          const arr = [];
          const itr = await doc.getPageIterator(1);

          for (itr; await itr.hasNext(); itr.next()) {
            const page = await itr.current();
            arr.push(page);
          }
          const panAnnotation = documentViewer.getTool(Tools.ToolNames.PAN);
          documentViewer.setToolMode(panAnnotation);
          return arr;
        }

        const [doc1Pages, doc2Pages] = await Promise.all([
          getPageArray(doc1),
          getPageArray(doc2)
        ]);

        const newDoc = await PDFNet.PDFDoc.create();
        newDoc.lock();

        // we'll loop over the doc with the most pages
        const biggestLength = Math.max(doc1Pages.length, doc2Pages.length)

        // we need to do the pages in order, so lets create a Promise chain
        const chain = Promise.resolve();

        for (let i = 0; i < biggestLength; i++) {
          chain.then(async () => {
            const page1 = doc1Pages[i];
            const page2 = doc2Pages[i];

            // handle the case where one document has more pages than the other
            if (!page1) {
              page1 = new PDFNet.Page(0); // create a blank page
            }
            if (!page2) {
              page2 = new PDFNet.Page(0); // create a blank page
            }
            return newDoc.appendVisualDiff(page1, page2, null)
          })
        }

        await chain; // wait for our chain to resolve
        newDoc.unlock();

        // display the document!
        // instance is a global variable thats automatically defined inside the config file.
        instance.UI.loadDocument(newDoc);
        const zOutEle = document.getElementById('zoom-out-button');
            if(zOutEle){
              if(!zOutEle.dataset?.clickBound || zOutEle.dataset.clickBound != 'yes'){
                zOutEle.dataset.clickBound = 'yes';
                zOutEle.addEventListener('click', () => {
                  let preLevel = documentViewer.getZoomLevel();
                  if(preLevel > 0.1){
                    documentViewer.zoomTo(documentViewer.getZoomLevel() - 0.25);
                  }else{
                    documentViewer.zoomTo(0.1);
                  }
                });
              }
            }
            const zInEle = document.getElementById('zoom-in-button');
            if(zInEle){
              if(!zInEle.dataset?.clickBound || zInEle.dataset.clickBound != 'yes'){
                zInEle.dataset.clickBound = 'yes';
                zInEle.addEventListener('click', () => {
                  documentViewer.zoomTo(documentViewer.getZoomLevel() + 0.25);
                });
              }
            }
      });
    }

  };

  const _loadFileside = async (comparedDocuments) => {
    setCurrentZoomLevel(false);
    if (containerSide.current) {
      const disElement = ['header', 'toolbarGroup-View',
        'multiViewerSyncButton',
        'toolsHeader',
        'toolbarGroup-Annotate',
        'toolbarGroup-Shapes',
        'toolbarGroup-Insert',
        'toolbarGroup-Measure',
        'toolbarGroup-Edit',
        'toolbarGroup-FillAndSign',
        'toolbarGroup-Forms',
        'toolbarGroup-Redact',
        'compareResizeBar',
        'header1',
        'leftPanel'];
      await WebViewer({
        fullAPI: true,
        disabledElements: disElement,
        disableMultiViewerComparison: true, // Disable default compare buttons
        path: "/webviewer/public",
        css: "/compare.css",
        licenseKey: APRYSE_L_KEY, // sign up to get a free trial key at https://dev.apryse.com,
        disableObjectURLBlobs: true,
        backendType: WebViewer.BackendTypes.ASM,
      }, containerSide.current)
        .then((instance) => {
          setInstance(instance);
          const { PDFNet, documentViewer, Tools } = instance.Core;
          instance.UI.disableMultiViewerSync();
          instance.UI.disableElements(disElement);
          const { UI, Core } = instance;
          const { Annotations } = Core;
          const { Color } = Annotations;

          UI.addEventListener(UI.Events.MULTI_VIEWER_READY, () => {
            setCurrentZoomLevel(true);
            const [documentViewer1, documentViewer2] = Core.getDocumentViewers();
            const scrollView = documentViewer1.getScrollViewElement();
            const simulateControlScroll = (event) => {
              if (documentViewer1.getToolMode().name === "Pan") {
                scrollView.style.overflow = 'hidden';
                if(!event.ctrlKey){
                  // Create a new wheel event with the Control key pressed
                  const newEvent = new WheelEvent('wheel', {
                    deltaX: event.deltaX,
                    deltaY: event.deltaY,
                    deltaZ: event.deltaZ,
                    deltaMode: event.deltaMode,
                    clientX: event.clientX,
                    clientY: event.clientY,
                    screenX: event.screenX,
                    screenY: event.screenY,
                    pageX: event.pageX,
                    pageY: event.pageY,
                    ctrlKey: true,  // Simulate Control key pressed
                    shiftKey: event.shiftKey,
                    altKey: event.altKey,
                    metaKey: event.metaKey,
                    bubbles: event.bubbles,
                    cancelable: event.cancelable,
                    composed: event.composed
                  });
                  scrollView.dispatchEvent(newEvent);
                }
              }else {
                scrollView.style.overflow = 'auto';
              }
            };
            // Use capturing phase to ensure we catch the event early
            scrollView.addEventListener('wheel', simulateControlScroll, { capture: true, passive: false });

            const scrollView2 = documentViewer2.getScrollViewElement();
            const simulateControlScroll2 = (event) => {
              if (documentViewer2.getToolMode().name === "Pan") {
                scrollView.style.overflow = 'hidden';
                if(!event.ctrlKey){
                  // Create a new wheel event with the Control key pressed
                  const newEvent = new WheelEvent('wheel', {
                    deltaX: event.deltaX,
                    deltaY: event.deltaY,
                    deltaZ: event.deltaZ,
                    deltaMode: event.deltaMode,
                    clientX: event.clientX,
                    clientY: event.clientY,
                    screenX: event.screenX,
                    screenY: event.screenY,
                    pageX: event.pageX,
                    pageY: event.pageY,
                    ctrlKey: true,  // Simulate Control key pressed
                    shiftKey: event.shiftKey,
                    altKey: event.altKey,
                    metaKey: event.metaKey,
                    bubbles: event.bubbles,
                    cancelable: event.cancelable,
                    composed: event.composed
                  });
                  scrollView2.dispatchEvent(newEvent);
                }
              }else {
                scrollView2.style.overflow = 'auto';
              }
            };
            // Use capturing phase to ensure we catch the event early
            scrollView2.addEventListener('wheel', simulateControlScroll2, { capture: true, passive: false });

            documentViewer2.addEventListener("zoomUpdated", (zoom) => {
              setCurrentZoomLevel(zoom);
            });
            documentViewer1.addEventListener("zoomUpdated", (zoom) => {
              setCurrentZoomLevel1(zoom);
            });
            const startCompare = async () => {
              const shouldCompare = documentViewer1.getDocument() && documentViewer2.getDocument();
              if (shouldCompare) { // Check if both documents l
                instance.UI.enableMultiViewerSync(1);
                setShowZoomAction(true);
                const panAnnotation = documentViewer1.getTool(Tools.ToolNames.PAN);
                documentViewer1.setToolMode(panAnnotation);
                const panAnnotation2 = documentViewer2.getTool(Tools.ToolNames.PAN);
                documentViewer2.setToolMode(panAnnotation2);
                const iframeDoc = instance.UI.iframeWindow.document;
                var y = iframeDoc.getElementsByClassName('MultiViewer');
                var aNode = y[0];
                aNode.style.background = 'white';
                const header1 = iframeDoc.querySelector('[id="header1"]');
                const header2 = iframeDoc.querySelector('[id="header2"]');
                const container1 = iframeDoc.querySelector('[id="container1"]');
                const container2 = iframeDoc.querySelector('[id="container2"]');
                const documentContainer = iframeDoc.querySelector('[id="DocumentContainer1"]');
                header1.style.display = 'none';
                header2.style.display = 'none';
                container1.style.background = "#EAEAEA";
                container1.style.border = "2px solid #D8D8D8";
                container1.style.borderTop = "none";
                container1.style.marginRight = "10px";
                container1.style.maxWidth = "calc(50% - 10px)";
                container2.style.maxWidth = "calc(50% - 10px)";
                container2.style.marginLeft = "10px";
                container2.style.border = "2px solid #D8D8D8";
                container2.style.background = "#EAEAEA";
                container2.style.borderTop = "none";
                const cusCropB = document.getElementById('sync');
                // const sync = instance.UI.isMultiViewerSyncing();
                if (cusCropB) {
                  if (cusCropB.getAttribute('listener') !== 'true') {
                    cusCropB.setAttribute('listener', 'true');
                    cusCropB.addEventListener('click', (e) => {

                      var sync1 = instance.UI.isMultiViewerSyncing();
                      if (sync1 == true) {
                        instance.UI.disableMultiViewerSync();
                        setSyncButton(false);
                      } else {
                        instance.UI.enableMultiViewerSync(2);
                        setSyncButton(true);
                      }
                    });
                  }

                }
                const cusCropA = document.getElementById('sync-1');
                if (cusCropA) {
                  if (cusCropA.getAttribute('listener') !== 'true') {
                    cusCropA.setAttribute('listener', 'true');
                    cusCropA.addEventListener('click', (e) => {

                      var sync1 = instance.UI.isMultiViewerSyncing();
                      if (sync1 == true) {
                        instance.UI.disableMultiViewerSync();
                        setSyncButton(false);
                      } else {
                        instance.UI.enableMultiViewerSync(1);
                        setSyncButton(true);
                      }
                    });

                  }

                }
              }

            }
            documentViewer1.addEventListener('documentLoaded', startCompare);
            documentViewer2.addEventListener('documentLoaded', startCompare);
            documentViewer1.loadDocument(comparedDocuments[0].url);
            documentViewer2.loadDocument(comparedDocuments[1].url);
            const zOutEle = document.getElementById('zoom-out-button');
            console.log("zOutEle:", zOutEle);
            if(zOutEle){
              if(!zOutEle.dataset?.clickBound || zOutEle.dataset.clickBound != 'yes'){
                zOutEle.dataset.clickBound = 'yes';
                zOutEle.addEventListener('click', () => {
                  console.log("click work");
                  let preLevel = documentViewer2.getZoomLevel();
                  if(preLevel > 0.1){
                    documentViewer2.zoomTo(documentViewer2.getZoomLevel() - 0.25);
                  }else{
                    documentViewer2.zoomTo(0.1);
                  }
                });
              }
            }
            const zInEle = document.getElementById('zoom-in-button');
            if(zInEle){
              if(!zInEle.dataset?.clickBound || zInEle.dataset.clickBound != 'yes'){
                zInEle.dataset.clickBound = 'yes';
                zInEle.addEventListener('click', () => {
                  documentViewer2.zoomTo(documentViewer2.getZoomLevel() + 0.25);
                });
              }
            }
             const zOutEle1 = document.getElementById('zoom-out-button-1');
            if(zOutEle1){
              if(!zOutEle1.dataset?.clickBound || zOutEle1.dataset.clickBound != 'yes'){
                zOutEle.dataset.clickBound = 'yes';
                zOutEle1.addEventListener('click', () => {
                  let preLevel = documentViewer1.getZoomLevel();
                  if(preLevel > 0.1){
                    documentViewer1.zoomTo(documentViewer1.getZoomLevel() - 0.25);
                  }else{
                    documentViewer1.zoomTo(0.1);
                  }
                });
              }
            }
            const zInEle1 = document.getElementById('zoom-in-button-1');
            if(zInEle1){
              if(!zInEle1.dataset?.clickBound || zInEle1.dataset.clickBound != 'yes'){
                zInEle1.dataset.clickBound = 'yes';
                zInEle1.addEventListener('click', () => {
                  documentViewer1.zoomTo(documentViewer1.getZoomLevel() + 0.25);
                });
              }
            }
          });
          UI.enableFeatures([UI.Feature.MultiViewerMode]);
        });
    }
  };
  async function handleChaneZoomValue(value, _instance = instance.current, secondViewer) {
    _instance.Core.documentViewer.zoomTo(value / 100);
  }

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
    >
      <CompareFilesStyles>
        <div className="sheet-file-view">
          <div className="sheet-name-and" style={{ width: "33.3%" }}>
            <div className="text-wrapper-2" style={{ maxWidth: "33.3%" }}>{callFrom == "FilesComponent" ? "Compare Files:" : "Compare Sheets:"}</div>
            <p className="p" style={{ maxWidth: "33.3%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{comparedDocuments && comparedDocuments[0] && comparedDocuments[0].Document_Number__c} (V{comparedDocuments[0]?.Version_Number__c ? comparedDocuments[0]?.Version_Number__c : comparedDocuments[0]?.version})</p>
            <p className="p"> vs </p>
            <p className="p" style={{ maxWidth: "33.3%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{comparedDocuments && comparedDocuments[1] && comparedDocuments[1].Document_Number__c} (V{comparedDocuments[1]?.Version_Number__c ? comparedDocuments[1]?.Version_Number__c : comparedDocuments[1]?.version})</p>
          </div>
          <div className="menu" style={{ width: "33.3%", right: "0px" }}>
            <div onClick={() => setSideBy(false)} className={sideBy ? "tool-selection" : "tool-selection-select"}>
              <div className={sideBy ? "text-wrapper-3" : "text-wrapper-3-select"}>Image Overlay</div>
            </div>
            <div onClick={() => setSideBy(true)} className={sideBy ? "div-wrapper-select" : "div-wrapper"}>
              <div className={sideBy ? "text-wrapper-select" : "text-wrapper"}>Side By Side</div>
            </div>
          </div>
          <div style={{ width: "33.3%" , paddingRight: '16px'}}>
            <div style={{ float: "right" }}>
              <Button onClick={() => setReplaceDocument(true)} style={{ margin: "8px" }} className="custom_button cancel_button">
                Replace Sheets
              </Button>
              <Button onClick={() => setCompareFiles(false)} style={{ margin: "8px" }} className="custom_button apply_button">
                Close
              </Button>
            </div>
          </div>
        </div>
        {sideBy ? (
          <>
            <div style={{ display: "flex", height: "70px" }}>
              <div style={{ background: "#EAEAEA", display: "flex", paddingTop: "20px", width: "50%", borderRight: "2px solid #D8D8D8", justifyContent: 'space-between', paddingRight: '20px', paddingLeft: "20px" }}>
                <div style={{display: 'flex', gap: '6px'}}>
                  <div className="text-wrapper-2">{comparedDocuments && comparedDocuments[0].Document_Number__c} (V{comparedDocuments[0]?.Version_Number__c ? comparedDocuments[0]?.Version_Number__c : comparedDocuments[0]?.version})</div>
                  <p className="p">{comparedDocuments && comparedDocuments[0].set}  {moment(comparedDocuments && comparedDocuments[0].CreatedDate).format("MMM DD YYYY")}</p>
                </div>
                <div className={syncButton ? "sync-select" : "sync"} id="sync-1" style={{cursor: "pointer", paddingLeft: "10px" }}>
                  <img src="sync.svg" alt="sync button" /> Sync
                </div>
              </div>
              <div style={{ background: "#EAEAEA", display: "flex", marginLeft: "20px", paddingTop: "20px", width: "50%", borderLeft: "2px solid #D8D8D8", borderRight: "2px solid #D8D8D8", justifyContent: 'space-between', paddingRight: '20px', paddingLeft: "20px"}}>
                <div style={{display: 'flex', gap: '6px'}}>
                  <div className="text-wrapper-2">{comparedDocuments && comparedDocuments[1].Document_Number__c} (V{comparedDocuments[1]?.Version_Number__c ? comparedDocuments[1]?.Version_Number__c : comparedDocuments[1]?.version})</div>
                  <p className="p">{comparedDocuments && comparedDocuments[1].set}  {moment(comparedDocuments && comparedDocuments[1].CreatedDate).format("MMM DD YYYY")}</p>
                </div>
                <div className={syncButton ? "sync-select" : "sync"} id="sync" style={{ cursor: "pointer", paddingLeft: "10px" }}>
                  <img src="sync.svg" alt="sync button" /> Sync
                </div>
              </div>
            </div>
            {showViewer &&
              <div
                className="upper_class"
                ref={containerSide}
                style={{ width: "100%", height: "95%" }}
              ></div>
            }
             {showZoomActions &&
            <ZoomButtons zoomLevel={currentZoomLevel} onChangeZoom={(value)=> {handleChaneZoomValue(value, instance)}}/>
            }
           
           {showZoomActions &&
            <ZoomButtons zoomLevel={currentZoomLevel1} onChangeZoom={(value)=> {handleChaneZoomValue(value, instance, true)}} secondViewer={true}/>
            }
          </>

        ) : (
          <Grid container className="compare-body">
            <Grid item xs={12}>
              {open && openFileVisibility && (
                <FileVisibilityDialog
                  selectedFiles={comparedDocuments}
                  open={openFileVisibility}
                  handleClose={handleFileVisibilityDialogClose}
                  comparedDocuments={comparedDocuments}
                />
              )}
             {showZoomActions &&
            <ZoomButtons zoomLevel={currentZoomLevel} onChangeZoom={(value)=> {handleChaneZoomValue(value, instance)}}/>
            }
              {showViewer &&
                <div
                  className="upper_class"
                  ref={containerRef}
                  style={{ width: "100%", height: "100%" }}
                ></div>
              }
            </Grid>
          </Grid>
        )}
        {replaceDocument &&
          <VersionsSelect
            callFrom={callFrom}
            instance={instance}
            replaceDocument={true}
            comparedDocuments={comparedDocuments}
            credential={credential}
            setSheetsForReplace={setSheetsForReplace}
            sheetsForReplace={sheetsForReplace}
            setCompareOpen={setReplaceDocument}
            viewFolderFiles={viewFolderFiles}
            onAccept={(selectedDocument) => {
              console.log("selectedDocument ::", selectedDocument);
              setReplaceDocument(false);
              setShowViewer(false);
          
              setComparedDocuments(selectedDocument);
              setTimeout(async () => {
                if (sideBy) {
                  _loadFileside(selectedDocument);
                } else {
                  _loadFile(selectedDocument);
                }
              }, 1000);
            }}
          />
        }
      </CompareFilesStyles>
    </Dialog>
  );
};

CompareFiles.propTypes = {};

export default CompareFiles;