// DO NOT EDIT

import { fetchUrl , inAppLoginUrl } from "Urls";
import axios from "axios";
const preIds = [];
export const APRYSE_L_KEY ="Constructforce LLC:OEM:Construcverse::B+:AMS(20261028):41DCFF0C075AB4F3BB313BC9B263182F4E6F1F992616900ECF2DB43C043ED06AFAB6F5C7";
export const null_prefix ="nullnull";
export const IMG_FORMAT = ['.jpeg', '.jpg', '.png', '.gif', '.heic', '.heif']; // ,'.tiff'
export const VIDEO_FORMAT = ['.mp4', '.mov', '.flv','.mkv','.avi','.hevc','.m4v'];
export const MS_DOC_FORMAT = ['.doc', '.docx', '.xls','.xlsx','.ppt','.pptx','.pub'];
export const CAD_DOC_FORMAT = ['.dwg', '.dxf', '.dgn','.rvt','.dwf'];
const serializer = new XMLSerializer();
export const GetToken = async () => {
  return await axios
    .get(fetchUrl + "/get_accesss_token")
    .then((res) => {
      if (res?.status === 200) {
        return res;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const saveLocalStorage = (key, value) => {
  let _value = value
  if (typeof variable !== "string") {
    _value = JSON.stringify(value);
  }
  localStorage.setItem(key, _value);
};
const variable = "Hello, world!";

if (typeof variable === "string") {
  console.log("Variable is a string");
} else {
  console.log("Variable is not a string");
}

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const getKeyAndBucketFromUrl = (fileUrl) => {
  const decodedUrl = decodeURIComponent(fileUrl);
  const url = new URL(decodedUrl);
  const i = url.host.indexOf('.');
  const bucketName = url.host.substring(0, i);
  const key = url.pathname.substring(1);
  const path = key.substring(0, key.lastIndexOf('/'));
  return { key, bucketName ,path};
};
export const getThumbnailPathFromOrgURL = (fileUrl) => {
  const { key, bucketName, path } = getKeyAndBucketFromUrl(fileUrl);
  let filename = key.substring(key.lastIndexOf('/') + 1);
  if(filename?.toLocaleLowerCase().endsWith('.pdf')){
    filename =filename.replace('.pdf','');
  }
  if(!filename?.toLocaleLowerCase().endsWith('.png')){
    filename +='.png';
  }
  let pathh = path.startsWith("/") ? path.substring(1) : path;
  const thumbFilename = `thumb_${filename}`;
  const thumbUrl = `https://${bucketName}.s3.amazonaws.com/${pathh}/${thumbFilename}`;
  return thumbUrl;
};

export async function _getTokensValues(_credential) {
  let instanceUrl;
  let token;
  let projectId;
  const { _instanceUrl, _token, _projectId } = _credential ? _credential : {} ;
  if(_instanceUrl && _token && _projectId && _instanceUrl != '' && _token != '' && _projectId != ''){
    instanceUrl = _instanceUrl;
    token = _token;
    projectId = _projectId;
  }else{
    const data = await GetToken();
    instanceUrl = data?.data?.instanceUrl;
    token = data?.data?.token;
    projectId = data?.data?.projectId;
  }

  return { instanceUrl, token, projectId };
}
export function getInternalRecordUrl(creditial , path , redirectId , skipInApp = false) {
  const {  sandbox, projectId } = creditial ? creditial : {} ;
  if(projectId && path && sandbox){
    let loginPath = inAppLoginUrl
    if(skipInApp === true){
      loginPath = '/auth/login?';
    }
    return `${fetchUrl}${loginPath}&projectId=${projectId}&sandbox=${sandbox}&redirectTo=${path}&redirectId=${redirectId}`
  }
  return null;
};
export const handleAuxClick = (event, url) => {
  // Middle-click is button 1
  console.log('click url :: ',1,url);
  if (event.button === 1 && url) {
    const a = document.createElement('a');  // Create the <a> element
    a.href = url;                           // Set the URL
    a.target = '_blank';                    // Open in a new tab
    a.rel = 'noopener noreferrer';          // Security measure to prevent access to window.opener
    a.style.display = 'none';               // Make it invisible

    document.body.appendChild(a);           // Add to the document
    a.click();                              // Programmatically click the link
    document.body.removeChild(a); 
    /* window.open(url, '_blank'); */
    window.focus();
    console.log('click url :: ','end');
  }
};

export const getFolderFileApi = async (id) => {
  return await axios
    .get(`${fetchUrl}/folder/${id}`)
    .then((res) => {
      if (res?.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getAttachment = async (attachId, cancelToken) => {
  let config;
  if(cancelToken){
    config = {cancelToken : cancelToken};
  }
  let ret;
  await axios.get(`${fetchUrl}/attachment/${attachId}`,config).then(async (res) => {
    if (res?.status === 200) {
      if(res.data.attachment){
        ret = res.data.attachment ;
      }
    }
  }).catch(err =>{
    console.log('Error In Get Attachment  :: ',err);
    if (axios.isCancel(err)) {
      // Handle the cancellation
    }
  });
  return ret;
};
export const  deleteRecord = async (sobjectname , recId) => {
  const { instanceUrl, token, projectId } = await _getTokensValues( );
  await axios.delete(`${fetchUrl}/delete/${sobjectname}/${recId}?token=${token}&instanceUrl=${instanceUrl}`)
  .then((res) => {
    //console.log("count:::: " + JSON.stringify(res));
  }).catch((err) => {
    console.log(err);
  });
}

export async function _getIssueList(_credential) {
  const { instanceUrl, token, projectId } = await _getTokensValues( _credential );
  return await axios
    .get(
      `${fetchUrl}/issueLists/${projectId}?instanceUrl=${instanceUrl}&&token=${token}`
    )
    .then((res) => {
      if (res?.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
export function makeRandomId(length) {
  if(!length){
    length = 5;
  }
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  if (preIds?.length > 0 && preIds.indexOf(result) > -1) {
    result = makeRandomId(length);
  }
  return result;
}
export function makeTestRecId(length) {
  if(!length){
    length = 5;
  }
  var result = makeRandomId(length);
  return null_prefix+result;
}

export const fallbackCopyTextToClipboard = (text , document) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  try {
    document.execCommand('copy');
    document.body.removeChild(textArea);
    return true;
  } catch (err) {
    document.body.removeChild(textArea);
    return false;
  }
};

export const copyTextToClipboard = async (text , document) => {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (err) {
      console.log('clipboard error ::',err);
    }
  } else {
    if(document){
      return fallbackCopyTextToClipboard(text);
    }
  }
  return false;
};
export const getIssueChatterBody = (projectName , issueNumber , docRepoUrl) => {
  let chatterBody = `<p>Project : ${projectName}</p>
                    <p>Issue Number : ${issueNumber}</p>
                    <p><a href="${docRepoUrl}">Issue Link</a></p>`;
  return chatterBody;
};
export class Uploader {
  constructor(projectId, folderId, fileData) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.projectId = projectId;
    this.folderId = folderId;
    this.file = fileData.file;
    this.chunkSize = fileData.file.chunkSize || 1024 * 1024 * 5;
    // number of parallel uploads
    this.threadsQuantity = Math.min(fileData.file.threadsQuantity || 5, 15);
    //console.log("this.threadsQuantity", this.threadsQuantity);
    this.contentType = '';
    if(this.file && this.file.type){
      this.contentType = this.file.type;
    }
    this.addtimeStamp = fileData.addtimeStamp === true ? 'yes' : 'no' ;
    let fileName1 = this.file.name;
    let pos = fileName1.lastIndexOf(".");
    let fileExtension = fileName1.substring(pos + 1);
    if (fileExtension) {
      fileExtension = fileExtension.toLowerCase();
      if (!fileName1.toLowerCase().endsWith("." + fileExtension)) {
        fileName1 += "." + fileExtension;
      }
    }
    this.fileName = fileName1;
    this.callbackData = {id:fileData.id, name : fileName1 , file : this.file , folderId : folderId , parentIdentifier : fileData.parentIdentifier} ;
    this.fileExtension = fileExtension;
    this.locationDetail = {};
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = null;
    this.fileKey = null;
    this.onProgressFn = () => { };
    this.onErrorFn = () => { };
    const { token, cancel } = axios.CancelToken.source();
    this.cancelToken = token;
    this.cancelAction = cancel;
  }

  // starting the multipart upload request
  start(handleFileProcess) {
    this.completeMethodCall = handleFileProcess;
    this.initialize();
  }

  async initialize() {
    try {
      // adding the the file extension (if present) to fileName
      // initializing the multipart request
      const fileData = new FormData();
      fileData.append("name", this.fileName);
      fileData.append("addtimeStamp", this.addtimeStamp);
      const initializeReponse = await axios.post(`${fetchUrl}/uploads/initializeMultipartUpload/${this.projectId}/${this.folderId}?contentType=${this.contentType}`, fileData,{
        cancelToken: this.cancelToken,
      }).catch((error) => {
        console.log('initialize_multipart_upload error',axios.isCancel(error) ,error);
        if (axios.isCancel(error)) {
          //console.log('initialize_multipart_upload cancel');
          this.completeMethodCall("canceled", this.callbackData);
        } else {
          // Handle other errors
        }
      });
      //data returned
      const returnData = initializeReponse.data;

      this.fileId = returnData.fileId;
      this.fileKey = returnData.fileKey;
      this.locationDetail = returnData.locationDetail;
      // retrieving the pre-signed URLs
      const numberOfparts = Math.ceil(this.file.size / this.chunkSize);

      const body = new FormData();
      body.append("fileId", this.fileId);
      body.append("fileKey", this.fileKey);
      body.append("parts", numberOfparts);
      const urlsResponse = await axios.post(`${fetchUrl}/uploads/getMultipartPreSignedUrls`, body,{
        cancelToken: this.cancelToken,
      }).catch((error) => {
        console.log('get_multipart_pre_signed error',axios.isCancel(error) ,error);
        if (axios.isCancel(error)) {
          //console.log('get_multipart_pre_signed cancel');
          this.completeMethodCall("canceled", this.callbackData);
        } else {
          // Handle other errors
        }
      });
      const newParts = urlsResponse.data.parts;
      this.parts.push(...newParts);
      this.sendNext();
    } catch (error) {
      await this.complete(error);
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;
    if (activeConnections >= this.threadsQuantity) {
      return;
    }
    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }
      return;
    }
    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);
      const sendChunkStarted = () => {
        this.sendNext();
      };
      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          this.parts.push(part);
          this.complete(error);
        });
    }
  }

  // terminating the multipart upload request on success or failure
  async complete(error) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
    } catch (error) {
      this.onErrorFn(error);
    }
  }

  // finalizing the multipart upload request on success by calling
  // the finalization API
  async sendCompleteRequest() {
    if (this.fileId && this.fileKey) {
      const body = new FormData();
      body.append("fileId", this.fileId);
      body.append("fileKey", this.fileKey);
      body.append("parts", JSON.stringify(this.uploadedParts));
      body.append("fileName", this.locationDetail?.fileName ? this.locationDetail?.fileName : this.fileName); // this.fileName
      body.append("fileExt", this.locationDetail?.extension ? this.locationDetail?.extension : this.fileExtension);// this.fileExtension
      body.append("title", this.locationDetail?.title ? this.locationDetail?.title : this.fileName);
      await axios.post(`${fetchUrl}/uploads/finalizeMultipartUpload/${this.projectId}/${this.folderId}`, body,{
        cancelToken: this.cancelToken,
      })
        .then(async () => {
          //toast.success("File Uploaded");
          this.completeMethodCall("success", this.callbackData);
        }).catch((error) => {
          console.log('finalize_multipart_upload error',axios.isCancel(error) ,error);
          if (axios.isCancel(error)) {
            //console.log('finalize_multipart_upload cancel');
            this.completeMethodCall("canceled", this.callbackData);
          } else {
            this.completeMethodCall("failed", this.callbackData);
          }
        });
    }
  }
  sendChunk(chunk, part, sendChunkStarted) {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error("Failed chunk upload"));
            return;
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  // calculating the current progress of the multipart upload request
  handleProgress(part, event) {
    if (this.file) {
      if (event.type === "progress" || event.type === "error" || event.type === "abort") {
        this.progressCache[part] = event.loaded;
      }
      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }
      const inProgress = Object.keys(this.progressCache).map(Number).reduce((memo, id) => (memo += this.progressCache[id]), 0);
      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);
      const total = this.file.size;
      const percentage = Math.round((sent / total) * 100);
      this.onProgressFn({ sent: sent, total: total, percentage: percentage });
    }
  }

  // uploading a part through its pre-signed URL
  upload(file, part, sendChunkStarted) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        // - 1 because PartNumber is an index starting from 1 and not 0
        const xhr = (this.activeConnections[part.PartNumber - 1] = new XMLHttpRequest());
        sendChunkStarted();
        const progressListener = this.handleProgress.bind(this, part.PartNumber - 1);
        xhr.upload.addEventListener("progress", progressListener);
        xhr.addEventListener("error", progressListener);
        xhr.addEventListener("abort", progressListener);
        xhr.addEventListener("loadend", progressListener);
        xhr.open("PUT", part.signedUrl);
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // retrieving the ETag parameter from the HTTP headers
            const ETag = xhr.getResponseHeader("ETag");
            if (ETag) {
              const uploadedPart = {
                PartNumber: part.PartNumber,
                // removing the " enclosing carachters from
                // the raw ETag
                ETag: ETag.replaceAll('"', ""),
              };
              this.uploadedParts.push(uploadedPart);
              resolve(xhr.status);
              delete this.activeConnections[part.PartNumber - 1];
            }
          }
        };
        xhr.onerror = (error) => {
          reject(error);
          this.completeMethodCall("failed", this.callbackData);
          delete this.activeConnections[part.PartNumber - 1];
        };
        xhr.onabort = () => {
          console.log('aborted');
          reject(new Error("Upload canceled by user"));
          this.completeMethodCall("canceled", this.callbackData);
          delete this.activeConnections[part.PartNumber - 1];
        };
        xhr.send(file);
      }
    });
  }

  onProgress(onProgress) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError) {
    this.onErrorFn = onError;
    return this;
  }

  abort() {
    //console.log('abort called',this.activeConnections);
    for(let key in this.activeConnections){
      //console.log('activeConnections Id ',key);
      this.activeConnections[key]?.abort(); 
    }
    this.cancelAction('Upload canceled by user.');
    this.aborted = true;
  }
}