import React, { useEffect, useRef, useState } from "react";
import { useSelector , useDispatch } from "react-redux";
import { makeRandomId } from "api";
import { setFolderIdToData,
        setAttachmentIdToThumbnail,
        setSheetVersionSetActiveFilters,
        setSheetTypeActiveFilters,
        setSheetDisciplineActiveFilters,
        setSheetReviewStatusActiveFilters,
        setSheetUpdatedByActiveFilters } from "../../redux/features/appStateSlice"
import WebViewer from '@pdftron/webviewer';
import { Tabs, Tab, InputAdornment } from "@mui/material";
import {
  Paper, IconButton, Button, TextField, Menu,
  Stack, Tooltip, Box, Checkbox
} from "@mui/material";
import { Folder, Search } from "@mui/icons-material";
import ToastComponent from "components/ToastComponent";
//import useSheetTableHook from "hooks/SheetTable.hook";
import axios from "axios";
import { fetchUrl } from "Urls";
import { getInternalRecordUrl } from "api";
import Spinner from "components/spinner";
import { cloneDeep } from "lodash";
import moment from "moment";
import ShowPdf from "components/SetsView/ShowPdf";
import ShowVideoDialog from "components/SetsView/ShowVideoDialog";
import VersionsDialog from "components/FolderTable/VersionsDialog";
import RecycleBin from "components/FolderTable/RecycleBin";
import pdfRed from "assets/images/pdfRed.svg";
import { ExportFileDialog } from "components/FolderTable/ExportFile";
import { toast } from "react-toastify";
import UploadLogs from "components/upload-logs/UploadLogs";
import DropdownFilter from "components/DropdownFilter";
import GridViewComponent from "components/FolderTable/GridViewComponent";
import FileUploadProgress from "components/MultifileProcessing/multiFileProcessing";
import { ConfirmDialog } from "components/DrawerComponent/DisciplineSettingDialog";
import AssignmentDialog from "components/FolderTable/AssignmentDialog";
import ShareFiles from "components/FolderTable/ShareFiles";
import MoveDialog from "components/FolderTable/MoveDialog";
import ShareableLinkComponent from "components/ShareableLinkComponent";
import ReactDataTable from "components/ReactDataTableComponent";
//import ShopDrawings from "components/DatableComponent/shopDrawin_gs";
import { _sortItems } from "constant/Methods";
import { useNavigate, useLocation } from "react-router-dom";
import CompareFiles from 'components/FolderTable/CompareFiles';
import { APRYSE_L_KEY } from "api";
import { getXMLJSFromMakrupXML, getWrapperFromAnnotaitionXMLJSON } from "components/SetsView/ShowPdf";
import Design from "./Design";
import ItemInQueueComponent from "components/ItemInQueueComponent";
import copyLinkIcon from "components/ShareableLinkComponent/Icons/copy_link_icon.svg";

const Contract_Folder_Name = 'Contract Drawings';
const Shop_Folder_Name = 'Shop Drawings';
const noDisciplineLabel = 'No Discipline';

export default function SheetsComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setStates] = useState(location.state); 
  const stateRef = useRef(location.state);
  const setState=(value)=>{
    stateRef.current = value;
    setStates(value);
  };
  const cancelAction0 = useRef();
  const cancelAction = useRef();
  var timeOutObj;
  var FETCH_LIMIT = 150;
  var interValTime = (60 * 1000);
  const { userData = {} } = useSelector((state) => state.userData);
  const { folderIdToData ,
        attachmentIdToThumbnail,
        sheetVersionSetActiveFilters,
        sheetTypeActiveFilters,
        sheetDisciplineActiveFilters,
        sheetReviewStatusActiveFilters,
        sheetUpdatedByActiveFilters } = useSelector((state) => state.appState);
  const setFolderIdToDataState = (folderId , data)=>{
    let payload = {folderId : folderId , data : data};
    dispatch(setFolderIdToData(payload));
  }
  const [sourceFolderDetail, setSourceFolder] = useState({});
  const [folderNameToIdMap, setSubFolderMap] = useState(null);
  const [firstCountCall, setFirstCountCall] = useState(true);
  const [readyForReviewCount, setReadyForReviewCount] = useState(0);

  const [credential, setCredential] = useState();
  const [userList, setUserList] = useState(userData?.userList);
  const [shopDrawingTypeOptions, setShopDrawingTypes] = useState([]);
  const [disciplineOptions, setDisciplineOptions] = useState([]);
  const [setOptions, setSetOptions] = useState([]);
  const [reviewStageOptions, setReviewStageOptions] = useState([]);

  const [tempProId, setTempProjId] = useState();
  const activeTabRef = useRef();
  const [activeTab, setActiveTab] = useState(0);
  const [folderFilesForSearch, setFolderFilesForSearch] = useState();
  const currentFolderFileData = useRef([]); // used for js calculation bcz of state not updating
  const [currentFolderFiles, setFolderFiles] = useState([]); // used for js calculation bcz of view
  const [folderFileLoading, setFolderFileLoading] = useState(false); // used for js calculation bcz of view
  const [allDataFetched, setAllDataFetchedState] = useState(false);
  const allDataFetchedRef = useRef(allDataFetched);
  const setAllDataFetched = (state)=>{
    allDataFetchedRef.current = state;
    setAllDataFetchedState(state);
  };
  const [pdfopenfiledata, setFileData] = useState();
  const [viewingIssueId, setViewingIssue] = useState();
  const [viewFolderFiles, setViewFolderFiles] = useState([]);
  const [showSpinner, setSpinner] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showlogs, setShowLogs] = useState(false);
  const [showRecyclebin, setShowRecyclebin] = useState(false);
  const [showShopDrawings, setShowShopDrawings] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isGridView, setIsGridView] = useState(false);
  const [currentFileId, setCurrentFileId] = useState();
  const openMenu = Boolean(anchorEl);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedMenuRow, setSelectedMenuRow] = useState();
  const [actionCalledFor, setActionCalledFor] = useState();
  const [editSetAssignPopup, setEditSetAssignPopup] = useState(false);
  const [movePopup, setmovePopup] = useState(false);
  const [toShareItems, setShareItems] = useState([]);
  const [compareFiles, setCompareFiles] = useState(false);
  const [allSheetsChecked, setAllSheetChecked] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  /*filter variable start*/

  const [filterOptionsVisible, showFilterOptions] = useState(true);
  const [totalFilterApplied, setTotalFilterAppliedState] = useState(0);
  const totalFilterAppliedRef = useRef(totalFilterApplied);
  const setTotalFilterApplied = (state)=>{
    totalFilterAppliedRef.current = state;
    setTotalFilterAppliedState(state);
  };
  const [activeFilterDropdown, setActiveFilterDropdown] = useState();
  const [versionSetActiveFilters, setVersionSetActiveFilters] = useState(sheetVersionSetActiveFilters[userData?.projectId]);
  const [typeActiveFilters, setTypeActiveFilters] = useState(sheetTypeActiveFilters[userData?.projectId]);
  const [disciplineActiveFilters, setDisciplineActiveFilters] = useState(sheetDisciplineActiveFilters[userData?.projectId]);
  const [reviewStatusActiveFilters, setReviewStatusActiveFilters] = useState(sheetReviewStatusActiveFilters[userData?.projectId]);
  const [updatedByActiveFilters, setUpdatedByActiveFilters] = useState(sheetUpdatedByActiveFilters[userData?.projectId]);
  const [rowsPerPage, setRowsPerPage] = useState([]);
  const [attachmentThumbnail, setAttachmentThumbnail] = useState(null);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const filteredTableDataRow = useRef([]);
  const processTofilter_ = useRef(null);
  const confirmDialogData = {
    title: 'Delete Sheet/s',
    content: 'Are you sure you want to delete selected sheet/s? Deleted sheets can be recovered in the deleted sheets tab.',
    action1: "Cancel",
    action2: "Delete"
  };
  const [webInstance, setInstance] = useState(null);
  const [openpopup, setOpen] = useState(false);
  const [paginationPageIndex, setPaginationPageIndex] = useState(0);
  const [openVideoPopup, setOpenVideoPopup] = useState(false);
  const instanceRef = useRef(null);
  const pdfContainerRef = useRef(null);
  let stopBreakThumbmaking = useRef(null);

  
  const [searchText, setSearchText] = useState("");
  const inputRef = useRef(null);
  const [contentSearchRunning, isContentSearchRunning] = useState(false);
  let stopPDFInContentSearch = useRef(null);
  const [searchBoxFilter, setSearchBoxFilter] = useState(false);

  function setInstance_(values) {
    //console.log("values ::", values);
    instanceRef.current = values;
    setInstance(values);
  }
  function setFilteredTableData_(values) {
    filteredTableDataRow.current = values;
    setFilteredTableData(values);
  }
  const [bgProcessItems, setBgProcessItems] = useState([]);
  const [showBgProcess , setShowBgProcess] = useState(false);
  const setItemToBgProcess=(queue_obj)=>{
    setBgProcessItems(pv=>{
      let t = cloneDeep(pv);
        if(!t){
          t = [];
        }
        let fi = t.findIndex(k=>k.id === queue_obj.id);
        if(fi > -1){
          t[fi] = queue_obj;
        }else{
          t.push(queue_obj);
        }
      return t;
    });
  }
  function _handleCloseProcess() {
    setBgProcessItems([]);
    setShowBgProcess(false);
  }

  useEffect(() => {
    //console.log("FolderTable Hook projectId>>>>>", projectId);
    //console.log("FolderTable Hook credential>>>>>", credential?.projectId );
    if (userData?.projectId) {
      processTofilter_.current = false;
      if (activeTabRef.current === null || activeTabRef.current === undefined) {
        activeTabRef.current = 0;
      }
      if (credential?.projectId !== userData?.projectId) {
        (async function () {
          await _connnectionFunc();
        })();
      }
    }
    return () => {
      //console.log('Clear Interval Called intervalId :: ' + timeOutObj);
      clearInterval(timeOutObj)
    };
  }, [userData?.projectId]);

  useEffect(() => {
    //console.log('change state :: ',location.state);
    setState(location.state);
  }, [location?.state]);
  useEffect(() => {
    if(!contentSearchRunning){
    setSelectedIds([]);
    let selectedId = [];
    if (allSheetsChecked === true) {
      selectedId = selected_ids.length > 0 ? [] : [];
      rowsPerPage.map((row) =>
        selectedId?.push(row.id)
      );
    } else {
      setSelectedIds([]);
    }
    setSelectedIds(selectedId);
    const selectedIds = new Set(selectedId);

    const selectedRows = viewFolderFiles.filter((row) =>
      selectedIds.has(row.id.toString())
    );
    setSelectedItems(selectedRows);
    const selectedFiless = selectedRows.filter((row) => row.type === "file");
    setSelectedFiles(selectedFiless);
  }
  }, [rowsPerPage]);
  useEffect(() => {
    async function fetchData() {
      if (credential?.projectId !== tempProId) {
        //console.log('useEffect_ FetchData Called' , 'in');
        setTempProjId(credential?.projectId);
        let thumbData = (attachmentIdToThumbnail && attachmentIdToThumbnail[credential.projectId] ? cloneDeep(attachmentIdToThumbnail[credential.projectId]) : {} );
        setAttachmentThumbnail( thumbData );
        /*console.log("SheetComponent Use Effect credential?.projectId",credential?.projectId,credential?.isPlan);*/
        let fetchFolder = true;
        let ffff = stateRef.current;
        if (ffff) {
          if (ffff?.activeTabId && ffff?.activeTabId !== null) {
            onActiveTabChange(ffff?.activeTabId);
            fetchFolder=false;
          } else if(ffff?.redirectId && ffff?.redirectId != null){
            if(ffff?.issueId && ffff?.issueId != null){
              setViewingIssue(ffff?.issueId);
            }
            fileNameClick({id : ffff?.redirectId , url : 'f' ,type:'file'});
          }
          window.history.replaceState({}, document.title);
          setState(null);
        }
        if(fetchFolder){
          getSourceFolder();
        }
      }
    }
    if (credential?.projectId) {
      //console.log('useEffect_ FetchData Called');
      fetchData();
    }
  }, [credential?.projectId]);

  async function _connnectionFunc() {
    //console.log('_connnection Func tokenInfo :: ',JSON.stringify(tokenInfo?.userList));
    setCredential(userData);
    setUserList(userData?.userList);
    getUnPublishBinderCount(userData); // first call
    getAttachmentFilterOptions(userData);
    if (!fetchUrl.includes('localhost')) {
      checkAndSetAutoGetCountTimeout(userData);
    }
    //console.log('Folder Table credential :: '+JSON.stringify(data?.data));
  }
  function checkAndSetAutoGetCountTimeout(_credential = credential) {
    clearAutoSaveGetCountInterval();
    timeOutObj = setInterval(() => {
      getUnPublishBinderCount(_credential);
    }, interValTime);
    //console.log('timeIntervalId start ' + timeOutObj);
    //setCountIntervalObj(timeOutObj);
  }
  function clearAutoSaveGetCountInterval() {
    if (timeOutObj) {
      clearInterval(timeOutObj);
      //setCountIntervalObj(undefined);
      //console.log('timeout cleared '+Date.now());
    }
  }
  async function getUnPublishBinderCount(_credential = credential) {
    await axios.get(`${fetchUrl}/unpublish/${_credential.projectId}?token=${_credential?.token}&instanceUrl=${_credential?.instanceUrl}`)
    .then((res) => {
      //console.log("count:::: " + JSON.stringify(res));
      if (res.status === 200) {
        if (readyForReviewCount !== res?.data?.expr0 && firstCountCall === false) {
          if (readyForReviewCount || readyForReviewCount === 0) {
            /*toast.success("Published log review updated.", {
              position: toast.top - toast.right,
            });*/
          }
        }
        setReadyForReviewCount(res?.data?.expr0);
        setFirstCountCall(false);
      } else {
        setReadyForReviewCount(0);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  async function getAttachmentFilterOptions(_credential = credential) {
    const instanceUrl = _credential.instanceUrl;
    const token = _credential.token;
    const projectId = _credential.projectId;
    if (!shopDrawingTypeOptions || shopDrawingTypeOptions?.length === 0 || projectId != tempProId) {
      var req_body = { fields: ['Shop_Drawing_Type__c'] };
      var shopValues = [];
      axios.post(`${fetchUrl}/picklist/valueObj/Amazon_S3_Attachment__c?token=${token}&instanceUrl=${instanceUrl}`, req_body)
        .then((res) => {
          if (res?.status === 200) {
            let retData = res.data;
            if (retData?.pickObj?.Shop_Drawing_Type__c?.valueList) {
              shopValues = retData?.pickObj?.Shop_Drawing_Type__c?.valueList;
            }
          }
          setShopDrawingTypes(shopValues);
        }).catch((err) => {
          console.log(err);
          setShopDrawingTypes(shopValues);
        });
    }
    if (!disciplineOptions || disciplineOptions?.length === 0 || disciplineOptions?.length === 1 || projectId != tempProId) {
      var disValues = [];
      axios.get(`${fetchUrl}/disciplineOptions/${projectId}?token=${token}&instanceUrl=${instanceUrl}`)
        .then((res) => {
          if (res?.status === 200) {
            let retData = res.data;
            disValues = retData?.options;
          }
          disValues.unshift({label: noDisciplineLabel , value : noDisciplineLabel ,key : noDisciplineLabel})
          setDisciplineOptions(disValues);
        }).catch((err) => {
          console.log(err);
          setDisciplineOptions(disValues);
        });
    }
    if (!setOptions || setOptions?.length === 0 || projectId != tempProId) {
      var setValues = [];
      axios.get(`${fetchUrl}/sets/${projectId}?token=${token}&instanceUrl=${instanceUrl}`)
        .then((res) => {
          if (res?.status === 200) {
            res?.data?.records?.map((val, index) => {
              if (val?.Issuance_Date__c !== null) {
                setValues.push({ value: val?.Id, label: val?.Name, date: moment(val?.Issuance_Date__c).format("MMM DD YYYY") });
              }
            });
            setSetOptions(setValues);
          }
        }).catch((err) => {
          console.log(err);
          setSetOptions(setValues);
        });
    }
    /* if (!reviewStageOptions || reviewStageOptions?.length === 0) {
      var rewValues = [];
      var req_body = { fields: ['Stage__c'] };
      axios.post(`${fetchUrl}/picklist/valueObj/Review__c?token=${token}&instanceUrl=${instanceUrl}`, req_body)
      .then((res) => {
        if (res?.status === 200) {
          let retData = res.data;
          if (retData?.pickObj?.Stage__c?.valueList) {
            rewValues = retData?.pickObj?.Stage__c?.valueList;
          }
        }
        setReviewStageOptions(rewValues);
      }).catch((err) => {
        console.log(err);
        setReviewStageOptions(rewValues);
      });
    } */
  }


  const handleSetAttachmentThumnail = (attchThumbData) => {
    let payload = {projectId : credential?.projectId , data : attchThumbData};
    dispatch(setAttachmentIdToThumbnail(payload));
  };
  useEffect(() => {
    let cc = 0;
    if(activeTabRef.current === 0){
      if (versionSetActiveFilters?.length > 0) {
        cc += versionSetActiveFilters.length;
      }
      if (disciplineActiveFilters?.length > 0) {
        cc += disciplineActiveFilters.length;
      }
    }
    if(activeTabRef.current === 1){
      if (typeActiveFilters?.length > 0) {
        cc += typeActiveFilters.length;
      }
    }
    if (reviewStatusActiveFilters?.length > 0) {
      cc += reviewStatusActiveFilters.length;
    }
    if (updatedByActiveFilters?.length > 0) {
      cc += updatedByActiveFilters.length;
    }
    setTotalFilterApplied(cc);
    if (processTofilter_.current) {
      onHandleFilterData(cc);
    }
    processTofilter_.current = false;

  }, [currentFolderFiles ,versionSetActiveFilters, typeActiveFilters, disciplineActiveFilters, reviewStatusActiveFilters, updatedByActiveFilters]);

  async function loadSampleDocument() {
    //console.log('pdfContainerRef.current :: ',pdfContainerRef.current);
    try{
      if (pdfContainerRef.current && !instanceRef.current ) {
        await WebViewer({
          fullAPI: true,
          path: '/webviewer/public',
          licenseKey: APRYSE_L_KEY,
          disableObjectURLBlobs: true,
          backendType: WebViewer.BackendTypes.ASM,
        }, pdfContainerRef.current).then(async (instance) => {
          setInstance_(instance);
      
          console.log('instance :: ', instance);
        }).catch(err=>{
          console.log('loadSampleDocument :', err);
        });
      }
    }catch (e) {
      console.log('loadSampleDocument :', e);
    }

  }
  const generatePdfThumbnailMapPromise = async (attachmentRows) => {
    const THUMB_BATCH_SIZE = 100;
    const BATCH_SIZE = 10;
    if (attachmentRows?.length > 0) {
      stopBreakThumbmaking.current = false;
      let toProcess_batch = [];
      let promises = [];
      for (let k = 0; k < attachmentRows.length; k++) {
        if (stopBreakThumbmaking.current) break;
        const row = attachmentRows[k];
        if (row.url && !row.thumbnail && (!attachmentThumbnail || !attachmentThumbnail[row.id])) {
          toProcess_batch.push(row);
        }
        if (toProcess_batch.length === THUMB_BATCH_SIZE || k === attachmentRows.length - 1){
          if(toProcess_batch?.length > 0){
            let recIds = toProcess_batch.map(t=>t.id);
            let thumbMap = await getThumbnailUrl(recIds);
            for (let i = 0; i < toProcess_batch.length; i++) {
              if (stopBreakThumbmaking.current) break;
              const file = toProcess_batch[i];
              if (file.url && (!attachmentThumbnail || !attachmentThumbnail[file.id])) {
                promises.push(genratePdfThumnail(file.id, file.url , false , thumbMap));
              }
              if (promises.length === BATCH_SIZE || i === toProcess_batch.length - 1) {
                if(promises?.length > 0){
                  //console.time(i,'promise starts :: ',promises?.length);
                  await Promise.all(promises).then((values) => {
                    //console.timeEnd(i,'promise starts :: ',values?.length);
                      if (values?.length > 0) {
                        let temList_folFiles = JSON.parse(JSON.stringify(currentFolderFileData.current)); let curfolFilePush = false;
                        let temList_viewFiles = JSON.parse(JSON.stringify(viewFolderFiles)); let viewFilePush = false;
                        let temList_filFiles = JSON.parse(JSON.stringify(filteredTableData)); let curFilFilePush = false;
                        for (let pdfth of values) {
                          if (pdfth !== null && pdfth.attachId && pdfth.thumbnail) {
                            let atchId = pdfth.attachId;
                            let thumbnail = pdfth.thumbnail;
                            if (temList_folFiles?.length > 0) {
                              const fInd = temList_folFiles?.findIndex(
                                (data) => data.id === atchId
                              );
                              if (fInd > -1) {
                                curfolFilePush = true;
                                temList_folFiles[fInd].thumbnail = thumbnail;
                              }
                            }
                            if (temList_viewFiles?.length > 0) {
                              const fInd = temList_viewFiles?.findIndex(
                                (data) => data.id === atchId
                              );
                              if (fInd > -1) {
                                viewFilePush = true;
                                temList_viewFiles[fInd].thumbnail = thumbnail;
                              }
                            }
                            if (temList_filFiles?.length > 0) {
                              const fInd = temList_filFiles?.findIndex(
                                (data) => data.id === atchId
                              );
                              if (fInd > -1) {
                                curFilFilePush = true;
                                temList_filFiles[fInd].thumbnail = thumbnail;
                              }
                            }
                          }
                        }
                        if (curfolFilePush) {
                          setCurrentFolderFiles(temList_folFiles);
                          if(sourceFolderDetail?.id){
                            setFolderIdToDataState(sourceFolderDetail?.id, temList_folFiles);
                          }
                          setFolderFilesForSearch(temList_folFiles);
                        }
                        if (viewFilePush) {
                          setViewFolderFiles(temList_viewFiles);
                        }
                        if (curFilFilePush) {
                          setFilteredTableData(temList_filFiles);
                        }
                      }
                  }).catch(err=>{
                    console.log('promise all',err);
                  });
                }
                promises = [];
              }
            }
          }
          toProcess_batch = [];
        }
        if (stopBreakThumbmaking.current) break;
      }
    } else {
      stopBreakThumbmaking.current = true;
    }
  };
  const genratePdfThumnail = async (attachId, fileUrl, updateList , thumbnailMap = {}) => {
    //let thumbnailMap ;// = await getThumbnailUrl([attachId]);
    let pdfThumbnail ;
    if(thumbnailMap[attachId]){
      pdfThumbnail = {attachId : attachId , thumbnail : thumbnailMap[attachId]} ;
    }else{
      pdfThumbnail = await getPdfThumbnailFromWebViewer(attachId , fileUrl , instanceRef.current) ;
    }
    if(pdfThumbnail && pdfThumbnail.attachId && pdfThumbnail.thumbnail) {
      let imgthumEleId = pdfThumbnail.attachId + '_thumb_img';
      setAttachmentThumbnail((preMap) => {
        let retMap = cloneDeep(preMap);
        if (!retMap) {
          retMap = {};
        }
        retMap[pdfThumbnail.attachId] = pdfThumbnail.thumbnail;
        var imgEle = document.getElementById(imgthumEleId);
        if (imgEle) {
          imgEle.src = pdfThumbnail.thumbnail;
          imgEle.style = {};
        }
        handleSetAttachmentThumnail(retMap);
        //console.log('gen sse :: ',pdfThumbnail.thumbnail);
        return retMap;
      });
      if (updateList === true) {
        updateThumbnailToCurrentData(pdfThumbnail.attachId, pdfThumbnail.thumbnail);
      }
      return pdfThumbnail;
    }
    return null;
  };
  async function onHandleFilterData(_totalFilterApplied = totalFilterApplied) {
    let orgData0 = cloneDeep(currentFolderFileData.current);
    if(allDataFetchedRef.current){
      let toSetData = orgData0;
      if (_totalFilterApplied > 0) {
        toSetData = [];
        if (orgData0?.length > 0) {
          for (const currRow of orgData0) {
            let matched = true;
            if(activeTabRef.current == 0){
              if (versionSetActiveFilters?.length > 0 && matched === true) {
                matched = false;
                if (currRow.setId) {
                  const valueFound = versionSetActiveFilters.find(usss => usss.value === currRow.setId)
                  if (valueFound) {
                    matched = true;
                  }
                }
              }
              if (disciplineActiveFilters?.length > 0 && matched === true) {
                matched = false;
                if (currRow.discipline) {
                  const valueFound = disciplineActiveFilters.find(usss => (usss.value === currRow.disciplineId || usss.value === currRow.discipline));
                  if (valueFound) {
                    matched = true;
                  }
                }
              }
            }
            if(activeTabRef.current == 1){
              if (typeActiveFilters?.length > 0) {
                matched = false;
                if (currRow.shopDrawing) {
                  matched = typeActiveFilters.includes(currRow.shopDrawing);
                }
              }
            }
            if (reviewStatusActiveFilters?.length > 0 && matched === true) {
              matched = false;
              if (currRow.reviewStatus) {
                matched = reviewStatusActiveFilters.includes(currRow.reviewStatus);
              }
            }
            if (updatedByActiveFilters?.length > 0 && matched === true) {
              matched = false;
              const valueFound = updatedByActiveFilters.find(usss => usss.value === currRow.updatedById);
              if (valueFound) {
                matched = true;
              }
            }
            if (matched) {
              toSetData.push(currRow);
            }
          }
        }
        setFilteredTableData_(toSetData);
      }
      setPaginationPageIndex(0);
      setViewFolderFiles(toSetData);
    }else{
      if(sourceFolderDetail?.id){
        if(cancelAction0.current){
          cancelAction0.current();
        }
        await getFolderAttachments(sourceFolderDetail?.id);
      }
    }
    return;
    let filteredFoundList = [];
    let orgData = cloneDeep(currentFolderFileData.current);
    if (orgData?.length > 0 && _totalFilterApplied > 0) {
      for (const currRow of orgData) {
        let matched = true;
        if(activeTabRef.current == 0){
          if (versionSetActiveFilters?.length > 0 && matched === true) {
            matched = false;
            if (currRow.setId) {
              const valueFound = versionSetActiveFilters.find(usss => usss.value === currRow.setId)
              if (valueFound) {
                matched = true;
              }
            }
          }
          if (disciplineActiveFilters?.length > 0 && matched === true) {
            matched = false;
            if (currRow.discipline) {
              const valueFound = disciplineActiveFilters.find(usss => (usss.value === currRow.disciplineId || usss.value === currRow.discipline));
              if (valueFound) {
                matched = true;
              }
            }
          }
        }
        if(activeTabRef.current == 1){
          if (typeActiveFilters?.length > 0) {
            matched = false;
            if (currRow.shopDrawing) {
              matched = typeActiveFilters.includes(currRow.shopDrawing);
            }
          }
        }
        if (reviewStatusActiveFilters?.length > 0 && matched === true) {
          matched = false;
          if (currRow.reviewStatus) {
            matched = reviewStatusActiveFilters.includes(currRow.reviewStatus);
          }
        }
        if (updatedByActiveFilters?.length > 0 && matched === true) {
          matched = false;
          const valueFound = updatedByActiveFilters.find(usss => usss.value === currRow.updatedById);
          if (valueFound) {
            matched = true;
          }
        }
        if (matched) {
          filteredFoundList.push(currRow);
        }
      }
      setFilteredTableData_(filteredFoundList);
    } else {
      filteredFoundList = cloneDeep(currentFolderFileData.current);
    }
    //console.log('on HandleFilterData filteredFoundList :: '+filteredFoundList?.length);
    setViewFolderFiles(filteredFoundList);
  };
  async function searchListViewFunction(searchTerm) {
    setSearchBoxFilter(false);
    stopPDFInContentSearch.current = true;
    isContentSearchRunning(false);
    setSearchText(searchTerm);
    let isFiltered = (filteredTableData?.length > 0 || totalFilterApplied > 0);
    let serLocal = isFiltered || allDataFetchedRef.current ;
    let toSearchData = isFiltered ? cloneDeep(filteredTableData) : cloneDeep(folderFilesForSearch);
    if(searchTerm && searchTerm !== null && searchTerm !== ""){
      if(serLocal){
        let searchFoundList = [];
        for (let el of toSearchData) {
          if ((el.name && el.name.toLowerCase().includes(searchTerm)) || (el.title && el.title.toLowerCase().includes(searchTerm))) {
            if(el.name && el.name.toLowerCase().includes(searchTerm)){
              el.name_mark  = highlightText(el.name, searchTerm);
            }
            if(el.title && el.title.toLowerCase().includes(searchTerm)){
              el.title_mark  = highlightText(el.title, searchTerm);
            }
            searchFoundList.push(el);
          }
        }
        setPaginationPageIndex(0);
        setViewFolderFiles(searchFoundList);
      }else{
        if(sourceFolderDetail?.id){
          if(cancelAction0.current){
            cancelAction0.current();
          }
          getFolderAttachments(sourceFolderDetail?.id , searchTerm , true);
        }
      }
    }else{
      setPaginationPageIndex(0);
      setViewFolderFiles(toSearchData);
    }
  };
  /* async function searchListViewFunction(searchTerm) {
    setSearchBoxFilter(false);
    stopPDFInContentSearch.current = true;
    isContentSearchRunning(false);
    setSearchText(searchTerm);

    let orgData = (filteredTableData?.length > 0 || totalFilterApplied > 0) ? cloneDeep(filteredTableData) : cloneDeep(folderFilesForSearch);
    //console.log(searchTerm,'orgData :: '+orgData?.length);
    let searchFoundList = cloneDeep(orgData);
    if (orgData?.length > 0) {
        if(searchTerm && searchTerm !== null && searchTerm !== ""){
          searchFoundList = [];
          for (let el of orgData) {
            if ((el.name && el.name.toLowerCase().includes(searchTerm)) || (el.title && el.title.toLowerCase().includes(searchTerm))) {
              if(el.name && el.name.toLowerCase().includes(searchTerm)){
                el.name_mark  = highlightText(el.name, searchTerm);
              }
              if(el.title && el.title.toLowerCase().includes(searchTerm)){
                el.title_mark  = highlightText(el.title, searchTerm);
              }
              searchFoundList.push(el);
            }
          }
          setSearchBoxFilter(true);
          setTimeout(() => {
            if(orgData?.length > 0){
              stopPDFInContentSearch.current = false;
              initiateInContentSearch(cloneDeep(orgData) , searchTerm);
            }
          }, 100);
        }
    }
    //console.log(searchTerm, 'searchFoundList :: ' + searchFoundList?.length);
    setViewFolderFiles(searchFoundList);
  }; */
  const initiateInContentSearch = async (attachmentRows, searchText) => {
    const promises = [];
    if (attachmentRows?.length > 0) {
      isContentSearchRunning(true);
      stopPDFInContentSearch.current = false;
      for (let file of attachmentRows) {
        if (stopPDFInContentSearch.current === true) {
          break;
        }
        let promise = await searchInPdfContent(file, searchText, file.url);
        promises.push(promise);
        if (stopPDFInContentSearch.current === true) {
          break;
        }
      }
    } else {
      stopPDFInContentSearch.current = true;
    }
    if (promises?.length > 0) {
      Promise.all(promises).then((values) => {
        //console.log('values :: ',values?.length);
        isContentSearchRunning(false);
      });
    } else {
      isContentSearchRunning(false);
    }
  }
  const searchInPdfContent = async (file_, searchTerm, fileUrl) => {
    const file = cloneDeep(file_);
    let pdfContent = await searchTextInPdfContentWebViewer(file.id, searchTerm, fileUrl, instanceRef.current);
    if (pdfContent && pdfContent.attachId && pdfContent.found) {
      setViewFolderFiles((preList) => {
        if (!preList) {
          preList = [];
        }
        const fInd = preList?.findIndex(
          (data) => data.id === file.id
        );
        file.in_content_found = true;
        if (fInd > -1) {
          preList[fInd] = file;
        } else {
          preList.push(file);
        }
        return preList;
      });
      return pdfContent;
    }
    return null;
  }

  const handleClickMenu = (event, parms) => {
    console.log('handleClickMenu :: ',parms);
    event.preventDefault();
    event.stopPropagation();
    setSelectedMenuRow(parms.row);
    setFileData(parms.row);
    setAnchorEl({ mouseX: event.clientX, mouseY: event.clientY });
  };
  const handleCloseMenu = () => {
    //console.log("handleCloseMenu handleCloseMenu");
    //  setEditSetAssignPopup(false);
    setFileData(null);
    setViewingIssue(null);
    setAnchorEl(null);
    setSelectedMenuRow(null);
  };

  const fileNameClick = (rowData) => {
    if (rowData.type === "file") {
      setFileData(rowData);
      handleClickOpen();
      return;
    }
    //console.log("row data is ", rowData);
    const imagesPdfExts = ["pdf", "jfif", "png", "jpeg", "jpg", "tiff", "tif"];
    // pdf and image files
    if ( sourceFolderDetail?.type?.toLowerCase() === "plan" && (!rowData.fileExt || rowData.fileExt === null) ) {
      //console.log("row data is ", rowData);
      rowData.fileExt = "pdf";
    }

    //   Videos
    const videoExts = ["mp4"];
    if (videoExts.includes(rowData.fileExt)) {
      //console.log("row data is ", rowData);
      setFileData(rowData);
      setOpenVideoPopup(true);
    }
  };
  const updateThumbnailToCurrentData = (attachId, thumUrl) => {
    
    //setSpinner(true);
    if (attachId) {
      let files = toUpdateElementbyId(attachId, thumUrl, currentFolderFileData.current, setCurrentFolderFiles);
      if(sourceFolderDetail?.id){
        setFolderIdToDataState(sourceFolderDetail?.id, files);
      }
      toUpdateElementbyId(attachId, thumUrl, viewFolderFiles, setViewFolderFiles);
      toUpdateElementbyId(attachId, thumUrl, filteredTableData, setFilteredTableData_);
      toUpdateElementbyId(attachId, thumUrl, folderFilesForSearch, setFolderFilesForSearch);

      function toUpdateElementbyId(attchId, thubUrl, _list, setToList) {
        let _tempList = cloneDeep(_list);
        if (_tempList && _tempList?.length > 0) {
          const fInd = _tempList?.findIndex(
            (data) => data.id === attchId
          );
          if (fInd > -1) {
            _tempList[fInd].thumbnail = thubUrl;
            if (setToList) {
              setToList(_tempList);
            }
          }
        }
        return _tempList;
      }
    }
    //setSpinner(false);
  };
  const updateStateTableDataWithRow = async (rowEle) => {
    
    setSpinner(true);
    if (rowEle.id) {
      let files = toReplaceElementbyId(rowEle, currentFolderFileData.current, setCurrentFolderFiles);
      if(sourceFolderDetail?.id){
        setFolderIdToDataState(sourceFolderDetail?.id, files);
      }
      toReplaceElementbyId(rowEle, viewFolderFiles, setViewFolderFiles , ['in_content_found' , 'name_mark' , 'title_mark']);
      toReplaceElementbyId(rowEle, filteredTableData, setFilteredTableData_);
      toReplaceElementbyId(rowEle, folderFilesForSearch, setFolderFilesForSearch);

      function toReplaceElementbyId(_ele, _list, setToList ,oldPropToKeep) {
        let ele = cloneDeep(_ele);
        let _tempList = cloneDeep(_list);
        if (_tempList?.length > 0) {
          const fInd = _tempList?.findIndex(
            (data) => data.id === ele.id
          );
          if (fInd > -1) {
            if(oldPropToKeep?.length > 0){
              for(let prop of oldPropToKeep){
                ele[prop] = _tempList[fInd][prop];
              }
            }
            _tempList[fInd] = ele;
            if (setToList) {
              setToList(_tempList);
            } // init folder files
          }
        }
        return _tempList;
      }
    }
    setSpinner(false);
  };
  const updateTableStateForLink = (links) => {
    if (links?.length > 0) {
      setSpinner(true);
      let files = toAddLinksToElementbyId(links, currentFolderFileData.current, setCurrentFolderFiles);
      if (sourceFolderDetail?.id) {
        setFolderIdToDataState(sourceFolderDetail?.id, files);
      }
      toAddLinksToElementbyId(links, viewFolderFiles, setViewFolderFiles);
      toAddLinksToElementbyId(links, filteredTableDataRow.current, setFilteredTableData_);
      toAddLinksToElementbyId(links, folderFilesForSearch, setFolderFilesForSearch);
      setSpinner(false);
    }
    function toAddLinksToElementbyId(links, _list, setToList) {
      let _tempList = cloneDeep(_list);
      if (_tempList?.length > 0) {
        let change = false;
        for (let lnk of links) {
          if (lnk.id) {
            let rowId = lnk.id;
            let link = lnk.link;
            const fInd = _tempList?.findIndex((data) => data.id === rowId);
            if (fInd > -1) {
              if(link){
                if (!(_tempList[fInd].Shared_Links__r?.length > 0)) {
                  _tempList[fInd].Shared_Links__r = [link];
                  change = true;
                }
              }else{
                if ((_tempList[fInd].Shared_Links__r?.length > 0)) {
                  _tempList[fInd].Shared_Links__r = [];
                  change = true;
                }
              }
            }
          }
        }
        if (change == true) {
          if (setToList) {
            setToList(_tempList);
          }
        }
      }
      return _tempList;
    }
  };
  const [selected_ids, setSelectedIds] = useState([]);
  const onRowSelectionChange = (ids, isChecked) => {
    let selectedId = [];
    if (isChecked === true) {
      selectedId = selected_ids.length > 0 ? selected_ids : [];
      selectedId?.push(ids);
    } else {
      selectedId = selected_ids.length > 0 ? selected_ids.filter((item) => item !== ids) : [];
    }
    setSelectedIds(selectedId);
    const selectedIds = new Set(selectedId);

    const selectedRows = viewFolderFiles.filter((row) =>
      selectedIds.has(row.id.toString())
    );
    setSelectedItems(selectedRows);
    const selectedFiless = selectedRows.filter((row) => row.type === "file");
    setSelectedFiles(selectedFiless);
  };
  const onRowSelectionAllChange = (isChecked) => {
    setAllSheetChecked(isChecked);
    setSelectedIds([]);
    let selectedId = [];
    if (isChecked === true) {
      selectedId = selected_ids.length > 0 ? [] : [];
      rowsPerPage.map((row) =>
        selectedId?.push(row.id)
      );
    } else {
      setSelectedIds([]);
    }
    setSelectedIds(selectedId);
    const selectedIds = new Set(selectedId);

    const selectedRows = viewFolderFiles.filter((row) =>
      selectedIds.has(row.id.toString())
    );
    setSelectedItems(selectedRows);
    const selectedFiless = selectedRows.filter((row) => row.type === "file");
    setSelectedFiles(selectedFiless);
  }
  const RenderTitleColumn = ({params})=>{
    let attachId = params.id;
    return (
    <div style={{ cursor: "pointer", width: "100%" }}>
      {params.row.type === "file" ? (
        <div onClick={(e) => { e.stopPropagation(); fileNameClick(params.row); }} style={{ display: "flex", width: "100%" ,gap:'10px' , alignItems : 'center'}} 
        onContextMenu={(e) => { 
          handleClickMenu(e , params);
        }}>
          {/* <img id={attachId + '_thumb_img'} src={params.row.thumbnail ? params.row.thumbnail : (attachmentThumbnail && attachmentThumbnail[attachId] ? attachmentThumbnail[attachId] : "icon.svg")} alt="loading_pdf" width="50" height="50"
            style={params.row.thumbnail ? {} : attachmentThumbnail && attachmentThumbnail[attachId] ? {} : { animation: 'animation-61bdi0 3.5s linear infinite' }}
            onClick={(evt) => {
              if (!attachmentThumbnail[attachId]) {
                genratePdfThumnail(attachId, params.row.url, true);
              }
            }} /> */}
          <img src={pdfRed} alt="pdfIcon" style={{height: '30px', width: "30px"}}/>
          <Tooltip title={params.row.tooltip} className="tooltip1">
            <div className="name_main" style={{color: "#505050", width: "90%" }}>
              <a className="title_link" href={params.row.inappurl} onClick={(e)=>{
                e.preventDefault();
              }}>
                <b style={{ color: "black" }}>
                  <span dangerouslySetInnerHTML={{ __html: params.row.name_mark ? params.row.name_mark : params.row.name }} />
                </b>
              </a>
              <br/>
              <span dangerouslySetInnerHTML={{ __html: params.row.title_mark ? params.row.title_mark : params.row.title }} />
            </div>
          </Tooltip>
          <div style={{ marginLeft: 'auto' , display: "flex" }}>
            {params.row.in_content_found && searchBoxFilter &&
              <Tooltip title={"The file contains text from the search results"} placement="bottom" >
                <img style={{ marginRight: "6px" }} src="/index search.svg" alt="Settig" />
              </Tooltip>
            }
            {params.row.Shared_Links__r?.length > 0  &&
              <Tooltip title="Document is being shared" placement="bottom" >
                <div style={{ marginLeft: 'auto' }} onClick={(e)=>{
                  e.stopPropagation();
                  setShareItems([params.row]);
                }}>
                  <img style={{ marginRight: "6px" }} src={copyLinkIcon} alt="copy" />
                </div>
              </Tooltip>
            }
          </div>
        </div>
      ) : (
        <>
          <Box onClick={() => { /*let objj = {id : params.row.id , row : params.row}; onRowClickFunc(params);*/ }} >
            <Folder index={params.row.id} color="action" />
            <span dangerouslySetInnerHTML={{ __html: params.row.name_mark ? params.row.name_mark : params.row.name }} />
          </Box>
        </>
      )}
    </div>
    )
  };
  const columns_react_table = [
    {
      id: "checkbox",
      accessor: "",
      disableSortBy: true,
      Header: (
        <Checkbox
          style={{ padding: "0px", marginLeft: "20px" }}
          sx={{ padding: "0px", "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
          checked={selected_ids.length > 0}
          onChange={(e) =>{
            onRowSelectionAllChange(e.target.checked);
          }}
        />
      ),
      Cell: (rowInfo) => {
        let fileData = selectedFiles.filter(
          (row) => row.id === rowInfo?.row?.original?.id
        );

        let folderData = selectedItems.filter(
          (row) => row.id === rowInfo?.row?.original?.id
        );
        return (
          <Checkbox
            style={{ padding: "0px", marginLeft: "20px" }}
            sx={{ "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
            type="checkbox"
            className="checkbox"
            checked={fileData.length > 0 || folderData.length > 0}
            onChange={(e) =>
              onRowSelectionChange(rowInfo?.row?.original?.id, e.target.checked)
            }
          />
        );
      },
      overrideStyle: {width: "5%"}
    },
    {
      accessor: "name",
      Header: "Number",
      sortType: "basic",
      disableColumnHide: true,
      className: "hide-menu-dot",
      _sortItems: (prev, curr, columnId) => {
        return _sortItems(prev, curr, columnId);
      },
      Cell: (params_) => {
        let params = { id: params_.row.original.id, row: params_.row.original };
        let attachId = params_.row.original.id;
        return (
          <RenderTitleColumn params={params}/>
        );
      },
      overrideStyle: {width: "45%"}
    },
    {
      accessor: "set",
      Header: "Set Name",
      flex: 1,
      disableColumnHide: true,
      sortType: "basic",
      overrideStyle: {width: "8%"},
      Cell: (rowInfo) => {
        return (
           <Tooltip title={rowInfo.value} className="tooltip1">
          <div>
            {rowInfo.value}
          </div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "currentVersion",
      Header: "Version",
      disableColumnHide: true,
      sortType: "basic",
      Cell: (params) => (
        <span title={params.row.original.currentVersion}>
          <VersionsDialog
            version={params.row.original.currentVersion}
            fileInfo={params.row.original}
          />
        </span>
      ),
      overrideStyle: {width: "8%"}
    },
    {
      accessor: "discipline",
      Header: "Discipline",
      width: 190,
      flex: 1,
      disableColumnHide: true,
      sortType: "basic",
      overrideStyle: {width: "8%"},
      Cell: (rowInfo) => {
        return (
           <Tooltip title={rowInfo.row.original.discipline ? rowInfo.row.original.discipline  : noDisciplineLabel} className="tooltip1">
          <div>
            {rowInfo.row.original.discipline ? rowInfo.row.original.discipline  : noDisciplineLabel}
          </div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "markupCount",
      Header: "Markup",
      flex: 1,
      sortType: "basic",
      Cell: (rowInfo) => {

        return (
          <div>
            <img
              style={{ marginRight: "6px" }}
              src={rowInfo.row.original.markupCount > 0 ? "/markupdark.svg" : "/markup.svg"}
              alt="Settig"
            />
            {rowInfo.row.original.markupCount}
          </div>
        );
      },
      overrideStyle: {width: "8%"}
    },
    {
      accessor: "issue",
      Header: "Issue",
      flex: 1,
      sortType: "basic",
      Cell: (rowInfo) => {
        const nonDraftIssues = rowInfo.row.original.issueList?.filter(issue => (issue.Status__c !== 'Draft' || issue.CreatedById === credential?.userId));
        const countNonDraftIssues = nonDraftIssues?.length;
        return (
          <div>
            <img style={{ marginRight: "6px" }} src={countNonDraftIssues > 0 ? "/errordark.svg" : "/error.svg"} alt="Settig" />
            {countNonDraftIssues}
          </div>
        );
      },
      overrideStyle: {width: "8%"}
    },
    // {
    //   accessor: "reviewStatus",
    //   Header: "Review status",
    //   sortType: "basic",
    //   Cell: (rowInfo) => {
    //     const [reviewS, setReviewS] = useState(
    //       rowInfo.row.original.Document_Reviews__r?.records?.length > 0 &&
    //         rowInfo.row.original.Document_Reviews__r.records[0].Review__r
    //         ? rowInfo.row.original.Document_Reviews__r.records[0].Review__r
    //         : null
    //     );
    //     return (
    //       <div>
    //         {reviewS && (
    //           <div>
    //             {reviewS.Stage__c === "Open" && "Pending Review"}
    //             {reviewS.Stage__c === "Void" && "Declined"}
    //             {reviewS.Stage__c !== "Open" && reviewS.Stage__c !== "Void" && (
    //               <>
    //                 {reviewS.Rejected_Document_Count__c > 0 && "Rejected"}
    //                 {!(reviewS.Rejected_Document_Count__c > 0) ? (
    //                   <>
    //                     <img src="/approved.svg" alt="approved" /> Approved
    //                   </>
    //                 ) : (
    //                   ""
    //                 )}
    //               </>
    //             )}
    //           </div>
    //         )}
    //       </div>
    //     );
    //   },
    //   overrideStyle: {width: "20%"}
    // },

    {
      accessor: "updatedBy",
      Header: " Last Updated By",
      flex: 1,
      sortType: "basic",
      Cell: (row) => {
        return (
          <div style={{ display: "flex" }}>
            <img src="user.svg" alt="saaa" />
            <div style={{ marginLeft: "5px", marginTop: "8px", color: "#505050" }}>
              <div style={{ color: "#505050" }}>
                {row.value}
              </div>
              <div style={{ color: "#929292" }}>
                on {moment(row.row.original.LastModifiedDate).format('MMMM D, YYYY HH:mm')}
              </div>
            </div>
          </div>
        );
      },
      overrideStyle: {width: "10%"}
    },
    {
      accessor: "settings",
      Header: "settings",
      flex: 1,
      sortType: "basic",
      Cell: (row) => {
        /* Add title */
        return <span title={row.value}>{row.value}</span>;
      },
      overrideStyle: {width: "5%"}
    },
  ];
  const Shop_Drawings = [
    {
      id: "checkbox",
      accessor: "",
      disableSortBy: true,
      Header: (
        <Checkbox
          style={{ padding: "0px", marginLeft: "20px" }}
          sx={{ padding: "0px", "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
          checked={selected_ids.length > 0}
          onChange={(e) =>{
            onRowSelectionAllChange(e.target.checked);
          }}
        />
      ),
      Cell: (rowInfo) => {
        let fileData = selectedFiles.filter(
          (row) => row.id === rowInfo?.row?.original?.id
        );

        let folderData = selectedItems.filter(
          (row) => row.id === rowInfo?.row?.original?.id
        );
        return (
          <Checkbox
            style={{ padding: "0px", marginLeft: "20px" }}
            sx={{ "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
            type="checkbox"
            className="checkbox"
            checked={fileData.length > 0 || folderData.length > 0}
            onChange={(e) =>
              onRowSelectionChange(rowInfo?.row?.original?.id, e.target.checked)
            }
          />
        );
      },
      overrideStyle: {width: "5%"}
    },
    {
      accessor: "name",
      Header: "Number",
      sortType: "basic",
      disableColumnHide: true,
      className: "hide-menu-dot",
      _sortItems: (prev, curr, columnId) => {
        return _sortItems(prev, curr, columnId);
      },
      Cell: (params_) => {
        let params = { id: params_.row.original.id, row: params_.row.original };
        let attachId = params_.row.original.id;
        return (
          <RenderTitleColumn params={params}/>
        );
      },
      overrideStyle: {width: "40%"}
    },
    {
      accessor: "currentVersion",
      Header: "Version",
      disableColumnHide: true,
      sortType: "basic",
      Cell: (params) => (
        <span title={params.row.original.currentVersion}>
          <VersionsDialog
            version={params.row.original.currentVersion}
            fileInfo={params.row.original}
          />
        </span>
      ),
      overrideStyle: {width: "7%"}
    },
    {
      accessor: "shopDrawing",
      Header: "Types",
      flex: 1,
      disableColumnHide: true,
      sortType: "basic",
      overrideStyle: {width: "12%"},
      Cell: (rowInfo) => {
        return (
           <Tooltip title={rowInfo.value} className="tooltip1">
          <div>
            {rowInfo.value}
          </div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "markupCount",
      Header: "Markup",
      flex: 1,
      sortType: "basic",
      overrideStyle: {width: "8%"},
      Cell: (rowInfo) => {
        //console.log("rowInfo.row.original", rowInfo.row.original.markupCount);
        /* Add title */
        return (
          <div>
            <img
              style={{ marginRight: "6px" }}
              src="/markup.svg"
              alt="Settig"
            />
            {rowInfo.row.original.markupCount}
          </div>
        );
      },
    },
    {
      accessor: "issue",
      Header: "Issue",
      flex: 1,
      sortType: "basic",
      Cell: (rowInfo) => {
        //console.log("rowInfo.row.original", rowInfo.row.original.markupCount);
        /* Add title */
        return (
          <div>
            <img style={{ marginRight: "6px" }} src="/error.svg" alt="Settig" />
            0
          </div>
        );
      },
      overrideStyle: {width: "8%"}
    },
    // {
    //   accessor: "reviewStatus",
    //   Header: "Review status",
    //   sortType: "basic",
    //   overrideStyle: {width: "20%"},
    //   Cell: (rowInfo) => {
    //     const [reviewS, setReviewS] = useState(
    //       rowInfo.row.original.Document_Reviews__r?.records?.length > 0 &&
    //         rowInfo.row.original.Document_Reviews__r.records[0].Review__r
    //         ? rowInfo.row.original.Document_Reviews__r.records[0].Review__r
    //         : null
    //     );
    //     return (
    //       <div>
    //         {reviewS && (
    //           <div>
    //             {reviewS.Stage__c === "Open" && "Pending Review"}
    //             {reviewS.Stage__c === "Void" && "Declined"}
    //             {reviewS.Stage__c !== "Open" && reviewS.Stage__c !== "Void" && (
    //               <>
    //                 {reviewS.Rejected_Document_Count__c > 0 && "Rejected"}
    //                 {!(reviewS.Rejected_Document_Count__c > 0) ? (
    //                   <>
    //                     <img src="/approved.svg" alt="approved" /> Approved
    //                   </>
    //                 ) : (
    //                   ""
    //                 )}
    //               </>
    //             )}
    //           </div>
    //         )}
    //       </div>
    //     );
    //   },
    // },

    {
      accessor: "updatedBy",
      Header: "Last Updated By",
      flex: 1,
      sortType: "basic",
      Cell: (row) => {
        return (
          <div style={{ display: "flex", }}>
          <img src="user.svg" alt="saaa" />
          <div style={{ marginLeft: "5px", color: "#505050" }}>
            <div style={{ color: "#505050" }}>
              {row.value}
            </div>
            <div style={{ color: "#929292" }}>
              on {moment(row.row.original.LastModifiedDate).format('MMMM D, YYYY HH:mm')}
            </div>
          </div>
        </div>
        );
      },
      overrideStyle: {width: "15%"}
    },
    {
      accessor: "settings",
      Header: "settings",
      flex: 1,
      sortType: "basic",
      Cell: (row) => {
        /* Add title */
        return <span title={row.value}>{row.value}</span>;
      },
      overrideStyle: {width: "5%"}
    },
  ];
  function setCurrentFolderFiles(filesData, setInView) {
    currentFolderFileData.current = filesData;
    setFolderFiles(filesData);
    if (setInView === true) {
      setFilteredTableData_([]);
      setViewFolderFiles(filesData); // init folder files for view
    }
    return filesData;
  }
  async function getSourceFolder() {
    let subFoldermap;
    if (!instanceRef.current) {
      await loadSampleDocument();
    }
    await axios.get(`${fetchUrl}/sourceFolder/Plan/${credential?.projectId}`)
      .then((res) => {
        if (res?.data?.status === 200) {
          const { tree } = res?.data;
          if (tree?.sub_folders?.length > 0) {
            for (let fol of tree?.sub_folders) {
              if (!subFoldermap) {
                subFoldermap = {};
              }
              if (!subFoldermap[fol.Name]) {
                subFoldermap[fol.Name] = fol;
              }
            }
          }
        }
      }).catch((err) => {
        console.log("/sourceFolder_/", err);
      });
    setSubFolderMap(subFoldermap);
    if (subFoldermap) {
      await setCurrentTabFolder(activeTabRef.current, subFoldermap);
    }
    setSpinner(false);
    setFolderFileLoading(false);
  }
  async function setCurrentTabFolder(_activeTab = activeTab, subFoldermap = folderNameToIdMap) {
    if(cancelAction.current){
      cancelAction.current();
    }
    if(cancelAction0.current){
      cancelAction0.current();
    }
    //console.log("setCurrentTabFolder", _activeTab, JSON.stringify(subFoldermap));
    let re;
    //console.log("sourceFolderDetail",sourceFolderDetail);
    if ((_activeTab === 1 || _activeTab === 0) && subFoldermap) {
      //console.log("sourceFolderDetail1");
      setSourceFolder(null);
      let curfolderName = _activeTab === 0 ? Contract_Folder_Name : Shop_Folder_Name;
      if (subFoldermap[curfolderName]) {
        re = { id: subFoldermap[curfolderName].Id, name: subFoldermap[curfolderName].Name, type: subFoldermap[curfolderName].Folder_Type__c };
      }
      setSourceFolder(re);
      if (re) {
        await refreshFolderFiles(re.id, false);
      }
    } else if (!subFoldermap) {
      resetCurrentFolderTableState();
      getSourceFolder();
    }
  }
  async function refreshFolderFiles(id , clearFilter = true) {
    //console.log("refresh FolderFiles ::", id);
    if(clearFilter == true){
      resetCurrentFolderTableState(clearFilter);
    }
    //await _getFolderFileApi(id);
    getFolderFiles(id);
    await getFolderAttachments(id);
  }
  async function resetCurrentFolderTableState(clearFilter = true) {
    if(clearFilter == true){
      handleClearAllFilters();
    }
    setSelectedIds([]);
    setCurrentFolderFiles([], true);
    setFolderFilesForSearch([]);
    setSelectedItems([]);
    setSelectedFiles([]);
    generatePdfThumbnailMapPromise([]);
  }
  // async function _getFolderFileApi(folderId) {
  //   setFolderFileLoading(true);
  //   setShowLogs(false);
  //   setShowRecyclebin(false);
  //   //console.log('folderIdToData ',folderIdToData);
  //   if(folderIdToData && folderIdToData[folderId]?.length > 0){
  //     if(folderIdToData[folderId]){
  //       processTofilter_.current = true;
  //       setCurrentFolderFiles(folderIdToData[folderId], true);
  //       setFolderFilesForSearch(folderIdToData[folderId]);
  //       //generatePdfThumbnailMapPromise(folderIdToData[folderId]);
  //       handleAppFilterState();
  //     }
  //   }else{
  //     resetCurrentFolderTableState(false);
  //   }
  //   let reqUrl = `${fetchUrl}/folderfiles/${folderId}`;
  //   var disValues = [{label: noDisciplineLabel , value : noDisciplineLabel ,key : noDisciplineLabel}];
  //   let usersList = credential?.userList?.length > 0 ? cloneDeep(credential?.userList) : [];
  //   let withfile = [];
  //   let activeTab = activeTabRef.current;
  //   const { token, cancel } = axios.CancelToken.source();
  //   cancelAction.current = cancel;
  //   //console.log('active tab',activeTab);
  //   processTofilter_.current = true;
  //   await axios.get(reqUrl,{cancelToken:token})
  //     .then(async (res) => {
  //       if (res?.status === 200) {
  //         const { files } = res?.data;
  //         //console.log("tree ::", tree);
  //         //let withfile = [...data];
  //         if (files?.length > 0) {
  //           let inddd = 1; 
  //           for (let file of files) {
  //             let fObj = await prepareAmazonRecordRow(file, credential, 'Sheets');
  //             if(fObj.disciplineId){
  //               let find = disValues.findIndex((t) => t.value === fObj.disciplineId);
  //               if( !(find > -1) ){
  //                 disValues.push({label: fObj.discipline , value : fObj.disciplineId ,key : fObj.disciplineId});
  //               }
  //             }
  //             if(fObj.updatedById){
  //               let find = usersList.findIndex((u) => u.value === fObj.updatedById);
  //               if( !(find > -1) ){
  //                 usersList.push({Id: fObj.updatedById, Name : fObj.updatedBy, value:fObj.updatedById, label: fObj.updatedBy , key : fObj.updatedById})
  //               }
  //             }
  //             if (attachmentThumbnail && attachmentThumbnail[fObj.id]) {
  //               fObj.thumbnail = attachmentThumbnail[fObj.id];
  //             }
  //             withfile.push(fObj);
  //             inddd++
  //           }
  //         }
  //         setCurrentFolderFiles(withfile, true);
  //         setFolderFilesForSearch(withfile);
  //         generatePdfThumbnailMapPromise(withfile);
  //         setFolderIdToDataState(folderId , withfile);
  //         //console.log("withfile2", withfile);
  //       }
  //     }).catch((err) => {
  //       console.log(err);
  //       setSpinner(false);
  //       if (axios.isCancel(err)) {
  //         // Handle the cancellation
  //         console.log('Request canceled by user',activeTab);
  //       }
  //     });
  //   setUserList(usersList);
  //   if(activeTabRef.current === 0){
  //     setDisciplineOptions(disValues);
  //   }
  //   if(activeTab === activeTabRef.current){
  //     setFolderFileLoading(false);
  //   }
  //   handleAppFilterState();
  //   setSpinner(false);
  // }
  async function getFolderFiles(folderId) {
    currentFolderFileData.current = [];
    setFolderFiles([]);
    setFolderFilesForSearch([]);
    setAllDataFetched(false);
    let reqUrl = `${fetchUrl}/folderfiles/${folderId}`;
    let withfile = [];
    let activeTab = activeTabRef.current;
    const { token, cancel } = axios.CancelToken.source();
    cancelAction.current = cancel;
    //console.log('active tab',activeTab);
    processTofilter_.current = true;
    await axios.get(reqUrl,{cancelToken:token})
      .then(async (res) => {
        if (res?.status === 200) {
          const { files } = res?.data;
          //console.log("tree ::", tree);
          //let withfile = [...data];
          if (files?.length > 0) {
            for (let file of files) {
              let fObj = await prepareAmazonRecordRow(file, credential, 'Sheets');
              if (attachmentThumbnail && attachmentThumbnail[fObj.id]) {
                fObj.thumbnail = attachmentThumbnail[fObj.id];
              }
              withfile.push(fObj);
            }
          }
          currentFolderFileData.current = withfile;
          setFolderFiles(withfile);
          setFolderFilesForSearch(withfile);
          setFolderIdToDataState(folderId , withfile);
          setAllDataFetched(true);
        }
      }).catch((err) => {
        console.log(err);
        setSpinner(false);
        if (axios.isCancel(err)) {
          // Handle the cancellation
          console.log('Request canceled by user',activeTab);
        }
      });
  }
  async function getFolderAttachments(folderId , searchQuery ,stopFilter = false) {
    setFolderFileLoading(true);
    setSelectedIds([]);
    setShowLogs(false);
    setShowRecyclebin(false);
    //console.log('folderIdToData ',folderIdToData);
    let fQuery = getCurrentFilterQuery();
    if(stopFilter === true){
      fQuery = undefined;
    }
    // if(!searchQuery){
    //   if(folderIdToData && folderIdToData[folderId]?.length > 0){
    //     if(folderIdToData[folderId]){
    //       processTofilter_.current = true;
    //       setCurrentFolderFiles(folderIdToData[folderId], false);
    //       setFolderFilesForSearch(folderIdToData[folderId]);
    //       //generatePdfThumbnailMapPromise(folderIdToData[folderId]);
    //       handleAppFilterState();
    //     }
    //   }else{
    //     resetCurrentFolderTableState(false);
    //   }
    // }
    let reqUrl = `${fetchUrl}/get_folder_attachments/${folderId}`;
    let reqBody = {};
    if(fQuery){
      reqBody.filterQuery = fQuery;
    }else if(searchQuery){
      reqBody.searchQuery = searchQuery;
    }
    if(!searchQuery && !fQuery){
      reqBody.limit = 'LIMIT 300';
    }
    let usersList = credential?.userList?.length > 0 ? cloneDeep(credential?.userList) : [];
    let withfile = [];
    let activeTab = activeTabRef.current;
    const { token, cancel } = axios.CancelToken.source();
    cancelAction0.current = cancel;
    //console.log('active tab',activeTab);
    processTofilter_.current = false;
    await axios.post(reqUrl,reqBody,{cancelToken:token})
      .then(async (res) => {
        if (res?.status === 200) {
          const { files } = res?.data;
          //console.log("tree ::", tree);
          //let withfile = [...data];
          if (files?.length > 0) {
            for (let file of files) {
              let fObj = await prepareAmazonRecordRow(file, credential, 'Sheets');
              if (attachmentThumbnail && attachmentThumbnail[fObj.id]) {
                fObj.thumbnail = attachmentThumbnail[fObj.id];
              }
              if(searchQuery){
                if(fObj.name && fObj.name.toLowerCase().includes(searchQuery)){
                  fObj.name_mark  = highlightText(fObj.name, searchQuery);
                }
                if(fObj.title && fObj.title.toLowerCase().includes(searchQuery)){
                  fObj.title_mark  = highlightText(fObj.title, searchQuery);
                }
              }
              withfile.push(fObj);
            }
          }
          setPaginationPageIndex(0);
          setViewFolderFiles(withfile);
          if(reqBody.limit){
            // setCurrentFolderFiles(withfile, true);
            // setFolderIdToDataState(folderId , withfile);
          }else{
            setViewFolderFiles(withfile);
            if(fQuery){
              setFilteredTableData_(withfile);
            }
          }
        }
      }).catch((err) => {
        console.log(err);
        setSpinner(false);
        if (axios.isCancel(err)) {
          // Handle the cancellation
          console.log('Request canceled by user',activeTab);
        }
      });
    setUserList(usersList);
    if(activeTab === activeTabRef.current){
      setFolderFileLoading(false);
    }
    setSpinner(false);
  }
  const getCurrentFilterQuery = ()=>{
    let filters = [];
    //if(totalFilterAppliedRef.current > 0){
      if(activeTabRef.current == 0){
        if(sheetVersionSetActiveFilters[credential?.projectId]){
          let vls = sheetVersionSetActiveFilters[credential?.projectId]?.map(v=>v.value);
          if(vls?.length > 0 ){
            let fts = joinStringForQuery(vls);
            filters.push(`Set__c IN ${fts}`);
          }
        }
        if(sheetDisciplineActiveFilters[credential?.projectId]){
          let vls = sheetDisciplineActiveFilters[credential?.projectId]?.map(v=>v.value);
          if(vls?.length > 0 ){
            let fts = joinStringForQuery(vls);
            filters.push(`Discipline__c IN ${fts}`);
          }
        }
      }
      if(activeTabRef.current == 1){
        if(sheetTypeActiveFilters[credential?.projectId]){
          if(sheetTypeActiveFilters[credential?.projectId].length > 0 ){
            let fts = joinStringForQuery(sheetTypeActiveFilters[credential?.projectId]);
            filters.push(`Shop_Drawing_Type__c IN ${fts}`);
          }
        }
      }
      if(sheetUpdatedByActiveFilters[credential?.projectId]){
        let vls = sheetUpdatedByActiveFilters[credential?.projectId]?.map(v=>v.value);
        if(vls?.length > 0 ){
          let fts = joinStringForQuery(vls);
          filters.push(`LastModifiedById IN ${fts}`);
        }
      }
    //}
    return filters.length > 0 ? filters.join(' AND ') : undefined;
  };
  const joinStringForQuery = (items = [])=>{
    return `('${items.join("','")}')`;
  };
  const handleAppFilterState = ()=>{
    processTofilter_.current = false;
    setVersionSetActiveFilters(sheetVersionSetActiveFilters[credential?.projectId]);
    setTypeActiveFilters(sheetTypeActiveFilters[credential?.projectId]);
    setDisciplineActiveFilters(sheetDisciplineActiveFilters[credential?.projectId]);
    setReviewStatusActiveFilters(sheetReviewStatusActiveFilters[credential?.projectId]);
    processTofilter_.current = true;
    setUpdatedByActiveFilters(sheetUpdatedByActiveFilters[credential?.projectId]);
  }
  const handleClickOpen = () => {
    //console.log("setopen true " + pdfopenfiledata?.url)
    setOpen(true);
  };

  const handleCloseVideo = () => {
    setFileData(null);
    setViewingIssue(null);
    setOpenVideoPopup(false);
  };

  const onRowClickFunc = async (parma) => {
    if (parma) {
      if (parma?.row?.filePath) {
        fileNameClick(parma?.row);
      } else {
        openCurrentFolder({ id: parma?.id });
      }
    }
  };
  const openCurrentFolder = async (selectedFolder) => {
    //console.log('openCurrentFolder');
    setFolderFileLoading(true);
    //console.log("open current folder ::", selectedFolder);
    //console.log("resTreeData", resTreeData);
    /*await setFolderTreeOnView(selectedFolder, resTreeData);*/
  };
  const deleteSelectedItems = async () => {
    //console.log("deleteSelectedItems :: ");
    if (selectedItems.length > 0) {
      setSpinner(true);
      let k = 0;
      Promise.all(selectedItems).then((values) => {
        values.forEach(async (f) => {
          await deleteItemStart(f.type, f.id).catch((err) => {
            console.log(err);
          });
          //console.log('k ',k);
          k++;
          if (k === selectedItems.length) {
            setSelectedMenuRow(null);
            toast.success("Items Deleted");
            if (sourceFolderDetail?.id) {
              await refreshFolderFiles(sourceFolderDetail.id);
              //console.log("dcsww ::",selectedItems);
            }
            setSpinner(false);
          }
        });
      });
    }
  };
  async function onClickDeleteItems() {
    setDeleteOpen(false);
    if (actionCalledFor === "multiple") {
      await deleteSelectedItems();
    } else if (actionCalledFor === "basic-menu") {
      await deleteMenuItem();
    }
  }
  async function deleteMenuItem() {
    setSpinner(true);
    console.log("selectedMenuRow", selectedMenuRow);
    await deleteItemStart(selectedMenuRow.type, selectedMenuRow.id)
      .then(async () => {
        setSelectedMenuRow(null);
        toast.success("Item Deleted");
        if (sourceFolderDetail?.id) {
          await refreshFolderFiles(sourceFolderDetail.id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setSpinner(false);
  }
  async function deleteItemStart(type, itemId) {
    await axios.put(`${fetchUrl}/recycle/${type}/${itemId}`);
  }
  async function closeMove() {
    setmovePopup(false);
    handleCloseMenu();
  }
  async function onClickMoveItems(moveFolderId) {
    setmovePopup(false);
    if (actionCalledFor === "multiple") {
      await moveSelectedItems(moveFolderId);
    } else if (actionCalledFor === "basic-menu") {
      await moveMenuItem(moveFolderId);
      handleCloseMenu();
    }
  }
  const moveSelectedItems = async (moveFolderId) => {
    //console.log("moveSelectedItems :: ");
    if (selectedItems.length > 0) {
      setSpinner(true);
      let k = 0;
      Promise.all(selectedItems).then((values) => {
        values.forEach(async (f) => {
          await moveItemStart(f.id, moveFolderId).catch((err) => {
            console.log(err);
          });
          //console.log('k ',k);
          k++;
          if (k === selectedItems.length) {
            setSelectedMenuRow(null);
            toast.success("Items Moved.");
            if (sourceFolderDetail?.id) {
              await refreshFolderFiles(sourceFolderDetail.id);
            }
            setSpinner(false);
          }
        });
      });
    }
  };
  async function moveMenuItem(moveFolderId) {
    setSpinner(true);
    await moveItemStart(selectedMenuRow.id, moveFolderId)
      .then(async () => {
        setSelectedMenuRow(null);
        toast.success("Item Moved");
        if (sourceFolderDetail?.id) {
          await refreshFolderFiles(sourceFolderDetail.id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setSpinner(false);
  }
  async function moveItemStart(itemId, moveFolderId) {
    await axios.put(
      `${fetchUrl}/move/${itemId}/${moveFolderId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`
    );
  }

  /**
   * Export files with markup
   */
  const browserWarningCheck = true;
  const [activeExportDialog, setActiveExportDialog] = useState(false);
  const [activeExportStep, setActiveExportStep] = useState(1);

  const handleOpenExportDialog = (eType) => {
    if (browserWarningCheck) {
      setActiveExportStep(2);
    } else {
      setActiveExportStep(2);
    }
    setActionCalledFor(eType);
    setActiveExportDialog(true);
  };

  const handleCloseExportDialog = () => {
    setActiveExportStep(1);
    setActiveExportDialog(false);
    setSelectedMenuRow(null);
  };
  const onClickClearAll = () =>{
    handleClearAllFilters();
    handleClearProjectAppStateFilters();
    if(!allDataFetchedRef.current){
      if(sourceFolderDetail?.id){
        if(cancelAction0.current){
          cancelAction0.current();
        }
        getFolderAttachments(sourceFolderDetail?.id , null , true);
      }
    }
  };
  const handleClearAllFilters = () => {
    setTotalFilterApplied(0);
    setSearchText("");
    setActiveFilterDropdown(undefined);
    setFilteredTableData_([]);
    if(activeTabRef.current === 0 ){
      setVersionSetActiveFilters([]);
      setDisciplineActiveFilters([]);
    }
    if(activeTabRef.current === 1 ){
      setTypeActiveFilters([]);
    }
    setUpdatedByActiveFilters([]);
    setReviewStatusActiveFilters([]);
    setViewFolderFiles(currentFolderFiles);
  };
  const handleClearProjectAppStateFilters = () => {
    let payload = {projectId : credential?.projectId , data : []};
    if(activeTabRef.current === 0 ){
      dispatch(setSheetVersionSetActiveFilters(payload));
      dispatch(setSheetDisciplineActiveFilters(payload));
    }
    if(activeTabRef.current === 1 ){
      dispatch(setSheetTypeActiveFilters(payload));
    }
    dispatch(setSheetReviewStatusActiveFilters(payload));
    dispatch(setSheetUpdatedByActiveFilters(payload));
  };

  const hideFilterDropDowns = () => {
    setActiveFilterDropdown(undefined);
  };
  function onActiveTabChange(tabValue) {
    //console.log('tabValue ::',tabValue);
    setActiveTab_(tabValue);
    if (tabValue === 0) {
      //handleClearAllFilters();
      setViewFolderFiles([]);
      setActiveFilterDropdown(undefined);
      setShowShopDrawings(false);
      setShowRecyclebin(false);
      setShowLogs(false);
      setCurrentTabFolder(tabValue, folderNameToIdMap);
    } else if (tabValue === 1) {
      //handleClearAllFilters();
      setViewFolderFiles([]);
      setActiveFilterDropdown(undefined);
      setShowShopDrawings(true);
      setShowLogs(false);
      setShowRecyclebin(false);
      setCurrentTabFolder(tabValue, folderNameToIdMap);
    } else if (tabValue === 2) {
      setShowShopDrawings(false);
      setShowLogs(true);
      setShowRecyclebin(false);
    } else if (tabValue === 3) {
      setShowShopDrawings(false);
      setShowRecyclebin(true);
      setShowLogs(false);
    }
  }
  function setActiveTab_(tabValue) {
    activeTabRef.current = tabValue;
    setActiveTab(tabValue);
    if (!instanceRef.current) {
      setTimeout(() => {
        loadSampleDocument();
      }, 500);
    }
  }
  return (
    <Design>
      <div className="wrapper_main">
        <div className="table_head">
          <div className="header_heading">
            <h1 onClick={() => {
                /* let reqb = {qry : 'SELECT Id FROM Account LIMIT 1'}
                  axios.post(
                    `${fetchUrl}/execute`,reqb
                  ).then(res=>{
                    console.log('res ::',res);
                    console.log('res.status ::',res.status);
                  }).catch(err=>{
                    console.log('execute' , err);
                  }); */
                if (sourceFolderDetail?.id) {
                  refreshFolderFiles(sourceFolderDetail.id);
                }
              }}
            >
              Sheets
            </h1>
          </div>
          <div className="right_side">
            <Button
              component="button"
              className="custom_button apply_button"
              onClick={() =>
                navigate("/add_sheets", { state: { activeTabId: activeTab } })
              }
            >
              Add Sheets
            </Button>
          </div>
        </div>
        <div className="tab_wrapper" style={{ marginLeft: "16px" }}>
        <div ref={pdfContainerRef} style={{ display: "none" }}></div>
          <Tabs
            value={activeTab}
            textColor="primary"
            indicatorColor="primary"
            onChange={(event, newValue) => {
              onActiveTabChange(newValue);
            }}
          >
            <Tab label="Contract Drawings" />
            <Tab label="Shop Drawings" />
            <Tab
              label={
                <div className="publish-logs">
                  <div className="text-and-kpi">
                    <div className="label poppins-normal-blue-14px">
                      {" "}
                      Published Log
                    </div>
                    <div className="kpi">
                      <div className="x1 poppins-normal-white-10px">
                        {" "}
                        {readyForReviewCount}{" "}
                      </div>
                    </div>{" "}
                  </div>
                </div>
              }
            />
            <Tab label="Deleted Sheets" />
          </Tabs>
        </div>
        {showlogs === true || showRecyclebin === true ? (
          <>
            {showlogs === true ? (
              <UploadLogs
                credential={credential}
                setActiveTab={onActiveTabChange}
                activeTab={activeTab}
                setShowLogsData={() => {
                  //console.log('setShowLogsData called');
                  getUnPublishBinderCount();
                  setShowLogs(false);
                }}
                getCountById={() => {
                  getUnPublishBinderCount();
                }}
                showInFolder={(selectedFolder) => {
                  openCurrentFolder(selectedFolder);
                  setShowLogs(false);
                }}
                readyForReviewCount
                project_Id={credential?.projectId}
              />
            ) : (
              <RecycleBin
                credential={credential}
                setActiveTab={onActiveTabChange}
                activeTab={activeTab}
                _reloadRecycle={setShowRecyclebin}
                handleCloseBin={() => {
                  setShowRecyclebin(false);
                }}
                webInstance={instanceRef.current}
              />
            )}
          </>
        ) : (
          <>
            <div
              className="table_head main_table"
              onClick={hideFilterDropDowns}
            >
              <div className="left_side">
                {selected_ids.length > 0 && (
                  <div
                    className="buttons"
                    onClick={(e) => onRowSelectionAllChange(e.target.checked)}
                  >
                    <img
                      className="close"
                      src="close.svg"
                      alt="close"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                )}
                <div
                  style={{ borderRight: "1px solid #EAEAEA", width: "100px" }}
                >
                  <h2>{selected_ids.length} Selected</h2>
                </div>
                <div
                  style={{
                    borderRight: "1px solid #EAEAEA",
                    borderTop: "1px solid #EAEAEA",
                    borderBottom: "1px solid #EAEAEA",
                    borderLeft: "1px solid #EAEAEA",
                    display: "flex",
                  }}
                >
                  {selectedFiles.length > 0 && (
                    <div style={{ borderRight: "1px solid #EAEAEA" }}>
                      <Tooltip
                        title="Export"
                        placement="top"
                        PopperProps={{ className: "version-action-tooltip" }}
                        arrow
                      >
                        <IconButton
                          sx={{ borderRadius: 0 }}
                          onClick={() => {
                            handleOpenExportDialog("multiple");
                          }}
                        >
                          <img src="/download.svg" alt="download" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {selectedItems.length > 0 && (
                    <div style={{ borderRight: "1px solid #EAEAEA" }}>
                      <Tooltip title="Share" placement="top" PopperProps={{ className: "version-action-tooltip" }} arrow >
                        <IconButton
                          sx={{ borderRadius: 0 }}
                          onClick={() => {
                            setActionCalledFor("multiple");
                            setShareItems(selectedItems);
                          }}
                        >
                          <img src="/share.svg" alt="share" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {selectedFiles.length > 0 && (
                    <div style={{ borderRight: "1px solid #EAEAEA" }}>
                      <Tooltip
                        title="Delete"
                        placement="top"
                        PopperProps={{ className: "version-action-tooltip" }}
                        arrow
                      >
                        <IconButton
                          sx={{ borderRadius: 0 }}
                          onClick={() => {
                            setActionCalledFor("multiple");
                            setDeleteOpen(true);
                          }}
                        >
                          <img src="/delete.svg" alt="download" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {selectedFiles.length > 0 && (
                    <div style={{ borderRight: "1px solid #EAEAEA" }}>
                      <Tooltip
                        title={showShopDrawings ? "Edit Type" :"Edit set"}
                        placement="top"
                        PopperProps={{ className: "version-action-tooltip" }}
                        arrow
                      >
                        <IconButton
                          sx={{ borderRadius: 0 }}
                          onClick={(e) => {
                            setEditSetAssignPopup(true);
                          }}
                        >
                          <img src="/Pencil.svg" alt="download" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {/* {selectedFiles.length > 0 && (
                    <div style={{ borderRight: "1px solid #EAEAEA" }}>
                      <Tooltip
                        title="Download Source File"
                        placement="top"
                        PopperProps={{ className: "version-action-tooltip" }}
                        arrow
                      >
                        <IconButton
                          sx={{ borderRadius: 0 }}
                          onClick={() => {
                            setReview(true);
                          }}
                        >
                          <img src="/download source.svg" alt="download" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )} */}
                  {/* {selectedFiles.length > 0 && (
                    <div style={{ borderRight: "1px solid #EAEAEA" }}>
                      <Tooltip
                        title="Submit for Review"
                        placement="top"
                        PopperProps={{ className: "version-action-tooltip" }}
                        arrow
                      >
                        <IconButton
                          sx={{ borderRadius: 0 }}
                          onClick={() => {
                            setReview(true);
                          }}
                        >
                          <img src="/submit for review.svg" alt="download" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )} */}
                  {selectedFiles.length > 1 && selectedFiles.length < 3 && (
                    <Tooltip
                      title="Compare"
                      placement="top"
                      PopperProps={{ className: "version-action-tooltip" }}
                      arrow
                    >
                      <IconButton
                        sx={{ borderRadius: 0 }}
                        onClick={() => {
                          setCompareFiles(true);
                        }}
                      >
                        <img src="/compare.svg" alt="download" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="right_side">
                <TextField
                  sx={{
                    "& .MuiInputBase-root": {
                      color: "#505050",
                      height: "37px",
                    },
                  }}
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  className="Searchbar-field"
                  placeholder="Search Sheet Number"
                  onClick={hideFilterDropDowns}
                  onChange={(e) => {
                    //convert input text to lower case
                    var lowerCase = e.target.value.toLowerCase();
                    if (!lowerCase || lowerCase === null || lowerCase === "") {
                      searchListViewFunction(lowerCase);
                    }
                    //setSearchText(lowerCase);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      // Do code here
                      e.preventDefault();
                      var lowerCase = e.target.value.toLowerCase();
                      //console.log('searchText ',searchText , 'lowerCase',lowerCase);
                      searchListViewFunction(lowerCase);
                    }
                  }}
                  inputRef={inputRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {searchBoxFilter ? (
                          <img
                            style={{ width: "25px", cursor: "pointer" }}
                            src="/cancel.svg"
                            alt="close icon"
                            onClick={() => {
                              if (inputRef.current) {
                                inputRef.current.value = "";
                              }
                              searchListViewFunction("");
                              setSearchText("");
                            }}
                          />
                        ) : (
                          <Search />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <div
                  className={
                    totalFilterApplied > 0
                      ? filterOptionsVisible
                        ? "grid-11"
                        : "grid-111"
                      : filterOptionsVisible
                      ? "grid-1"
                      : "grid"
                  }
                  onClick={() => {
                    showFilterOptions(!filterOptionsVisible);
                  }}
                >
                  <img className="filter2" src="/filter2 (1).svg" alt="filter2" />
                  <div className="label-11 poppins-normal-abbey-14px">
                    Filters{" "}
                    {totalFilterApplied > 0 && (
                      <>{" (" + totalFilterApplied + ")"}</>
                    )}
                  </div>
                  {!filterOptionsVisible && totalFilterApplied > 0 && (
                    <div className={filterOptionsVisible ? "grid-1" : "grid-2"}
                      onClick={onClickClearAll}
                    >
                      <div className="clear1">
                        <img className="clear" src="close.svg" alt="close" />
                      </div>
                    </div>
                  )}
                </div>

                <div className="switch_button" style={{marginBottom: "2px"}}>
                  <div
                    className="switch_icon"
                    style={{ background: !isGridView && "#E8EFFF" }}
                    onClick={() => setIsGridView(false)}
                  >
                    <img src="/list.svg" alt="nevLeft" />
                  </div>
                  <div
                    className="switch_icon"
                    style={{ background: isGridView && "#E8EFFF" }}
                    onClick={() => setIsGridView(true)}
                  >
                    <img src="/gridIcon.svg" alt="gridIcon" />
                  </div>
                </div>
              </div>
            </div>
            {(searchBoxFilter || contentSearchRunning) && (
              <div
                className="bottom_area"
                style={{
                  paddingBottom: "16px",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                {(searchBoxFilter) && (
                  <>
                    {" "}
                    {viewFolderFiles.length} Search Results for{" "}
                    <b>"{searchText}"</b>
                  </>
                )}
                {contentSearchRunning && " Pdf Search is Running..."}
              </div>
            )}
            <div className="bottom_area">
              {filterOptionsVisible && (
                <div className="filters">
                  {showShopDrawings && (
                    <DropdownFilter
                      credential={credential}
                      dropLabel="Shop Drawing Type"
                      options={shopDrawingTypeOptions}
                      activeFilters={typeActiveFilters}
                      isValueArray={true}
                      activeFilterDropdown={activeFilterDropdown}
                      setActiveFilterDropdown={setActiveFilterDropdown}
                      onFilterChange={async (newValues) => {
                        processTofilter_.current = true;
                        let payload = {projectId : credential?.projectId , data : newValues};
                        dispatch(setSheetTypeActiveFilters(payload));
                        setTypeActiveFilters(newValues);
                      }}
                    />
                  )}
                  {!showShopDrawings && (
                    <>
                      <DropdownFilter
                        credential={credential}
                        dropLabel="Set Name"
                        options={setOptions}
                        activeFilters={versionSetActiveFilters}
                        activeFilterDropdown={activeFilterDropdown}
                        setActiveFilterDropdown={setActiveFilterDropdown}
                        onFilterChange={async (newValues) => {
                          processTofilter_.current = true;
                          let payload = {projectId : credential?.projectId , data : newValues};
                          dispatch(setSheetVersionSetActiveFilters(payload));
                          setVersionSetActiveFilters(newValues);
                        }}
                      />
                      <DropdownFilter
                        credential={credential}
                        dropLabel="Discipline"
                        options={disciplineOptions}
                        activeFilterDropdown={activeFilterDropdown}
                        setActiveFilterDropdown={setActiveFilterDropdown}
                        activeFilters={disciplineActiveFilters}
                        onFilterChange={async (newValues) => {
                          processTofilter_.current = true;
                          let payload = {projectId : credential?.projectId , data : newValues};
                          dispatch(setSheetDisciplineActiveFilters(payload));
                          setDisciplineActiveFilters(newValues);
                        }}
                      />
                    </>
                  )}
                  <DropdownFilter
                    credential={credential}
                    dropLabel="Review Status"
                    options={reviewStageOptions}
                    isValueArray={true}
                    activeFilterDropdown={activeFilterDropdown}
                    setActiveFilterDropdown={setActiveFilterDropdown}
                    activeFilters={reviewStatusActiveFilters}
                    onFilterChange={async (newValues) => {
                      processTofilter_.current = true;
                      let payload = {projectId : credential?.projectId , data : newValues};
                      dispatch(setSheetReviewStatusActiveFilters(payload));
                      setReviewStatusActiveFilters(newValues);
                    }}
                  />
                  <DropdownFilter
                    credential={credential}
                    dropLabel="Updated By"
                    options={userList}
                    activeFilterDropdown={activeFilterDropdown}
                    setActiveFilterDropdown={setActiveFilterDropdown}
                    activeFilters={updatedByActiveFilters}
                    onFilterChange={async (newValues) => {
                      processTofilter_.current = true;
                      //console.log("updatedByActiveFilters ::", newValues);
                      let payload = {projectId : credential?.projectId , data : newValues};
                      dispatch(setSheetUpdatedByActiveFilters(payload));
                      setUpdatedByActiveFilters(newValues);
                    }}
                  />
                  {totalFilterApplied > 0 && (
                    <>
                      <div className="tertiary" onClick={onClickClearAll}>
                        <img className="reset" src="reset.svg" alt="reset" />
                        <div className="filters">Clear All</div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            {(activeTab === 0 || showShopDrawings === true) &&
              viewFolderFiles && (
                <>
                  {(folderFileLoading)&& (
                    <Box className="custom-alert custom-alert-info">
                      Folder Files is loading.
                    </Box>
                  )}
                  {compareFiles && (
                    <CompareFiles
                      callFrom={"SheetsComponent"}
                      open={true}
                      selectedFiles={selectedFiles}
                      setCompareFiles={setCompareFiles}
                      credential={credential}
                      viewFolderFiles={viewFolderFiles}
                    />
                  )}
                  {isGridView && (
                    <GridViewComponent
                      gridHeight={filterOptionsVisible ? 'calc(98vh - 230px)' : 'calc(98vh - 200px)'}
                      attachmentThumbnail={attachmentThumbnail}
                      callFrom={"SheetsComponent"}
                      viewTableData={viewFolderFiles}
                      onRowClickFunc={(parma) => {
                        onRowClickFunc(parma);
                      }}
                      onRowSelectionChange={onRowSelectionChange}
                      selectedIds={selected_ids}
                      handleClickMenu={handleClickMenu}
                      openMenu={openMenu}
                    />
                  )}
                  {!isGridView && (
                    <Box className="react_table_new" style={{ height: filterOptionsVisible ? 
                                                                        ((searchBoxFilter || contentSearchRunning) && folderFileLoading) ? "calc(90vh - 280px)" 
                                                                            : (searchBoxFilter|| contentSearchRunning) ? "calc(90vh - 205px)" 
                                                                              : folderFileLoading ? "calc(90vh - 235px)" : "calc(90vh - 180px)"
                                                                        :((searchBoxFilter|| contentSearchRunning) && folderFileLoading) ? "calc(90vh - 240px)" 
                                                                          : (searchBoxFilter|| contentSearchRunning) ? "calc(90vh - 175px)" 
                                                                            : folderFileLoading ?  "calc(90vh - 200px)" : "calc(90vh - 140px)"}} >
                      {showSpinner && <Spinner />}  
                      <ReactDataTable
                        tableId={activeTab === 0 ? 'contractSheetTable' : 'shopSheetTable'}
                        pagination={true}
                        initPageIndex={paginationPageIndex}
                        onPageChange={(pageNumber)=>{
                          setPaginationPageIndex(pageNumber)}
                        }
                        columns_react_table={ activeTab === 0 ? columns_react_table : Shop_Drawings }
                        rowss={viewFolderFiles}
                        handleClickMenu={handleClickMenu}
                        openMenu={openMenu}
                        setCurrentFileId={setCurrentFileId}
                        setRowsPerPage={setRowsPerPage}
                        selectedIds={selected_ids}
                        listCount={viewFolderFiles?.length}
                      />
                    </Box>
                  )}
                </>
              )}
            {pdfopenfiledata?.url && openpopup && (
              <ShowPdf
                viewFolderFiles={currentFolderFiles}
                credential={credential}
                pdfopenfiledata={pdfopenfiledata}
                updateTableState={updateStateTableDataWithRow}
                updateTableStateForLink={updateTableStateForLink}
                viewingIssue={viewingIssueId}
                handleClose={() => {
                  setOpen(false);
                  setViewingIssue(null);
                }}
                callFrom={"SheetsComponent"}
                navigation
                currentViewFiles={viewFolderFiles}
                thumbnailMap={attachmentThumbnail}
              />
            )}
            {pdfopenfiledata?.url && openVideoPopup && (
              <ShowVideoDialog
                filedata={pdfopenfiledata}
                open={openVideoPopup}
                handleClose={handleCloseVideo}
              />
            )}
            {deleteOpen && (
              <ConfirmDialog
                onCancel={() => {
                  setDeleteOpen(false);
                }}
                onAccept={() => {
                  onClickDeleteItems();
                }}
                DialogData={confirmDialogData}
              />
            )}
          </>
        )}
        {openMenu && (
          <>
            <Stack direction="row" spacing={2}>
              <Paper sx={{ height: 320, maxWidth: "100%" }}>
                <Menu
                  id="basic-menu"
                  open={openMenu}
                  onClose={handleCloseMenu}
                  autoFocus
                  anchorReference="anchorPosition"
                  anchorPosition={
                    anchorEl
                      ? { top: anchorEl.mouseY, left: anchorEl.mouseX }
                      : undefined
                  }
                  transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                  }}
                  MenuListProps={{ "aria-labelledby": "basic-button" }}
                  onContextMenu={(e)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    handleCloseMenu();
                  }}>
                  <div className="dropdown-list">
                    <div className="text-with-icon-1" onClick={(e) => {
                      handleOpenExportDialog("basic-menu");
                      setAnchorEl(null);
                    }}>
                      <img className="share" src="/download.svg" alt="export" />{" "}
                      <div className="label-1 poppins-normal-abbey-14px">
                        {" "}
                        Export
                      </div>
                    </div>
                    <div className="text-with-icon-1" onClick={()=> {
                      setShareItems([selectedMenuRow]);
                      setAnchorEl(null);
                    }}>
                      <img className="share" src="share.svg" alt="share" />{" "}
                      <div className="label-1 poppins-normal-abbey-14px">
                        {" "}
                        Share
                      </div>
                    </div>
                    <div className="text-with-icon-1"
                      onClick={(e) => {
                        setActionCalledFor("basic-menu");
                        setDeleteOpen(true);
                        setAnchorEl(null);
                      }}
                    >
                      <img className="share" src="/delete.svg" alt="export" />{" "}
                      <div className="label-1 poppins-normal-abbey-14px">
                        {" "}
                        Delete
                      </div>
                    </div>
                    <div
                      className="text-with-icon-1"
                      onClick={(e) => {
                        setEditSetAssignPopup(true);
                        setAnchorEl(null);
                      }}
                    >
                      <img className="pencil" src="/Pencil.svg" alt="Pencil" />{" "}
                      <div className="label-2 poppins-normal-abbey-14px">
                        {showShopDrawings ? "Edit Type" :"Edit set"}
                      </div>
                    </div>
                    {/* <div className="text-with-icon-1">
                      <img
                        className="download-source"
                        src="/download source.svg"
                        alt="download source"
                      />
                      <div className="label-3 poppins-normal-abbey-14px">
                        {" "}
                        Download Source File
                      </div>
                    </div> */}
                    {/* <div className="text-with-icon-1">
                      <img
                        className="submit-for-review"
                        src="submit for review.svg"
                        alt="submit for review"
                      />

                      <div className="submit-for-review-1 poppins-normal-abbey-14px">
                        Submit for Review
                      </div>
                    </div>{" "} */}
                  </div>
                </Menu>
              </Paper>
            </Stack>
          </>
        )}
        {activeExportDialog && (
          <ExportFileDialog
            onAccept={(_selectedFiles, exportWithMarkup) =>{
              if(instanceRef.current && _selectedFiles?.length > 0){
                let fname;
                if(_selectedFiles?.length > 1){
                  fname = `${sourceFolderDetail.name} sheets`;
                }else{
                  fname = `${_selectedFiles[0]?.File_Name__c}`;
                }
                let que_obj = {id : makeRandomId(3)+fname+'_'+makeRandomId(5) ,
                               name :fname , label  :fname , started : true , icon : pdfRed};
                setItemToBgProcess(que_obj);
                setShowBgProcess(true);
                downloadPdfDocument(_selectedFiles ,fname,  exportWithMarkup, instanceRef.current).then(failed=>{
                  let succ = !failed;
                  que_obj.processed =true;
                  que_obj.failed = failed;
                  que_obj.completed = !que_obj.failed;
                  setItemToBgProcess(que_obj);
                }).catch(qe=>{
                  que_obj.processed =true;
                  que_obj.failed = true;
                  que_obj.completed = !que_obj.failed;
                  setItemToBgProcess(que_obj);
                });
              }
              handleCloseExportDialog();
            }}
            setLoading={setSpinner}
            open={activeExportDialog}
            handleClose={handleCloseExportDialog}
            selectedFiles={selectedMenuRow ? [selectedMenuRow] : selectedFiles}
            callFrom='SheetsComponent'
          />
        )}
        {showBgProcess && (
          <ItemInQueueComponent
            heading='Exporting'
            queueItems={bgProcessItems}
            onClose={_handleCloseProcess}
          />
        )}
        {editSetAssignPopup && (
          <AssignmentDialog
            option={showShopDrawings ? shopDrawingTypeOptions : setOptions}
            credential={credential}
            setSpinner={setSpinner}
            setActiveTab={onActiveTabChange}
            setEditSetAssignPopup={(refresh) => {
              if (refresh) {
                refreshFolderFiles(sourceFolderDetail?.id);
              }
              setEditSetAssignPopup(false);
              setSelectedMenuRow(null);
            }}
            selectedItems={selectedItems}
            attachRow={selectedMenuRow}
            showShopDrawings={showShopDrawings}
            editSetAssignPopup={editSetAssignPopup}
            handleClose={handleCloseMenu}
            updateTableState={updateStateTableDataWithRow}
            selected_ids={selected_ids}
          />
        )}
        {movePopup && (
          <MoveDialog
            projectId={credential?.projectId}
            movePopup={movePopup}
            handleClose={closeMove}
            handleSave={onClickMoveItems}
            currentFolderName={sourceFolderDetail}
          />
        )}
        {/* {shareOpen && (
          <ShareDialog
            open={shareOpen}
            handleClose={handleShareClose}
            currentFileId={currentFileId}
          />
        )}
        {shareFiles && (
          <ShareFiles
            open={shareFiles}
            handleClose={handleShareClose}
            currentFileId={selectedMenuRow}
          />
        )} */}
        {toShareItems?.length > 0 && (
          <ShareableLinkComponent
            setToastMessage={setToastMessage}
            shareListItems={toShareItems}
            onCancel={(links) => {
              updateTableStateForLink(links);
              setShareItems([]);
            }}
          />
        )}
        {toastMessage &&
          <ToastComponent message={toastMessage} handleClose={() => { setToastMessage(null) }} />
        }
      </div>
    </Design>
  );
};
export const highlightText = (text, highlight) => {
    if (!highlight || !text) {
        return text;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    return text.replace(regex, (match) => `<mark>${match}</mark>`);
};
export const prepareAmazonRecordRow = async (file, cred, path) => {
  //console.log("file ::", file?.Document_Reviews__r);
  let jsonMarkup = file?.Markup_Json__c;
  let markupJs = await getXMLJSFromMakrupXML(jsonMarkup, cred);
  let markupWrapper = getWrapperFromAnnotaitionXMLJSON(markupJs, cred);
  let inUrl = getInternalRecordUrl(cred , path , file?.Id);
  const MS_DOC_FORMAT = ['doc', 'docx'];
  const MS_EXCEL_FORMAT = ['xls','xlsx','ppt','pptx','pub'];
  return {
    id: file?.Id,
    inappurl : inUrl,
    title: file.Document_Title__c ? file.Document_Title__c : file.File_Name__c,
    name: file.Document_Number__c ? file.Document_Number__c : file.File_Name__c,
    tooltip: file.Document_Title__c ? file.Document_Title__c : file.File_Name__c,
    set: file.Set__c ? file.Set__r.Name : "-",
    setId: file.Set__c,
    version: file?.Document_File_Versions__r?.records?.length /*Record_Version__c */,
    currentVersion:file.Version_Number__c,
    lastUpdated: moment(file.LastModifiedDate).format("DD MMM YYYY HH:mm"),
    updatedBy: file.LastModifiedBy?.Name,
    updatedById: file.LastModifiedById,
    createdBy: file.CreatedBy?.Name,
    createdById: file.CreatedById,
    markupJSON: jsonMarkup,
    markupCount: markupWrapper.users_markup_counts,
    thumbnail: file.thumbnail,
    location: "",
    shopDrawing: file?.Shop_Drawing_Type__c,
    discipline: file.Discipline__c ? `${file.Discipline__r.Designator__c} (${file.Discipline__r.Name})` : noDisciplineLabel,
    disciplineId: file.Discipline__c,
    // reviewStatus: file?.Document_Reviews__r?.records[0].Review__r.Stage__c ? file?.Document_Reviews__r?.records[0].Review__r.Stage__c : "-",
    type: "file",
    relatedToId:file?.Related_To_ID__c,
    folderId: file?.Folder__c,
    filePath: file?.Folder__r?.Folder_Path__c,
    amazonPath: file?.Folder__r?.S3_Folder_Path__c,
    s3versionId: file?.S3_Version_ID__c,
    attObj: file,
    url: file.url,
    fileExt: file?.File_Extension__c?.toLowerCase(),
    iconPath: MS_DOC_FORMAT.includes(file?.File_Extension__c?.toLowerCase()) ? "/microsoftWord.svg": MS_EXCEL_FORMAT.includes(file?.File_Extension__c?.toLowerCase()) ? "/excel.svg": "/pdfRed.svg",
    ...file,
  };
};
export const prepareFolderRecordRow = async (folder) => {
  //console.log("file ::", file?.Document_Reviews__r);
  return {
    id: folder?.Id,
    title: folder?.Name,
    name:folder?.Name,
    tooltip: folder?.Name,
    updatedById: folder.LastModifiedById,
    set: '-',
    version: "-",
    lastUpdated:moment(folder.LastModifiedDate).format("DD MMM YYYY HH:mm") ,
    updatedBy: folder?.LastModifiedBy?.Name,
    markupJSON: "-",
    issue: "-",
    location: "-",
    reviewStatus: "-",
    type:'folder',
    rec : JSON.stringify(folder),
    ...folder
  };
};
export const getPdfThumbnailFromWebViewer = async (attachId, fileUrl, webviewerIns) => {
  var returnValue = { attachId: attachId };
  //console.log('process start',attachId);
  //console.time('attachIdWebViewer'+attachId);
  try {
    const { PDFNet } = webviewerIns.Core;
    await PDFNet.initialize();
    await PDFNet.deallocateAllObjects();
    async function main() {
      const docAsPDF = await PDFNet.PDFDoc.createFromURL(fileUrl);
      const pdfdraw = await PDFNet.PDFDraw.create(25);
      const currPage = await docAsPDF.getPage(1);
      const pngBuffer = await pdfdraw.exportStream(currPage, 'PNG');
      const image = URL.createObjectURL(new Blob([pngBuffer.buffer], { type: 'image/png' }));
      //console.log(attachId,'apryse image ::',image)
      //console.timeEnd('attachIdWebViewer'+attachId);
      returnValue.thumbnail = image;
      if(false){
        await convertImageToBase64(image).then(async thumb64=>{
          if(thumb64){
            const thumb = await convertImageBase64ToBlob(thumb64);
            const formData = new FormData();
            formData.append("thumbFile", thumb , `thumb_${attachId}.png`);
            await axios.post(`${fetchUrl}/save_attachment_thumbnail/${attachId}`, formData).then(res=>{
              if(res.status === 200){
                console.log('res ::',JSON.stringify(res.data));
                returnValue.thumbnail = res.data.url;
              }
            });
          }
        }).catch(e=>{
          console.log('img thumb 64',e);
        });
      }
      return returnValue;
    }
    // Automatically locks all worker operations
    //return PDFNet.runWithCleanup(main);
    return await main();
  } catch (e) {
    console.log('pdffails :', e);
    return returnValue;
  }
};
export const searchTextInPdfContentWebViewer = async (attachId, searchText, fileUrl, webviewerIns) => {
  var returnValue = { attachId: attachId };
  try {
    const { PDFNet } = webviewerIns.Core;
    await PDFNet.initialize();
    await PDFNet.deallocateAllObjects();
    async function main() {
      try {
        const docAsPDF = await PDFNet.PDFDoc.createFromURL(fileUrl);
        docAsPDF.initSecurityHandler();
        docAsPDF.lock();
  
        const txtSearch = await PDFNet.TextSearch.create();
        let searchMode = PDFNet.TextSearch.Mode;
        let mode = PDFNet.TextSearch.Mode.e_reg_expression | PDFNet.TextSearch.Mode.e_highlight;
        // 'pattern' can be a regular express when using 'e_reg_expression' mode
        txtSearch.begin(docAsPDF, searchText, mode);
        let result = await txtSearch.run();
        while (true) {
          if (result.code === PDFNet.TextSearch.ResultCode.e_found) {
            returnValue.found = true;
            break;
          } else if (result.code === PDFNet.TextSearch.ResultCode.e_page) {
            //console.log(`Finish searching page ${result.page_num}`);
          } else if (result.code === PDFNet.TextSearch.ResultCode.e_done) {
            //console.log(`Finish searching the document`);
            break;
          }
          result = await txtSearch.run();
        }
        return returnValue;
      }catch (ee){
        console.log('pdffails :', ee);
        return returnValue;
      }
    }
    // Automatically locks all worker operations
    //return PDFNet.runWithCleanup(main);
    return await main();
  } catch (e) {
    console.log('pdffails :', e);
    return returnValue;
  }
};
export const downloadPdfDocument = async (_selectedFiles, fileName, exportWithMarkup, webviewerIns , returnAsBlob = false) => {
  const { PDFNet } = webviewerIns.Core;
  let failed = true;
  try {
    await PDFNet.initialize();
    async function main() {
      const mergedDoc = await PDFNet.PDFDoc.create();
      await mergedDoc.initSecurityHandler();
      for (let i = 0; i < _selectedFiles.length; i++) {
        const url = _selectedFiles[i].url;
        const doc = await PDFNet.PDFDoc.createFromURL(url);
        await doc.initSecurityHandler();
        const pageCount = await doc.getPageCount();

        if (exportWithMarkup && _selectedFiles[i].markupJSON && _selectedFiles[i].markupJSON.trim() !== '') {
          // Apply the annotations from markupJson
          const fdfDoc = await PDFNet.FDFDoc.createFromXFDF(_selectedFiles[i].markupJSON);
          await doc.fdfMerge(fdfDoc);
          await doc.flattenAnnotations();
        }
        // Append the current document to the merged document
        await mergedDoc.insertPages( i + 1, doc, 1, pageCount, PDFNet.PDFDoc.InsertFlag.e_none );
        //await doc.close();
      }
      const pdfBuffer = await mergedDoc.saveMemoryBuffer(PDFNet.SDFDoc.SaveOptions.e_linearized);
      const blob = new Blob([pdfBuffer], { type: 'application/pdf' });
      if(returnAsBlob){
        return blob;
      }
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      if (!fileName.toLowerCase().endsWith(".pdf")) {
        fileName = (fileName + '.pdf');
      }
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      //await mergedDoc.close();
      failed = false;
      return failed;
    }
    return await PDFNet.runWithCleanup(main);
  } catch (error) {
    console.error('Error downloading and merging documents:', error);
    failed = true;
  }
  return failed;
};
export const getLowerqualityThumbnailBase64 = async (PDFNet, docAsPDF, thumurl, pageNum , maxSizeInKB = 100) => {
  try {
    if (!thumurl) {
      await PDFNet.initialize();
      async function main() {
        const pdfdraw = await PDFNet.PDFDraw.create(40);
        const currPage = await docAsPDF.getPage(pageNum);
        const pngBuffer = await pdfdraw.exportStream(currPage, 'PNG');
        const image = URL.createObjectURL(new Blob([pngBuffer.buffer], { type: 'image/png' }));
        //console.log('image :: ', image);
        return image;
      }
      thumurl = await PDFNet.runWithCleanup(main);
      //console.log('thumurl :: ', thumurl);
    }
    if (thumurl) {
      const base64img = await convertImageToBase64(thumurl, maxSizeInKB);
      //console.log('base64img :: ', base64img);
      return base64img;
    }
  } catch (err) {
    console.error('Error converting image:', err);
  }
  return null;
};
export const getThumbnailUrl = async (recIds)=>{
  var req_body = { recIds: recIds };
  let ret = await axios.post(`${fetchUrl}/get_attachment_thumbnail`, req_body).catch((err) => {
  });
  const thumbnailMap = ret?.data?.thumbnailMap;
  return thumbnailMap; 
};
export const convertImageToBase64 = async (url, maxSizeInKB = 100) => {
  //console.log('size compress',0);
  return new Promise((resolve, reject) => {
    //console.log('size compress',0);
    const img = new Image();
    //console.log('size compress',0);
    img.crossOrigin = 'Anonymous'; // This is needed if you're loading an image from a different origin
    img.onload = () => {
      //console.log('size compress',1);
      let canvas = document.createElement('canvas');
      //console.log('size compress',2);
      let ctx = canvas.getContext('2d');
      //console.log('size compress',3);
      let width = img.width;
      let height = img.height;
      //console.log('size compress',4);
      canvas.width = width;
      canvas.height = height;
      //console.log('width ::',width,'height ::',height);
      ctx.drawImage(img, 0, 0, width, height);
      let base64String = canvas.toDataURL('image/png');
      //console.log('size compress',5);
      let maxSize = (maxSizeInKB * 1024);
      //console.log('size compress',6);
      let quality = 0.80;
      while (base64String.length > maxSize && quality > 0.20) {
        //console.log('size compress loop', base64String?.length);
        // Reduce dimensions by 5% each iteration
        width *= quality;
        height *= quality;
        canvas.width = width;
        canvas.height = height;
        //console.log('after width ::',width,'after height ::',height);
        ctx.drawImage(img, 0, 0, width, height);
        base64String = canvas.toDataURL('image/png');
        quality -= 0.05; // Reduce quality by 5%
      }
      if (base64String.length > maxSize){
        base64String = null;
      }
      //console.log('size compress final');
      resolve(base64String);
    };
    img.onerror = (err) => {
      reject(err);
    };
    img.src = url;
  });
};
export const convertImageBase64ToBlob = async (base64String) => {
  //console.log('size compress',0);
  return new Promise((resolve, reject) => {
    fetch(base64String).then(res => res.blob()).then(blob => resolve(blob))
      .catch(err => {
        reject(null)
      });
  });
};