import React, { useEffect, useRef, useState } from "react";
import WebViewer from '@pdftron/webviewer';
import { DataGrid } from "@mui/x-data-grid";
import { Tabs, Tab, InputAdornment } from "@mui/material";
import Styled from "styled-components";
import { Paper, InputBase, IconButton, Button, TextField, Menu, 
        Stack, Tooltip, Box, Checkbox, CircularProgress } from "@mui/material";
import { CheckCircleOutline, Folder, Search, FileCopy, MoreHoriz } from "@mui/icons-material";
import GridViewIcon from "@mui/icons-material/GridView";
import { GiHamburgerMenu } from "react-icons/gi";
import PdfViewerComponent from "../PdfViewerComponent";
import DialogComponent from "../Shared/DialogComponent";
import DialogStepper from "./DialogStepper";
import ProgressBar from "components/Shared/ProgressBar";
import useFolderTableHook from "hooks/FolderTable.hook";
import { ImagesCss, WithoutTemplateSection } from "./folderTable.style";
import axios from "axios";
import { fetchUrl } from "Urls";
import Spinner from "components/spinner";
import Loader from "components/Loader";
import _, { cloneDeep } from "lodash";
import moment from "moment";
import ShowPdf from "components/SetsView/ShowPdf";
import ShowVideoDialog from "components/SetsView/ShowVideoDialog";
import VersionsDialog from "./VersionsDialog";
import RecycleBin from "./RecycleBin";
import { ExportFileDialog } from "./ExportFile";
import { toast } from "react-toastify";
import UploadLogs from "components/upload-logs/UploadLogs";
import DropdownFilter from "components/DropdownFilter";
import GridViewComponent from "./GridViewComponent";
import FileUploadProgress from "components/MultifileProcessing/multiFileProcessing";
import { ConfirmDialog } from "components/DrawerComponent/DisciplineSettingDialog";
import AssignmentDialog from "./AssignmentDialog";
import ShareFiles from "./ShareFiles";
import MoveDialog from "./MoveDialog";
import ShareDialog from "../../../src/components/FolderSection/ShareDialog";
import ReactDataTable from "components/DatableComponent/react_tatble";
import ShopDrawings from "components/DatableComponent/shopDrawings";
import { _sortItems } from "constant/Methods";
import { LoadingButton } from "@mui/lab";
import ReviewModel from "./ReviewModel";
import { useNavigate ,useLocation } from "react-router-dom";
import CompareFiles from './CompareFiles';
import { APRYSE_L_KEY } from "api";
import { getXMLJSFromMakrupXML , getWrapperFromAnnotaitionXMLJSON } from "components/SetsView/ShowPdf";
import { getPdfThumbnailFromWebViewer } from "components/SheetsComponent";
//import sampleDoc from "components/../../public/samplePDF.pdf";

export function SearchComponent(props) {
  return (
    <Paper
      component="div"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 250 }}
    >
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <Search />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
      />
    </Paper>
  );
}
const DataTableDesign = Styled.div`
h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
}
[role="tablist"] {
    gap: 24px;
      button {
      padding: 10px 0;
      font-family: var(--common-font); 
      font-size: 14px;
      color: var(--drgray);
      text-transform: capitalize;
  }
   
.Mui-selected{
  color: var(--drblue);font-weight: 500;
} 
}

.left_side{
   h2 {
    color: var(--drgray);
    font-size: 14px;
    line-height: 21px;
}
}
.tab_wrapper .MuiTabs-scroller{
span.MuiTabs-indicator {
        background: var(--drblue);
        height: 1px;
    }
} 
.publish-logs {
  align-items: flex-start; 
  display: inline-flex; 
  flex-direction:
  column; 
  gap: 8px;
  position: relative;
  }
  .text-and-kpi {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto; 
  gap: 6px;
  justify-content: center; 
  position: relative;
  }
  .label {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px; 
  position: relative; 
  width: fit-content;
  }
  .kpi {
  align-items: center;
  background-color: #F92828; 
  border-radius: 16px;
  display: inline-flex;
  flex: 0 auto;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  padding: 3px 6px; position: relative;
  }
  .x1 {
  letter-spacing: 0.1px;
  line-height: 10px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap; 
  width: fit-content;
  color: #ffff
  }
.table_head.main_table {
    padding: 10px 16px;
}
.buttons {
  align-items: center; border-radius: 2px; display: flex;
  height: 24px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 24px;
  }
  .close {
    &:hover {
      background: #F8F8F8;
    }
  height: 16px;
  position: relative;
  width: 16px;
  }
  .clear {
    &:hover {
      background: #F8F8F8;
    }
  height: 16px;
  position: relative;
  width: 16px;
  margin-left: 10px
  }
.wrapper_main {
    padding-top: 20px;
}
.left_side {
  //  border-right: 1px solid #EAEAEA;
    padding: 5px 0;
}
.box_hodler {
    max-width: 180px;
}
.right_side{
  
.FilterButton{
color: #505050;
text-transform: capitalize;
font-family: var(--common-font);
font-weight: 400;
font-size: 14px;
&:hover {
    background: #E8EFFF;
    svg{
      fill: #505050;
    }
   }
}
}
.filters {
  display: flex;
  gap:12px;
}
  button.mainButton {
    &:hover {
      background: #F8F8F8;
    }
    cursor: pointer;
    padding: 8px 12px; 
    background-color: #ffff;
    color: #505050;
    border: 1px solid #D8D8D8;
    border-radius: 2px;
    img {
    margin-left: 10px;
}}
button.mainButton-1 {
  &:hover {
    background: #F8F8F8;
  }
  cursor: pointer;
  padding: 8px 12px; 
  background-color: #E8EFFF;
  color: #505050;
  border: 1px solid #D8D8D8;
  border-radius: 2px;
  img {
  margin-left: 10px;
}}
.holder_input {
    position: relative;
    z-index: 1;
}
 
.filter-dropdown {
    top: 40px;
    left: 0px;
    width: 264px;
    border: none;
    border-radius: 2px; 
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    position: absolute;
  }
  .filter-search {
    border: 1px solid #d3d3d3;
    padding: 7px 12px;
    border-radius: 2px;
    margin: 10px 10px 0;
      input {
      border: none;
      padding: 0;
      width: 100%;
      outline: none;
      font-size: 14px;
  } 
  }
  .filter-footer {
    border-top: 1px solid #EAEAEA; gap: 10px;  display: flex;
    justify-content: flex-end; padding: 10px;
}   
  
 
.filter-options {
  height: 150px;
  overflow: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 10px;
  label {
    height: 32px;
    gap:7px;
    padding: 0;
    margin: 0;
    span{
      font-size: 14px; 
      font-family: var(--commonfont);
      color: #505050;
      padding: 0
    } 
    .MuiTouchRipple-root{
      margin-left: 10px
    } 
    }
 }
 
}
.bottom_area {
    padding: 0px 16px;
}
span.MuiButtonBase-root  svg.MuiSvgIcon-root {
    color: var(--border-gray);
}
span.MuiButtonBase-root.Mui-checked svg.MuiSvgIcon-root {
    color: var(--drblue);
}
.filter-checkbox {
  &:hover {
    background: #F8F8F8;
  }
}
.grid-1 {
  &:hover {
    background: #F8F8F8;
  }
  width: 115px;
  align-items: center;
  background-color: #E8EFFF;
  border: 1px solid;
  border-color: #D8D8D8; 
  border-radius: 2px;
  display: inline-flex;
  gap: 8px;
  height: 37px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
  }
.grid {
  &:hover {
    background: #F8F8F8;
  }
  width: 115px;
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid;
  border-color: #D8D8D8; 
  border-radius: 2px;
  display: inline-flex;
  gap: 8px;
  height: 37px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
  }
  .grid-2 {
    &:hover {
      background: #F8F8F8;
    }
    width: 20px;
    align-items: center;
   // background-color: #FFFFFF;
    border-left: 1px solid;
    border-color: #D8D8D8; 
    border-radius: 2px;
   // display: inline-flex;
    height: 37px;
    justify-content: center;
   // overflow: hidden;
    padding: 8px 1px;
   // position: relative;
    }
  .grid-11 {
    &:hover {
      background: #F8F8F8;
    }
    width: 170px;
    align-items: center;
    background-color: #E8EFFF;
    border: 1px solid;
    border-color: #D8D8D8; 
    border-radius: 2px;
    display: inline-flex;
    gap: 8px;
    height: 37px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 12px;
    position: relative;
    }
  .grid-111 {
    &:hover {
      background: #F8F8F8;
    }
    width: 240px;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid;
    border-color: #D8D8D8; 
    border-radius: 2px;
    display: inline-flex;
    gap: 8px;
    height: 37px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 12px;
    position: relative;
    }
  .filter2 {
  height: 16px;
  position: relative;
  width: 16px;
  }
  .label-11 {
  cursor: pointer;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px; 
  position: relative; 
  width: fit-content;
  color: #505050;
  }
  .tertiary {
    &:hover {
      background: #F8F8F8;
    }
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid;
    border-color: #D8D8D8; 
    border-radius: 2px;
    display: inline-flex; gap: 8px;
    height: 37px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 12px;
    position: relative;
    }
    .reset {
    height: 16px;
    position: relative;
    width: 16px;
    }
    .filters {
    color: #505050;
    font-family: var(--font-family-poppins); 
    font-size: var(--font-size-m);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px; 
    position: relative; 
    width: fit-content;
    }
`;
export default function DataTable({ ...props }) {
  //const {allData , data } = props;
  //console.log("DataTable props?.projectId", props?.projectId);
  const {
    pdfImages,
    pdfFile,
    activeStep,
    open,
    progress,
    selectedRowData,
    selectedSplitData,
    selectedTemplate,
    containerRef,
    credential,
    newTemplateName,
    templateName,
    templateNumber,
    templateCheck,
    selectedradio,
    duplicateAvailable,
    preSelectedPdfImages,
    handleNext,
    setPreSelectedPdfImages,
    _handleUploadFile,
    _handleClose,
    setSelectedRowData,
    _handleSelect,
    setSelectedSplitData,
    columnsB,
    saveSplit,
    _selectTitleAndNumber,
    setSelectedTemplate,
    setNewTemplateName,
    saveNewTemplate,
    setIsLoading,
    isloading,
    popupLoader,
    setSelectedRadio,
    setUploadWithoutSet,
    isSelectionTitleLoading,
    isSelectionNumberLoading,
    buttonloading,
    readyForReviewCount,
    getUnPublishBinderCount,
    handleMinimize,
    fileUrl,
    miniMizeLoading,
    workflowTemplateOptions,
    sourceFolderDetail,
    setSourceFolder,
    folderNameToIdMap, setSubFolderMap,
    Contract_Folder_Name,
    Shop_Folder_Name,
    shopDrawingTypeOptions,
    disciplineOptions,
    setOptions,
    reviewStageOptions
  } = useFolderTableHook(props);

  const [tempProId, setTempProjId] = useState();
  const [searchText, setSearchText] = useState("");
  const activeTabRef = useRef();
  const [activeTab, setActiveTab] = useState(0);
  const [folderFilesForSearch, setFolderFilesForSearch] = useState();
  var currentFolderFileData = useRef([]); // used for js calculation bcz of state not updating
  const [currentFolderFiles, setFolderFiles] = useState([]); // used for js calculation bcz of view
  const [folderFileLoading, setFolderFileLoading] = useState(false); // used for js calculation bcz of view
  const [pdfopenfiledata, setFileData] = useState();
  const [pdfopenfiledat, setFileDat] = useState();
  const [viewFolderFiles, setViewFolderFiles] = useState([]);
  const [showSpinner, setSpinner] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [browserWarningOpen, setBrowserWarningOpen] = useState(false);
  const [showlogs, setShowLogs] = useState(false);
  const [showRecyclebin, setShowRecyclebin] = useState(false);
  const [showShopDrawings, setShowShopDrawings] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isGridView, setIsGridView] = useState(false);
  const [currentFileId, setCurrentFileId] = useState();
  const openMenu = Boolean(anchorEl);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedMenuRow, setSelectedMenuRow] = useState();
  const [loading, setLoading] = useState(false);
  const [actionCalledFor, setActionCalledFor] = useState();
  const [editSetAssignPopup, setEditSetAssignPopup] = useState(false);
  const [movePopup, setmovePopup] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [shareFiles, setShareFiles] = useState(false);
  const [review, setReview] = useState(false);
  const [compareFiles, setCompareFiles] = useState(false);
  const [showEditInput, setShowEditInput] = useState(false);
  const [isbuttonclicked, isButtonClicked] = useState(false);
  const [fileDataRow, setFileDataRow] = useState();
  const [fileName, setfileName] = useState("");

  /*filter variable start*/

  const [filterOptionsVisible, showFilterOptions] = useState(false);
  const [totalFilterApplied, setTotalFilterApplied] = useState(false);

  const [activeFilterDropdown, setActiveFilterDropdown] = useState(false);
  const [versionSetActiveFilters, setVersionSetActiveFilters] = useState([]);
  const [typeActiveFilters, setTypeActiveFilters] = useState([]);
  const [disciplineActiveFilters, setDisciplineActiveFilters] = useState([]);
  const [reviewStatusActiveFilters, setReviewStatusActiveFilters] = useState([]);
  const [updatedByActiveFilters, setUpdatedByActiveFilters] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState([]);
  const [attachmentThumbnail, setAttachmentThumbnail] = useState(null);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const filteredTableDataRow = useRef([]);
  const processTofilter_ = useRef(null);
  const confirmDialogData = {
    title: 'Delete Sheet/s',
    content: 'Are you sure you want to delete selected sheet/s? Deleted sheets can be recovered in the deleted sheets tab.',
    action1: "Cancel",
    action2: "Delete"
  };
  const [webInstance, setInstance] = useState(null);
  const instanceRef = useRef(null);
  const pdfContainerRef = useRef(null);
  let stopBreakThumbmaking = useRef(null);
  function setInstance_(values){
    instanceRef.current = values;
    setInstance(values);
  }
  function setFilteredTableData_(values){
    filteredTableDataRow.current = values;
    setFilteredTableData(values);
  }
  /*filter variable end*/

  /*const { _getFolderApi, setFolderTreeOnView, resTreeData } =
    props?.sectionHook;*/
  const navigate = useNavigate();
  const location = useLocation();
  const [bState,setBrowserState] = useState(location?.state);
  //console.log("pdfopenfiledata", pdfopenfiledata);

  useEffect(() => {
    processTofilter_.current = false;
    if(activeTabRef.current == null || activeTabRef.current == undefined){
      activeTabRef.current = 0;
    }
    async function fetchData() {
      if(props?.projectId !== tempProId){
        setTempProjId(props?.projectId);
        setAttachmentThumbnail(null);
      }
      /*console.log("DataTable Use Effect props?.projectId",props?.projectId,props?.isPlan);*/
      if(bState && bState?.activeTabId != null){
        onActiveTabChange(bState?.activeTabId);
        window.history.replaceState({}, document.title);
      }else{
        getSourceFolder();
      }
    }
    fetchData();
  }, [props?.isPlan, props?.projectId]);
  useEffect(() => {
    let cc = 0;
    if(typeActiveFilters.length > 0){
      cc += typeActiveFilters.length;
    }
    if(reviewStatusActiveFilters.length > 0){
      cc += reviewStatusActiveFilters.length;
    }
    if(disciplineActiveFilters.length > 0){
      cc += disciplineActiveFilters.length;
    }
    if(updatedByActiveFilters.length > 0){
      cc += updatedByActiveFilters.length;
    }
    if(versionSetActiveFilters.length > 0){
      cc += versionSetActiveFilters.length;
    }
    setTotalFilterApplied(cc);
    if(processTofilter_.current){
      onHandleFilterData(cc);
    }
    processTofilter_.current = false;

  }, [versionSetActiveFilters, typeActiveFilters,disciplineActiveFilters,reviewStatusActiveFilters,updatedByActiveFilters]);

  async function loadSampleDocument(){
    console.log('pdfContainerRef.current :: ',pdfContainerRef.current);
    if(pdfContainerRef.current){
      await WebViewer({
        fullAPI: true,
        path: '/webviewer/public',
        licenseKey: APRYSE_L_KEY,  // sign up to get a free trial key at https://dev.apryse.com,
        disableObjectURLBlobs: true,
        backendType: WebViewer.BackendTypes.ASM,
      }, pdfContainerRef.current).then(async (instance) => {
        setInstance_(instance);
        console.log('instance :: ',instance);
      });
    }
  }
  const generatePdfThumbnailMapPromise = async (attachmentRows) =>{
    const BATCH_SIZE = 10;
    let promises = [];
    if (attachmentRows?.length > 0) {
      stopBreakThumbmaking.current = false;
      for (let i = 0; i < attachmentRows.length; i++) {
        if (stopBreakThumbmaking.current) break;
  
        const file = attachmentRows[i];
        if (file.url && (!attachmentThumbnail || !attachmentThumbnail[file.id])) {
          promises.push(genratePdfThumnail(file.id, file.url));
        }
  
        if (promises.length === BATCH_SIZE || i === attachmentRows.length - 1) {
          if(promises?.length > 0){
            //console.time(i,'promise starts :: ',promises?.length);
            await Promise.all(promises).then((values) => {
              //console.timeEnd(i,'promise starts :: ',values?.length);
              if(values?.length > 0 ){
                let temList_folFiles = JSON.parse(JSON.stringify(currentFolderFileData.current)); let curfolFilePush = false;
                let temList_viewFiles = JSON.parse(JSON.stringify(viewFolderFiles)); let viewFilePush = false;
                let temList_filFiles = JSON.parse(JSON.stringify(filteredTableData)); let curFilFilePush = false;
                for(let pdfth of values){
                  if(pdfth !=null && pdfth.attachId && pdfth.thumbnail){
                    let atchId = pdfth.attachId;
                    let thumbnail = pdfth.thumbnail;
                    if(temList_folFiles?.length > 0){
                      const fInd = temList_folFiles?.findIndex(
                        (data) => data.id == atchId
                      );
                      if (fInd > -1) {
                        curfolFilePush =true;
                        temList_folFiles[fInd].thumbnail = thumbnail;
                      }
                    }
                    if(temList_viewFiles?.length > 0){
                      const fInd = temList_viewFiles?.findIndex(
                        (data) => data.id == atchId
                      );
                      if (fInd > -1) {
                        viewFilePush =true;
                        temList_viewFiles[fInd].thumbnail = thumbnail;
                      }
                    }
                    if(temList_filFiles?.length > 0){
                      const fInd = temList_filFiles?.findIndex(
                        (data) => data.id == atchId
                      );
                      if (fInd > -1) {
                        curFilFilePush =true;
                        temList_filFiles[fInd].thumbnail = thumbnail;
                      }
                    }
                  }
                }
                if(curfolFilePush){
                  setCurrentFolderFiles(temList_folFiles);
                  setFolderFilesForSearch(temList_folFiles);
                }
                if(viewFilePush){
                  setViewFolderFiles(temList_viewFiles);
                }
                if(curFilFilePush){
                  setFilteredTableData(temList_filFiles);
                }
              }
            }).catch(err=>{
              console.log('promise all',err);
            });
          }
          promises = [];
        }
      }
    } else {
      stopBreakThumbmaking.current = true;
    }
  }
  const genratePdfThumnail = async (attachId , fileUrl , updateList) =>{
    let pdfThumbnail =await getPdfThumbnailFromWebViewer(attachId , fileUrl , instanceRef.current);
    if(pdfThumbnail && pdfThumbnail.attachId && pdfThumbnail.thumbnail){
      let imgthumEleId = pdfThumbnail.attachId+'_thumb_img' ;
      setAttachmentThumbnail((preMap)=>{
        if(!preMap){
          preMap = {} ;
        }
        preMap[pdfThumbnail.attachId] = pdfThumbnail.thumbnail;
        var imgEle = document.getElementById(imgthumEleId);
        if(imgEle){
          imgEle.src = pdfThumbnail.thumbnail ;
        }
        //console.log('gen sse :: ',pdfThumbnail.thumbnail);
        return preMap;
      });
      if(updateList === true){
        updateThumbnailToCurrentData(pdfThumbnail.attachId , pdfThumbnail.thumbnail);
      }
      return pdfThumbnail;
    }
    return null;
  }
  async function onHandleFilterData(_totalFilterApplied=totalFilterApplied) {
    let filteredFoundList = [];
    let orgData = cloneDeep(folderFilesForSearch);
    if(orgData?.length > 0 && _totalFilterApplied > 0){
      for(const currRow of orgData){
        let matched= true;
        if(typeActiveFilters?.length >0){
          matched= false;
          if(currRow.shopDrawing){
            matched = typeActiveFilters.includes(currRow.shopDrawing);
          }
        }
        if(versionSetActiveFilters?.length >0 && matched == true ){
          matched= false;
          if(currRow.setId){
            const valueFound = versionSetActiveFilters.find(usss => usss.value == currRow.setId)
            if(valueFound){
              matched = true;
            }
          }
        }
        if(disciplineActiveFilters?.length >0 && matched == true ){
          matched= false;
          if(currRow.disciplineId){
            const valueFound = disciplineActiveFilters.find(usss => usss.value == currRow.disciplineId);
            if(valueFound){
              matched = true;
            }
          }
        }
        if(reviewStatusActiveFilters?.length >0 && matched == true ){
          matched= false;
          if(currRow.reviewStatus){
            matched = reviewStatusActiveFilters.includes(currRow.reviewStatus);
          }
        }
        if(updatedByActiveFilters?.length >0 && matched == true ){
          matched= false;
          const valueFound = updatedByActiveFilters.find(usss => usss.value == currRow.updatedById);
          if(valueFound){
            matched = true;
          }
        }
        if(matched){
          filteredFoundList.push(currRow);
        }
      }
    }else{
      filteredFoundList = cloneDeep(currentFolderFileData.current);
    }
    //console.log('onHandleFilterData filteredFoundList :: '+filteredFoundList?.length);
    setFilteredTableData_(filteredFoundList);
    setViewFolderFiles(filteredFoundList);
  };
  async function searchListViewFunction(searchTerm) {
    //console.log('searchListViewFunction filteredTableData :: '+filteredTableData?.length);
    //console.log('filteredTableData.current :: '+filteredTableDataRow.current?.length);
    //console.log('searchListViewFunction totalFilterApplied :: '+totalFilterApplied);
    let orgData = (filteredTableData?.length > 0 || totalFilterApplied > 0)? cloneDeep(filteredTableData) : cloneDeep(folderFilesForSearch);
    //console.log(searchTerm,'orgData :: '+orgData?.length);
    let searchFoundList = orgData;
    if (orgData && searchTerm && searchTerm != null && searchTerm !== "") {
      searchFoundList = [];
      for (let el of orgData) {
        if ((el.name && el.name.toLowerCase().includes(searchTerm)) ||(el.title && el.title.toLowerCase().includes(searchTerm))) {
          searchFoundList.push(el);
        }
      }
    } else {
      searchFoundList = (filteredTableData?.length > 0 || totalFilterApplied > 0) ? cloneDeep(filteredTableData) : cloneDeep(currentFolderFileData.current);
    }
    console.log(searchTerm,'searchFoundList :: '+searchFoundList?.length);
    setViewFolderFiles(searchFoundList);
  };

  const handleClickMenu = (event, parms) => {
    setFileDataRow(parms.row);
    event.preventDefault();
    event.stopPropagation();
    setSelectedMenuRow(parms.row);
    setFileData(parms.row);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    //console.log("handleCloseMenu handleCloseMenu");
   //  setEditSetAssignPopup(false);
    setFileData(null);
    setAnchorEl(null);
   // setSelectedMenuRow(null);
  };

  const fileNameClick = (rowData) => {
    if (rowData.type === "file") {
      const reqBody = {
        fileUrl: rowData.File_URL__c,
        signedPath: rowData.signedPath,
      };
      axios.post(`${fetchUrl}/publish_fileUrl?token=${credential?.sessionId}&instanceUrl=${credential?.instanceUrl}`,reqBody)
      .then((res) => {
        let newFileUrl = res?.data?.url;
        //console.log("checking file url:>>", newFileUrl);
        if (res?.status === 200) {
          //console.log("parameter", rowData.fileUrl)
          // setSelectedViewData(parms?.row?.original)
          setFileDat(newFileUrl);
        }
      });
    }
    //console.log("row data is ", rowData);
    const imagesPdfExts = ["pdf", "jfif", "png", "jpeg", "jpg", "tiff", "tif"];
    if (imagesPdfExts.includes(rowData.fileExt)) {
      //console.log("row data is 2 ", JSON.stringify(rowData));
      //console.log("row data is 2 ", rowData.fileExt);
      setFileData(rowData);
      handleClickOpen();
    }
    // pdf and image files
    if (
      sourceFolderDetail?.type?.toLowerCase() === "plan" &&
      (!rowData.fileExt || rowData.fileExt === null)
    ) {
      //console.log("row data is ", rowData);
      rowData.fileExt = "pdf";
    }
    
    //   Videos
    const videoExts = ["mp4"];
    if (videoExts.includes(rowData.fileExt)) {
      //console.log("row data is ", rowData);
      setFileData(rowData);
      setOpenVideoPopup(true);
    }
  };
  const updateThumbnailToCurrentData = (attachId , thumUrl) => {
    
    //setSpinner(true);
    if (attachId) {
      toUpdateElementbyId(attachId ,thumUrl ,currentFolderFileData.current , setCurrentFolderFiles);
      toUpdateElementbyId(attachId ,thumUrl ,viewFolderFiles , setViewFolderFiles);
      toUpdateElementbyId(attachId ,thumUrl ,filteredTableData , setFilteredTableData_);
      toUpdateElementbyId(attachId ,thumUrl ,folderFilesForSearch , setFolderFilesForSearch);

      function toUpdateElementbyId(attchId , thubUrl ,_list , setToList){
        let _tempList = cloneDeep(_list);
        if (_tempList && _tempList?.length > 0) {
          const fInd = _tempList?.findIndex(
            (data) => data.id == attchId
          );
          if (fInd > -1) {
            _tempList[fInd].thumbnail = thubUrl;
            if(setToList){
              setToList(_tempList);
            }
          }
        }
        return _tempList;
      }
    }
    //setSpinner(false);
  };
  const updateStateTableDataWithRow = async (rowEle) => {
    
    setSpinner(true);
    if (rowEle.id) {
      toReplaceElementbyId(rowEle ,currentFolderFileData.current , setCurrentFolderFiles);
      toReplaceElementbyId(rowEle ,viewFolderFiles , setViewFolderFiles);
      toReplaceElementbyId(rowEle ,filteredTableData , setFilteredTableData_);
      toReplaceElementbyId(rowEle ,folderFilesForSearch , setFolderFilesForSearch);

      function toReplaceElementbyId(ele , _list , setToList){
        let _tempList = cloneDeep(_list);
        if (_tempList?.length > 0) {
          const fInd = _tempList?.findIndex(
            (data) => data.id == ele.id
          );
          if (fInd > -1) {
            _tempList[fInd] = ele;
            if(setToList){
              setToList(_tempList);
            } // init folder files
          }
        }
        return _tempList;
      }
    }
    setSpinner(false);
  };
  const [selected_ids, setSelectedIds] = useState([]);
  const onRowSelectionChange = (ids, isChecked) => {
    let selectedId = [];
    if (isChecked === true) {
      selectedId = selected_ids.length > 0 ? selected_ids : [];
      selectedId?.push(ids);
    } else {
      selectedId = selected_ids.length > 0 ? selected_ids.filter((item) => item !== ids) : [];
    }
    setSelectedIds(selectedId);
    const selectedIds = new Set(selectedId);

    const selectedRows = viewFolderFiles.filter((row) =>
      selectedIds.has(row.id.toString())
    );
    setSelectedItems(selectedRows);
    const selectedFiless = selectedRows.filter((row) => row.type == "file");
    setSelectedFiles(selectedFiless);
  };
  const onRowSelectionAllChange = (isChecked) => {
    setSelectedIds([]);
    let selectedId = [];
    if (isChecked === true) {
      selectedId = selected_ids.length > 0 ? [] : [];
      rowsPerPage.map((row) =>
        selectedId?.push(row.id)
      );
    } else {
      setSelectedIds([]);
    }
    setSelectedIds(selectedId);
    const selectedIds = new Set(selectedId);

    const selectedRows = viewFolderFiles.filter((row) =>
      selectedIds.has(row.id.toString())
    );
    setSelectedItems(selectedRows);
    const selectedFiless = selectedRows.filter((row) => row.type == "file");
    setSelectedFiles(selectedFiless);
  }
  const columns_react_table = [
    {
      id: "checkbox",
      accessor: "",
      Header: (
        <Checkbox
          style={{padding: "0px", marginLeft: "20px"}}
          sx={{padding: "0px", "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
          checked={selected_ids.length > 0}
          onChange={(e) =>
            onRowSelectionAllChange(e.target.checked)
          }
        />
      ),
      Cell: (rowInfo) => {
        let fileData = selectedFiles.filter(
          (row) => row.id == rowInfo?.row?.original?.id
        );

        let folderData = selectedItems.filter(
          (row) => row.id == rowInfo?.row?.original?.id
        );
        return (
          <Checkbox
            style={{padding: "0px", marginLeft: "20px"}}
            sx={{ "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
            type="checkbox"
            className="checkbox"
            checked={fileData.length > 0 || folderData.length > 0}
            onChange={(e) =>
              onRowSelectionChange(rowInfo?.row?.original?.id, e.target.checked)
            }
          />
        );
      },
    },
    {
      accessor: "name",
      Header: "Number",
      sortType: "basic",
      className: "hide-menu-dot",
      _sortItems: (prev, curr, columnId) => {
        return _sortItems(prev, curr, columnId);
      },
      width: 280,
      Cell: (params_) => {
        let params = { id: params_.row.original.id, row: params_.row.original };
        let attachId = params_.row.original.id;
      
        return (
          <span title={params_.row.original.title} className="tooltip1">
            <Box className="name_main" style={{ cursor: "pointer" }}>
              {params.row.type === "file" ? (
                <Box onClick={(e) => { e.stopPropagation(); fileNameClick(params.row); }} >
                  <img id={attachId+'_thumb_img'} src={params.row.thumbnail ? params.row.thumbnail :(attachmentThumbnail && attachmentThumbnail[attachId] ? attachmentThumbnail[attachId] :"icon.svg")} alt="loading_pdf" width="50" height="50"
                  onClick={(evt)=>{
                    evt.stopPropagation();
                    if(!attachmentThumbnail[attachId]){
                      genratePdfThumnail(attachId , params.row.url , true);
                    }
                  }}/>
                  <div style={{ marginLeft: "10px", color: "#505050" }}>
                    <b style={{ color: "black" }}>{params.row.Document_Number__c}</b>
                    <br></br>
                    {params_.row.original.title}
                  </div>
                </Box>
              ) : (
                <>
                  <Folder index={params.row.id} color="action" />
                  <Box onClick={() => { /*let objj = {id : params.row.id , row : params.row}; onRowClickFunc(params);*/ }} >
                    {params.row.name}
                  </Box>
                </>
              )}
            </Box>
          </span>
        );
      },
    },
    {
      accessor: "version",
      Header: "Version",
      sortType: "basic",
      //  flex: 1,
      width: 150,
      Cell: (params) => (
        <span title={params.row.original.version}>
          <VersionsDialog
            version={params.row.original.version}
            fileInfo={params.row.original}
          />
        </span>
      ),
    },
    {
      accessor: "set",
      Header: "Set Name",
      flex: 1,
      width: 150,
      sortType: "basic",
    },
    {
      accessor: "discipline",
      Header: "Discipline",
      width: 190,
      flex: 1,
      sortType: "basic"
    },
    {
      accessor: "markupCount",
      Header: "Markup",
      width: 160,
      flex: 1,
      sortType: "basic",
      Cell: (rowInfo) => {
        
        return (
          <div>
            <img
              style={{ marginRight: "6px" }}
              src={rowInfo.row.original.markupCount> 0 ? "/markupdark.svg" : "/markup.svg"}
              alt="Settig"
            />
            {rowInfo.row.original.markupCount}
          </div>
        );
      },
    },
    {
      accessor: "issue",
      Header: "Issue",
      width: 150,
      flex: 1,
      sortType: "basic",
      Cell: (rowInfo) => {
      const nonDraftIssues = rowInfo.row.original.issueList.filter(issue => issue.Status__c != 'Draft');
      const countNonDraftIssues = nonDraftIssues.length;
        return (
          <div>
            <img style={{ marginRight: "6px" }} src={countNonDraftIssues > 0 ? "/errordark.svg" : "/error.svg"} alt="Settig" />
            {countNonDraftIssues}
          </div>
        );
      },
    },
    {
      accessor: "reviewStatus",
      Header: "Review status",
      width: 180,
      sortType: "basic",
      Cell: (rowInfo) => {
        const [review, setReview] = useState(
          rowInfo.row.original.Document_Reviews__r?.records?.length > 0 &&
            rowInfo.row.original.Document_Reviews__r.records[0].Review__r
            ? rowInfo.row.original.Document_Reviews__r.records[0].Review__r
            : null
        );
        return (
          <div>
            {review && (
              <div>
                <div>{review.Stage__c === "Open" && "Pending Review"}</div>
                <div>{review.Stage__c === "Void" && "Declined"}</div>
                <div>
                  {review.Stage__c !== "Open" && review.Stage__c !== "Void" && (
                    <>
                      {review.Rejected_Document_Count__c > 0 && "Rejected"}
                      {!(review.Rejected_Document_Count__c > 0) ? (
                        <>
                          <img src="/approved.svg" alt="approved" /> Approved
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      },
    },

    {
      accessor: "updatedBy",
      Header: " Last Updated By",
      width: 220,
      flex: 1,
      sortType: "basic",
      Cell: (row) => {
        /* Add title */

        return (
          <div style={{color: "#505050"}}>
            <img src="/Ellipse 2.svg" alt="saaa" />
            {row.value}
          </div>
        );
      },
    },
    {
      accessor: "settings",
      Header: "settings",
      width: 60,
      flex: 1,
      sortType: "basic",
      marginLeft: "30px",
      Cell: (row) => {
        /* Add title */
        return <span title={row.value}>{row.value}</span>;
      },
    },
  ];
  const Shop_Drawings = [
    {
      id: "checkbox",
      accessor: "",
      Header: (
        <Checkbox
          style={{padding: "0px", marginLeft: "20px"}}
          sx={{padding: "0px", "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
          checked={selected_ids.length > 0}
          onChange={(e) =>
            onRowSelectionAllChange(e.target.checked)
          }
        />
      ),
      Cell: (rowInfo) => {
        let fileData = selectedFiles.filter(
          (row) => row.id == rowInfo?.row?.original?.id
        );

        let folderData = selectedItems.filter(
          (row) => row.id == rowInfo?.row?.original?.id
        );
        return (
          <Checkbox
          style={{padding: "0px",marginLeft: "20px"}}
            sx={{ "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
            type="checkbox"
            className="checkbox"
            checked={fileData.length > 0 || folderData.length > 0}
            onChange={(e) =>
              onRowSelectionChange(rowInfo?.row?.original?.id, e.target.checked)
            }
          />
        );
      },
    },
    {
      accessor: "name",
      Header: "Number",
      sortType: "basic",
      className: "hide-menu-dot",
      _sortItems: (prev, curr, columnId) => {
        return _sortItems(prev, curr, columnId);
      },
      width: 320,
      Cell: (params_) => {
        let params = { id: params_.row.original.id, row: params_.row.original };
        let attachId = params_.row.original.id;
        //const [thumbnail, setThumbnail] = React.useState( ( params.row.thumbnail ? params.row.thumbnail : (attachmentThumbnail && attachmentThumbnail[attachId] ?attachmentThumbnail[attachId] :undefined ) ) );
        return (
          <span title={params.row.name} className="tooltip1">
            <Box className="name_main" style={{ cursor: "pointer" }}>
              {params.row.type === "file" ? (
                <Box onClick={(e) => { e.stopPropagation(); fileNameClick(params.row); }} >
                  <img id={attachId+'_thumb_img'} src={params.row.thumbnail ? params.row.thumbnail :(attachmentThumbnail && attachmentThumbnail[attachId] ? attachmentThumbnail[attachId] :"icon.svg")} alt="loading_pdf" width="50" height="50"
                  onClick={(evt)=>{
                    evt.stopPropagation();
                    if(!attachmentThumbnail[attachId]){
                      genratePdfThumnail(attachId , params.row.url ,true);
                    }
                  }}/>
                  <div style={{ marginLeft: "10px", color: "#505050" }}>
                    <b style={{ color: "black" }}>{params.row.Document_Number__c}</b>
                    <br></br>
                    {params_.row.original.title}
                  </div>
                </Box>
              ) : (
                <>
                  <Folder index={params.row.id} color="action" />
                  <Box onClick={() => { /*let objj = {id : params.row.id , row : params.row};   onRowClickFunc(params);*/ }} >
                    {params.row.name}
                  </Box>
                </>
              )}
            </Box>

            {
              //menu not opening on clicked item
              /*<IconButton
              onClick={(e) => handleClickMenu(e, params)}
              className="menu_open_button"
              size="small"
              sx={{ ml: 2 }}
              aria-controls={"account-menu"}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
            >
              <MoreHoriz
                onClick={() => {
                  setCurrentFileId({
                    id: params.row.id,
                    name: params.row.name,
                    type: params.row.type ? params.row : "folder",
                  });
                }}
                color="warning"
              />
            </IconButton> */
            }
          </span>
        );
      },
    },
    {
      accessor: "version",
      Header: "Version",
      sortType: "basic",
      //  flex: 1,
      width: 100,
      Cell: (params) => (
        <span title={params.row.original.version}>
          <VersionsDialog
            version={params.row.original.version}
            fileInfo={params.row.original}
          />
        </span>
      ),
    },
    {
      accessor: "shopDrawing",
      Header: "Types",
      flex: 1,
      width: 150,
      sortType: "basic",
    },
    {
      accessor: "markupCount",
      Header: "Markup",
      width: 100,
      flex: 1,
      sortType: "basic",
      Cell: (rowInfo) => {
        //console.log("rowInfo.row.original", rowInfo.row.original.markupCount);
        /* Add title */
        return (
          <div>
            <img
              style={{ marginRight: "6px" }}
              src="/markup.svg"
              alt="Settig"
            />
            {rowInfo.row.original.markupCount}
          </div>
        );
      },
    },
    {
      accessor: "issue",
      Header: "Issue",
      width: 100,
      flex: 1,
      sortType: "basic",
      Cell: (rowInfo) => {
        //console.log("rowInfo.row.original", rowInfo.row.original.markupCount);
        /* Add title */
        return (
          <div>
            <img style={{ marginRight: "6px" }} src="/error.svg" alt="Settig" />
            0
          </div>
        );
      },
    },
    {
      accessor: "reviewStatus",
      Header: "Review status",
      width: 140,
      sortType: "basic",
      Cell: (rowInfo) => {
        const [review, setReview] = useState(
          rowInfo.row.original.Document_Reviews__r?.records?.length > 0 &&
            rowInfo.row.original.Document_Reviews__r.records[0].Review__r
            ? rowInfo.row.original.Document_Reviews__r.records[0].Review__r
            : null
        );
        return (
          <div style={{ display: "flex" }}>
            <img src="/approved.svg" alt="approved" />
            <div style ={{marginLeft: "5px", color: "#505050"}}>Approved</div>
          </div>
        );
      },
    },

    {
      accessor: "updatedBy",
      Header: " Last Updated By",
      width: 220,
      flex: 1,
      sortType: "basic",
      Cell: (row) => {
        /* Add title */

        return (
          <div style={{ display: "flex" }}>
            <img src="/Ellipse 2.svg" alt="saaa" />
            <div style ={{marginLeft: "5px", marginTop: "8px", color: "#505050"}}> 
            <div style ={{color: "#505050"}}>
            {row.value}
          </div>
            <div style ={{color: "#929292"}}>
              on May 1, 2023 14:35
            </div>
            </div>
          </div>
        );
      },
    },
    {
      accessor: "settings",
      Header: "settings",
      width: 40,
      flex: 1,
      sortType: "basic",
      marginLeft: "30px",
      Cell: (row) => {
        /* Add title */
        return <span title={row.value}>{row.value}</span>;
      },
    },
  ];
  function setCurrentFolderFiles(filesData, setInView) {
    //console.log("filesData1", filesData);
    //console.log("filesData1Lenght", filesData.length);
    currentFolderFileData.current = filesData;
    setFolderFiles(filesData);
    if (setInView == true) {
      setFilteredTableData_([]);
      setViewFolderFiles(filesData); // init folder files for view
    }
    return filesData;
  }
  async function getSourceFolder() {
    //console.log('props?.isPlan ',props?.isPlan ,'ProjectId',props?.projectId);
    let type = props?.isPlan ? "Plan" : "Project File";
    let curSourceFolder = sourceFolderDetail;
    let newSourceFolder;
    let subFoldermap;
    setIsLoading(true);
    if(!instanceRef.current){
      await loadSampleDocument();
    }
    await axios.get(`${fetchUrl}/sourceFolder/${type}/${props?.projectId}`)
    .then((res) => {
      if (res?.data?.status == 200) {
        const { tree } = res?.data;
        if (tree?.sourceFolder) {
          newSourceFolder = {
            id: tree?.sourceFolder?.Id,
            name: tree?.sourceFolder?.Name,
            type: tree?.sourceFolder?.Folder_Type__c,
          };
          //setSourceFolder(newSourceFolder); now the source folder is either contract or shop drawing
        }
        if (tree?.sub_folders?.length > 0) {
          for(let fol of tree?.sub_folders){
            if(!subFoldermap){
              subFoldermap = {};
            }
            if(!subFoldermap[fol.Name]){
              subFoldermap[fol.Name] = fol;
            }
          }
        }
      }
    }).catch((err) => {
      console.log("/sourceFolder_/", err);
    });
    setSubFolderMap(subFoldermap);
    if(subFoldermap){
      await setCurrentTabFolder(activeTabRef.current , subFoldermap);
    }
    setSpinner(false);
    setIsLoading(false);
    setFolderFileLoading(false);
  }
  async function setCurrentTabFolder(_activeTab=activeTab , subFoldermap = folderNameToIdMap) {
    console.log("setCurrentTabFolder", _activeTab  ,subFoldermap);
    let re;
    //console.log("sourceFolderDetail",sourceFolderDetail);
    let curSourceFolder = sourceFolderDetail;
    if((_activeTab == 1 || _activeTab == 0) && subFoldermap){
      //console.log("sourceFolderDetail1");
      setSourceFolder(null);
      let curfolderName = _activeTab == 0 ? Contract_Folder_Name : Shop_Folder_Name ;
      if(subFoldermap[curfolderName]){
        re = { id: subFoldermap[curfolderName].Id, name: subFoldermap[curfolderName].Name, type: subFoldermap[curfolderName].Folder_Type__c };
      }
      setSourceFolder(re);
      if(re){
        await refreshFolderFiles(re.id);
        setIsLoading(false);
      }
    }else if(!subFoldermap){
      resetCurrentFolderTableState();
      getSourceFolder();
    }
  }
  async function refreshFolderFiles(id) {
    //console.log("refreshFolderFiles ::", id);
    resetCurrentFolderTableState();
    await _getFolderFileApi(id, "refresh folder files");
  }
  async function resetCurrentFolderTableState() {
    handleClearAllFilters();
    setSelectedIds([]);
    setCurrentFolderFiles([], true);
    setFolderFilesForSearch([]);
    setSelectedItems([]);
    setSelectedFiles([]);
    generatePdfThumbnailMapPromise([]);
  }
  async function _getFolderFileApi(id, calledFrom) {
    //console.log("_getFolderFileApi_1", id, "calledFrom", calledFrom);
    if (calledFrom != "upload log refresh") {
      setIsLoading(true);
    }
    setFolderFileLoading(true);
    setShowLogs(false);
    setShowRecyclebin(false);
    //console.log("_getFolderFileApi folderid ::", id);
    let _id = id ? id : "a0DDb00000HBrvWMAT";
    await axios.get(`${fetchUrl}/folderfiles/${_id}`)
    .then(async (res) => {
      if (res?.status === 200) {
        const { files } = res?.data;
        //console.log("files ::", files);
        let withfile = [];
        //let withfile = [...data];
        if(files?.length > 0){
          for(let file of files){
            let fObj = await prepareAmazonRecordRow(file, credential) ;
            if(attachmentThumbnail && attachmentThumbnail[fObj.id]){
              fObj.thumbnail = attachmentThumbnail[fObj.id];
            }
            withfile.push(fObj);
          }
        }
        setCurrentFolderFiles(withfile, true);
        setFolderFilesForSearch(withfile);
        generatePdfThumbnailMapPromise(withfile);
        //console.log("withfile2", withfile);
      }
    }).catch((err) => {
      console.log(err);
      setSpinner(false);
    });
    setFolderFileLoading(false);
    setSpinner(false);
    if (calledFrom != "upload log refresh") {
      setIsLoading(false);
    }
  }

  let folderFileInterval = useRef(null);
  let interValTime = 10 * 1000;
  const refreshAndRedirectToFolder = async (id, pageCount) => {
    //called from uploadlog
    await openCurrentFolder({ id: id, stopLoading: true });
    if (folderFileInterval.current) {
      clearInterval(folderFileInterval.current);
    }
    setTimeout(async function () {
      setFolderFileLoading(true);
      let coun = 0;
      let countLimit = Number((pageCount > 1 ? pageCount : 0) + 1);
      folderFileInterval.current = setInterval(async () => {
        if (countLimit == coun) {
          if (folderFileInterval.current) {
            clearInterval(folderFileInterval.current);
          }
          return;
        } else {
          setTimeout(async function () {
            coun++;
            await _getFolderFileApi(id, "upload log refresh");
          }, 5000);
        }
      }, 15000);
    }, 10000);
  };
  const [openpopup, setOpen] = useState(false);
  const [openVideoPopup, setOpenVideoPopup] = useState(false);

  const handleClickOpen = () => {
    //console.log("setopen true " + pdfopenfiledata?.url)
    setOpen(true);
  };

  const handleCloseVideo = () => {
    setFileData(null);
    setOpenVideoPopup(false);
  };

  const handleClose = () => {
    setOpen(false);
    setFileData(null);
    getSourceFolder();
    setCurrentTabFolder(activeTab, folderNameToIdMap);
    if (sourceFolderDetail?.id) {
      //refreshFolderFiles(sourceFolderDetail.id);
    }
  };

  const downloadFile = async ( url, fileName, fileExt, includeMarkup, instantJSONMarkup ) => {
    if ( sourceFolderDetail?.type?.toLowerCase() === "plan" && (!fileExt || fileExt === null) ) {
      fileExt = "pdf";
    }
    if (!fileName.toLowerCase().endsWith("." + fileExt.toLowerCase())) {
      fileName += "." + fileExt.toLowerCase();
    }
    //console.log('downloadFile url',url);
    if ( fileName.toLowerCase().endsWith(".pdf") || sourceFolderDetail?.type?.toLowerCase() === "plan" ) {
      let confi = {
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        document: url,
        headless: true,
      };
      if (includeMarkup && instantJSONMarkup) {
        let listAA = JSON.parse(instantJSONMarkup).annotations;
        for (let ann of listAA) {
          if (ann.customData) {
            ann.customData.source = "export";
          }
        }
        let annotationList = listAA;
        confi.instantJSON = {
          format: "https://pspdfkit.com/instant-json/v1",
          annotations: annotationList,
        };
      }
      let PSPDFKit = await import("pspdfkit");
      let instance = await PSPDFKit.load(confi);
      await instance.exportPDF().then((buffer) => {
        const blob = new Blob([buffer], { type: "application/pdf" });
        const fName = fileName;
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fName);
        } else {
          const objectUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = objectUrl;
          a.style = "display: none";
          a.download = fName;
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(objectUrl);
          document.body.removeChild(a);
        }
      });
    } else {
      downloadMedia(url, fileName);
    }
  };

  const downloadMedia = (url, fileName) => {
    const a = document.createElement("a");
    a.href = url;
    a.style.display = "none";
    a.download = `${fileName}`;
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const browserWarningDialogOpen = () => {
    setBrowserWarningOpen(true);
  };
  const browserWarningDialogClose = () => {
    setBrowserWarningOpen(false);
  };

  const downloadSeletedFiles = async () => {
    browserWarningDialogClose();
    selectedFiles.forEach(async (f) => {
      await downloadFile( f.url, f.name, f.fileExt, f.markupJSON );
    });
  };
  const menuExportClick = async () => {
    if (selectedMenuRow?.url && selectedMenuRow?.name) {
      await downloadFile( selectedMenuRow.url, selectedMenuRow.name, selectedMenuRow.fileExt, selectedMenuRow.markupJSON );
    }
  };

  const onRowClickFunc = async (parma) => {
    if (parma) {
      if (parma?.row?.filePath) {
        fileNameClick(parma?.row);
      } else {
        openCurrentFolder({ id: parma?.id });
      }
    }
  };
  const openCurrentFolder = async (selectedFolder) => {
    //console.log('openCurrentFolder');
    setFolderFileLoading(true);
    if (!selectedFolder || !selectedFolder.stopLoading) {
      setIsLoading(true);
    }
    //console.log("open current folder ::", selectedFolder);
    //console.log("resTreeData", resTreeData);
    /*await setFolderTreeOnView(selectedFolder, resTreeData);*/
  };
  const deleteSelectedItems = async () => {
    console.log("deleteSelectedItems :: ");
    if (selectedItems.length > 0) {
      setSpinner(true);
      let k = 0;
      Promise.all(selectedItems).then((values) => {
        values.forEach(async (f) => {
          await deleteItemStart(f.type, f.id).catch((err) => {
            console.log(err);
          });
          //console.log('k ',k);
          k++;
          if (k == selectedItems.length) {
            setSelectedMenuRow(null);
            toast.success("Items Deleted");
            if (sourceFolderDetail?.id) {
              await refreshFolderFiles(sourceFolderDetail.id);
              //console.log("dcsww ::",selectedItems);
            }
            setSpinner(false);
          }
        });
      });
    }
  };
  async function onClickDeleteItems() {
    setDeleteOpen(false);
    if (actionCalledFor == "multiple") {
      await deleteSelectedItems();
    } else if (actionCalledFor == "basic-menu") {
      await deleteMenuItem();
    }
  }
  async function deleteMenuItem() {
    setSpinner(true);
    console.log("selectedMenuRow", selectedMenuRow);
    await deleteItemStart(selectedMenuRow.type, selectedMenuRow.id)
      .then(async () => {
        setSelectedMenuRow(null);
        toast.success("Item Deleted");
        if (sourceFolderDetail?.id) {
          await refreshFolderFiles(sourceFolderDetail.id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setSpinner(false);
  }
  async function deleteItemStart(type, itemId) {
    await axios.put(`${fetchUrl}/recycle/${type}/${itemId}`);
  }
  async function closeMove() {
    setmovePopup(false);
    handleCloseMenu();
  }
  async function onClickMoveItems(moveFolderId) {
    setmovePopup(false);
    if (actionCalledFor == "multiple") {
      await moveSelectedItems(moveFolderId);
    } else if (actionCalledFor == "basic-menu") {
      await moveMenuItem(moveFolderId);
      handleCloseMenu();
    }
  }
  const moveSelectedItems = async (moveFolderId) => {
    //console.log("moveSelectedItems :: ");
    if (selectedItems.length > 0) {
      setSpinner(true);
      let k = 0;
      Promise.all(selectedItems).then((values) => {
        values.forEach(async (f) => {
          await moveItemStart(f.id, moveFolderId).catch((err) => {
            console.log(err);
          });
          //console.log('k ',k);
          k++;
          if (k === selectedItems.length) {
            setSelectedMenuRow(null);
            toast.success("Items Moved.");
            if (sourceFolderDetail?.id) {
              await refreshFolderFiles(sourceFolderDetail.id);
            }
            setSpinner(false);
          }
        });
      });
    }
  };
  async function moveMenuItem(moveFolderId) {
    setSpinner(true);
    await moveItemStart(selectedMenuRow.id, moveFolderId)
      .then(async () => {
        setSelectedMenuRow(null);
        toast.success("Item Moved");
        if (sourceFolderDetail?.id) {
          await refreshFolderFiles(sourceFolderDetail.id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setSpinner(false);
  }
  async function moveItemStart(itemId, moveFolderId) {
    await axios.put(
      `${fetchUrl}/move/${itemId}/${moveFolderId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`
    );
  }

  /**
   * Export files with markup
   */
  const browserWarningCheck = true;
  const [activeExportDialog, setActiveExportDialog] = useState(false);
  const [activeExportStep, setActiveExportStep] = useState(1);

  const handleOpenExportDialog = (eType) => {
    if (browserWarningCheck) {
      setActiveExportStep(2);
    } else {
      setActiveExportStep(2);
    }
    setActionCalledFor(eType);
    setActiveExportDialog(true);
  };

  const handleCloseExportDialog = () => {
    setActiveExportStep(1);
    setActiveExportDialog(false);
  };

  const gotoExportStepTwo = () => {
    setActiveExportStep(2);
  };
  const handleExportDownloadClick = async () => {
    //console.log('eType :: '+actionCalledFor);
    handleCloseExportDialog();
    if (actionCalledFor == "multiple") {
      await downloadSeletedFiles();
    } else if (actionCalledFor == "basic-menu") {
      await menuExportClick();
    }
  };
  const [completedFiles, setCompletedFiles] = useState([]);
  const [showUploadProgress, setUploadProgress] = useState(false);
  function _handleCloseProcess() {
    setCompletedFiles([]);
    setUploadProgress(false);
  }

  const handleShareClose = () => {
    setShareFiles(false);
    setShareOpen(false);
  };
  const handleReviewClose = (closeAndRefresh) => {
    setReview(false);
    setSelectedIds([]);
    if (closeAndRefresh == true && sourceFolderDetail?.id) {
      refreshFolderFiles(sourceFolderDetail.id);
    }
  };

  const handleClearAllFilters = () => {
    setViewFolderFiles(currentFolderFiles);
    setFilteredTableData_([]);
    setActiveFilterDropdown(undefined);
    setTypeActiveFilters([]);
    setVersionSetActiveFilters([]);
    setUpdatedByActiveFilters([]);
    setReviewStatusActiveFilters([]);
    setDisciplineActiveFilters([]);
  };
  const handleShareOpen = () => {
    setShareOpen(true);
    handleCloseMenu();
  };

  const hideFilterDropDowns = () => {
    setActiveFilterDropdown(undefined);
  };
  function onActiveTabChange(tabValue){
    if (tabValue == 0) {
      handleClearAllFilters();
      setShowShopDrawings(false);
      setShowRecyclebin(false);
      setShowLogs(false);
      setCurrentTabFolder(tabValue , folderNameToIdMap);
    } else if (tabValue == 1) {
      handleClearAllFilters();
      setShowShopDrawings(true);
      setShowLogs(false);
      setShowRecyclebin(false);
      setCurrentTabFolder(tabValue , folderNameToIdMap);
    } else if (tabValue == 2) {
      setShowShopDrawings(false);
      setShowLogs(true);
      setShowRecyclebin(false);
      setInstance_(null);
    } else if (tabValue == 3) {
      setShowShopDrawings(false);
      setShowRecyclebin(true);
      setShowLogs(false);
      setInstance_(null);
    }
    setActiveTab_(tabValue);
  }
  function setActiveTab_(tabValue){
    activeTabRef.current = tabValue;
    setActiveTab(tabValue);
    if(!instanceRef.current){
      setTimeout(()=>{
        loadSampleDocument();
      } , 500);
    }
  }
  return (
    <DataTableDesign>
      <div className="wrapper_main">
        <div className="table_head">
          <div className="header_heading">
            <h1 onClick={()=>{
              if (sourceFolderDetail?.id) {
                refreshFolderFiles(sourceFolderDetail.id);
              }
            }}>{props?.isPlan ? "Sheets" : "Files"}</h1>
          </div>
          <div className="right_side">
            <Button
              variant="contained"
              component="button"
              className="btn btn-blue"
              onClick={() => navigate("/add_sheets" , {state:{activeTabId: activeTab}})}
            >
              {props?.isPlan ? "Add Sheets" : "Add Files"}
            </Button>
          </div>
        </div>
        <div className="tab_wrapper" style={{ marginLeft: "16px" }}>
          <Tabs
            value={activeTab}
            textColor="primary"
            indicatorColor="primary"
            onChange={(event, newValue) => {
              onActiveTabChange(newValue);
            }}
          >
            <Tab label="Contract Drawings" />
            <Tab label="Shop Drawings" />
            <Tab
              label={
                <div className="publish-logs">
                  <div className="text-and-kpi">
                    <div className="label poppins-normal-blue-14px">
                      {" "}
                      Published Log
                    </div>
                    <div className="kpi">
                      <div className="x1 poppins-normal-white-10px"> {readyForReviewCount} </div>
                    </div>{" "}
                  </div>
                </div>
              }
            />
            <Tab label="Deleted Sheets" />
          </Tabs>
        </div>

        {/* {console.log("showlogs", showlogs, "viewFolder Files", viewFolde rFiles)} */}
        {showlogs === true || showRecyclebin === true ? (
          <>
            {showlogs === true ? (
              <UploadLogs
                _filesReload={refreshAndRedirectToFolder}
                credential={credential}
                setActiveTab={onActiveTabChange}
                activeTab={activeTab}
                setShowLogsData={() => {
                  //console.log('setShowLogsData called');
                  getUnPublishBinderCount();
                  setShowLogs(false);
                  setIsLoading(true);
                }}
                getCountById={() => {
                  getUnPublishBinderCount();
                }}
                showInFolder={(selectedFolder) => {
                  openCurrentFolder(selectedFolder);
                  setShowLogs(false);
                }}
                readyForReviewCount
                project_Id={props?.projectId}
              />
            ) : (
              <RecycleBin
                credential={credential}
                props={props}
                setActiveTab={onActiveTabChange}
                activeTab={activeTab}
                _reloadRecycle={setShowRecyclebin}
                handleCloseBin={() => {
                  setShowRecyclebin(false);
                }}
              />
            )}
          </>
        ) : (
          <>
            <div ref={pdfContainerRef} style={{display :'none'}}></div>
            <div className="table_head main_table" onClick={hideFilterDropDowns}>
              <div className="left_side">
                {selected_ids.length > 0 && (
                  <div className="buttons">
                    <img className="close" src="close.svg" alt="close" />
                  </div>
                )}
                <div
                  style={{ borderRight: "1px solid #EAEAEA", width: "100px" }}
                >
                  <h2>{selected_ids.length} Selected</h2>
                </div>
                <div
                  style={{
                    borderRight: "1px solid #EAEAEA",
                    borderTop: "1px solid #EAEAEA",
                    borderBottom: "1px solid #EAEAEA",
                    borderLeft: "1px solid #EAEAEA",
                    display: "flex",
                  }}
                >
                  {selectedFiles.length > 0 && (
                    <div style={{ borderRight: "1px solid #EAEAEA" }}>
                      <Tooltip
                        title="Export"
                        placement="top"
                        PopperProps={{ className: "version-action-tooltip" }}
                        arrow
                      >
                        <IconButton
                        sx={{borderRadius: 0}}
                          onClick={() => {
                            handleOpenExportDialog("multiple");
                          }}
                        >
                          <img src="/download.svg" alt="download" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {selectedItems.length > 0 && (
                    <div style={{ borderRight: "1px solid #EAEAEA" }}>
                      <Tooltip
                        title="Share"
                        placement="top"
                        PopperProps={{ className: "version-action-tooltip" }}
                        arrow
                      >
                        <IconButton
                        sx={{borderRadius: 0}}
                          onClick={() => {
                            setActionCalledFor("multiple");
                          //  setDeleteOpen(true);
                          }}
                        >
                          <img src="/share.svg" alt="share" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {selectedFiles.length > 0 && (
                    <div style={{ borderRight: "1px solid #EAEAEA" }}>
                      <Tooltip
                        title="Delete"
                        placement="top"
                        PopperProps={{ className: "version-action-tooltip" }}
                        arrow
                      >
                        <IconButton
                        sx={{borderRadius: 0}}
                          onClick={() => {
                            setActionCalledFor("multiple");
                            setDeleteOpen(true);
                          }}
                        >
                          <img src="/delete.svg" alt="download" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {selectedFiles.length > 0 && (
                    <div style={{ borderRight: "1px solid #EAEAEA" }}>
                      <Tooltip
                        title="Edit set"
                        placement="top"
                        PopperProps={{ className: "version-action-tooltip" }}
                        arrow
                      >
                        <IconButton
                        sx={{borderRadius: 0}}
                          onClick={(e) => {
                            setEditSetAssignPopup(true);
                          }}
                        >
                          <img src="/Pencil.svg" alt="download" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {selectedFiles.length > 0 && (
                    <div style={{ borderRight: "1px solid #EAEAEA" }}>
                      <Tooltip
                        title="Download Source File"
                        placement="top"
                        PopperProps={{ className: "version-action-tooltip" }}
                        arrow
                      >
                        <IconButton
                        sx={{borderRadius: 0}}
                          onClick={() => {
                            setReview(true);
                          }}
                        >
                          <img src="/download source.svg" alt="download" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {selectedFiles.length > 0 && (
                    <div style={{ borderRight: "1px solid #EAEAEA" }}>
                      <Tooltip
                        title="Submit for Review"
                        placement="top"
                        PopperProps={{ className: "version-action-tooltip" }}
                        arrow
                      >
                        <IconButton
                        sx={{borderRadius: 0}}
                          onClick={() => {
                            setReview(true);
                          }}
                        >
                          <img src="/submit for review.svg" alt="download" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {selectedFiles.length > 1 && selectedFiles.length < 3 && (
                    <Tooltip
                      title="Compare"
                      placement="top"
                      PopperProps={{ className: "version-action-tooltip" }}
                      arrow
                    >
                      <IconButton
                      sx={{borderRadius: 0}}
                        onClick={() => {
                          setCompareFiles(true);
                        }}
                      >
                        <img src="/compare.svg" alt="download" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="right_side">
                <TextField
                  sx={{"& .MuiInputBase-root": {color: "#505050", height: '37px'}}}
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  className="Searchbar-field"
                  placeholder="Search Sheet Number"
                  onClick={hideFilterDropDowns}
                  value={searchText}
                  onChange={(e) => {
                    //convert input text to lower case
                    var lowerCase = e.target.value.toLowerCase();
                    if (!lowerCase || lowerCase == null || lowerCase === "") {
                      searchListViewFunction(lowerCase);
                    }
                    setSearchText(lowerCase);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      // Do code here
                      e.preventDefault();
                      var lowerCase = e.target.value.toLowerCase();
                      //console.log('searchText ',searchText , 'lowerCase',lowerCase);
                      searchListViewFunction(searchText);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
                <div className={totalFilterApplied > 0 ? (filterOptionsVisible ? "grid-11" : "grid-111") : (filterOptionsVisible ? "grid-1" : "grid") } onClick={() => { showFilterOptions(!filterOptionsVisible); }}>
                  <img className="filter2" src="/filter2 (1).svg" alt="filter2"/>
                  <div className="label-11 poppins-normal-abbey-14px">
                    Filters {totalFilterApplied > 0 &&<>{' ('+totalFilterApplied+')'}</>}
                  </div>
                  {!filterOptionsVisible && totalFilterApplied > 0 && (
                    <div className={filterOptionsVisible ? "grid-1" : "grid-2"} onClick={() => { handleClearAllFilters(); }}>
                      <div className="clear1">
                        <img className="clear" src="close.svg" alt="close" />
                      </div>
                    </div>
                  )}
                </div>

                <div className="d-flex">
                  <IconButton
                    className={!isGridView ? "list_view active" : "list_view"}
                    onClick={() => setIsGridView(false)}
                  >
                    <GiHamburgerMenu />
                  </IconButton>
                  <IconButton
                    className={isGridView ? "grid_view active" : "grid_view"}
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsGridView(true)}
                  >
                    <GridViewIcon />
                  </IconButton>
                </div>
              </div>
            </div>
            <div className="bottom_area">
                {filterOptionsVisible && (
                  <div className="filters">
                    {showShopDrawings &&
                      <DropdownFilter
                      credential={credential}
                      dropLabel='Shop Drawing Type'
                      options={shopDrawingTypeOptions}
                      activeFilters={typeActiveFilters}
                      isValueArray={true}
                      activeFilterDropdown={activeFilterDropdown}
                      setActiveFilterDropdown={setActiveFilterDropdown}
                      onFilterChange={async (newValues)=>{
                        processTofilter_.current = true;
                        setTypeActiveFilters(newValues);
                      }}
                    />}
                    {!showShopDrawings &&<>
                      <DropdownFilter
                        credential={credential}
                        dropLabel='Set Name'
                        options={setOptions}
                        activeFilters={versionSetActiveFilters}
                        activeFilterDropdown={activeFilterDropdown}
                        setActiveFilterDropdown={setActiveFilterDropdown}
                        onFilterChange={async (newValues)=>{
                          processTofilter_.current = true;
                          setVersionSetActiveFilters(newValues);
                        }}
                      />
                      <DropdownFilter
                        credential={credential}
                        dropLabel='Discipline'
                        options={disciplineOptions}
                        activeFilterDropdown={activeFilterDropdown}
                        setActiveFilterDropdown={setActiveFilterDropdown}
                        activeFilters={disciplineActiveFilters}
                        onFilterChange={async (newValues)=>{
                          processTofilter_.current = true;
                          setDisciplineActiveFilters(newValues);
                        }}
                      />
                    </>}
                    <DropdownFilter
                      credential={credential}
                      dropLabel='Review Status'
                      options={reviewStageOptions}
                      isValueArray={true}
                      activeFilterDropdown={activeFilterDropdown}
                      setActiveFilterDropdown={setActiveFilterDropdown}
                      activeFilters={reviewStatusActiveFilters}
                      onFilterChange={async (newValues)=>{
                        processTofilter_.current = true;
                        setReviewStatusActiveFilters(newValues);
                      }}
                    />
                    <DropdownFilter
                      credential={credential}
                      dropLabel='Updated By'
                      options={credential?.userList}
                      activeFilterDropdown={activeFilterDropdown}
                      setActiveFilterDropdown={setActiveFilterDropdown}
                      activeFilters={updatedByActiveFilters}
                      onFilterChange={async (newValues)=>{
                        processTofilter_.current = true;
                        console.log('updatedByActiveFilters ::',newValues);
                        setUpdatedByActiveFilters(newValues);
                      }}
                    />
                    {totalFilterApplied > 0 && <>
                      <div className="tertiary" onClick={handleClearAllFilters}>
                        <img className="reset" src="reset.svg" alt="reset" />
                        <div className="filters">Clear All</div>
                      </div>
                    </>}
                  </div>
                )}
            </div>
            <div>
              {showSpinner && <Spinner />}
              {deleteOpen && (
                <ConfirmDialog
                onCancel={()=>{
                  setDeleteOpen(false);
                }}
                onAccept={()=>{
                  onClickDeleteItems()
                }}
                  DialogData={confirmDialogData}
                />
              )}
              {showShopDrawings == true && (
                <>
                  {folderFileLoading && (
                    <Box className="custom-alert custom-alert-info">
                      Folder Files is loading.
                    </Box>
                  )}
                  {compareFiles && <CompareFiles
                    open={compareFiles}
                    setCompareFiles={setCompareFiles}
                    selectedFiles={selectedFiles}
                  />}
                  <Box className="react_table_new">
                    <ShopDrawings
                      columns_react_table={Shop_Drawings}
                      rowss={viewFolderFiles}
                      handleClickMenu={handleClickMenu}
                      openMenu={openMenu}
                      setCurrentFileId={setCurrentFileId}
                      selectedIds={selected_ids}
                      setRowsPerPage={setRowsPerPage}
                      listCount={currentFolderFileData.current?.length}
                    />
                  </Box>
                </>
              )}
              {activeTab == 0 && viewFolderFiles &&(
                  <>
                    {!isGridView ? (
                      <>
                        {folderFileLoading && (
                          <Box className="custom-alert custom-alert-info">
                            Folder Files is loading.
                          </Box>
                        )}
                        {compareFiles && 
                          <CompareFiles 
                          open={compareFiles}
                          setCompareFiles={setCompareFiles}
                          selectedFiles={selectedFiles}/>
                        }
                        <Box className="react_table_new">
                          {loading && <Spinner /> }
                          <ReactDataTable
                            columns_react_table={columns_react_table}
                            rowss={viewFolderFiles}
                            handleClickMenu={handleClickMenu}
                            openMenu={openMenu}
                            setCurrentFileId={setCurrentFileId}
                            setRowsPerPage={setRowsPerPage}
                            selectedIds={selected_ids}
                            listCount={currentFolderFileData.current?.length}
                          />
                        </Box>
                      </>
                    ) : (
                      <GridViewComponent
                        viewFolderFiles={viewFolderFiles}
                        onRowClickFunc={onRowClickFunc}
                        onRowSelectionChange={onRowSelectionChange}
                      />
                    )}
                  </>
                )}
            </div>
            {pdfopenfiledata?.url && openpopup && (
              <ShowPdf
                viewFolderFiles={currentFolderFiles}
                credential={credential}
                pdfopenfiledata={pdfopenfiledata}
                setOpen={setOpen}
                open={openpopup}
                handleClickOpen={handleClickOpen}
                updateTableState={updateStateTableDataWithRow}
                handleClose={()=>{
                  setOpen(false);
                  setFileData(null);
                }}
              />
            )}
            {pdfopenfiledata?.url && openVideoPopup && (
              <ShowVideoDialog
                filedata={pdfopenfiledata}
                open={openVideoPopup}
                handleClose={handleCloseVideo}
              />
            )}
            {open && (
              <DialogComponent
                dialogProp={{
                  opened: open,
                  classFor:
                    selectedTemplate.length > 0 || templateCheck
                      ? "imgpdf"
                      : "",
                  fullScreen: false,
                  handleClose: () => _handleClose(),
                  titleContent: (
                    <DialogStepper
                      handleNext={handleNext}
                      activeStep={activeStep}
                    >
                      <div className="continueButton">
                        {activeStep === 0 ? (
                          <>
                            <Button
                              loading={buttonloading}
                              sx={{ marginRight: "10px" }}
                              className="contained"
                              disabled={
                                !(selectedRowData?.length > 0) ||
                                activeStep === 1 ||
                                activeStep === 2
                              }
                              onClick={() => {
                                handleNext("continue");
                              }}
                            >
                              Continue
                            </Button>
                            {/* <Button
                            loading={buttonloading}
                            className="contained"
                            disabled={
                              !(selectedRowData?.length > 0) ||
                              activeStep == 1 ||
                              activeStep == 2
                            }
                            onClick={() => {
                              handleNext("continoueOffline");
                            }}
                          >
                            Continue Offline
                          </Button> */}
                          </>
                        ) : selectedTemplate.length === 0 && !templateCheck ? (
                          <>
                            <Button
                              className="contained"
                              disabled={!newTemplateName}
                              onClick={() =>
                                activeStep === 1 && saveNewTemplate()
                              }
                            >
                              continue
                            </Button>
                          </>
                        ) : activeStep === 1 ? (
                          <>
                            {!popupLoader && (
                              <Button
                                variant="contained"
                                disabled={
                                  !(
                                    selectedSplitData?.length > 0 &&
                                    duplicateAvailable == false
                                  )
                                }
                                onClick={() => {
                                  saveSplit();
                                }}
                              >
                                Upload {duplicateAvailable}
                              </Button>
                            )}
                            {selectedTemplate.length > 0 &&
                              activeStep !== 0 &&
                              fileUrl !== "" && (
                                <LoadingButton
                                  loading={miniMizeLoading}
                                  style={{ marginLeft: 2 }}
                                  loadingPosition="end"
                                  loadingIndicator="Loading�"
                                  onClick={handleMinimize}
                                  variant="contained"
                                >
                                  Save for Later
                                </LoadingButton>
                              )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </DialogStepper>
                  ),
                }}
              >
                {/*<span>activeStep :: {activeStep} , selectedTemplate.length :: {selectedTemplate.length} , templateCheck :: {templateCheck ? <>true</> : <>false</>}<br/>
              </span>*/}
                {pdfFile && activeStep === 0 && (
                  <div className="PDF-viewer">
                    {popupLoader ? (
                      <Box className="LOader_club">
                        <Loader />
                      </Box>
                    ) : (
                      <PdfViewerComponent
                        document={pdfFile}
                        setSelectedRowData={setSelectedRowData}
                        handleSelect={(data) => _handleSelect(data)}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        handleUploadFile={_handleUploadFile}
                        credential={credential}
                        selectedradio={selectedradio}
                        setSelectedRadio={setSelectedRadio}
                        setUploadWithoutSet={setUploadWithoutSet}
                        {...props}
                      />
                    )}
                  </div>
                )}

                {activeStep === 1 && (
                  <ImagesCss>
                    {selectedTemplate.length > 0 || templateCheck ? (
                      <>
                        {popupLoader ? (
                          <Box
                            className="LOader_club"
                            sx={{ height: 400, width: "100%" }}
                          >
                            <Loader />
                          </Box>
                        ) : (
                          <div>
                            {progress !== 100 ? (
                              <div className="main-prog">
                                <div className="processing_content">
                                  <h3>Processing your document</h3>
                                </div>
                                <div className="content">
                                  <p>
                                    If you uploding PDF files , we're scanning
                                    all your sheets and locating sheet
                                    information based on the selected
                                  </p>
                                  <p>
                                    layout template. If you haven't selected a
                                    layout template , you'll choose or create on
                                    in the next step.
                                  </p>
                                </div>
                                <div className="progrssSection_div">
                                  <ProgressBar progress={progress} />
                                </div>
                              </div>
                            ) : (
                              <>
                                {/* {isloading ? (
                                <Box sx={{ height: 400, width: "100%" }}>
                                  <Box className="LOader_club">
                                    <Loader />
                                  </Box>
                                </Box>
                              ) : ( */}
                                <DataGrid
                                  className="TextareaAndNumerTable"
                                  // rows={pdfImages?.sort((a,b) =>  a.id - b.id)}
                                  rows={pdfImages}
                                  columns={columnsB}
                                  pageSize={100}
                                  rowsPerPageOptions={[]}
                                  checkboxSelection
                                  columnVisibilityModel={{
                                    id: false,
                                  }}
                                  onRowSelectionModelChange={(ids) => {
                                    const selectedSplitData = pdfImages?.filter(
                                      (row) => ids.includes(+row?.id)
                                    );
                                    setSelectedSplitData(selectedSplitData);
                                    setPreSelectedPdfImages(ids);
                                  }}
                                  hideFooter={true}
                                  autoPageSize={true}
                                  disableRowSelectionOnClick
                                  rowHeight={160}
                                  rowSelectionModel={preSelectedPdfImages}
                                  disableVirtualization
                                />
                                {/* )} */}
                              </>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {popupLoader ? (
                          <Box
                            className="LOader_club"
                            sx={{ height: 400, width: "100%" }}
                          >
                            <Loader />
                          </Box>
                        ) : (
                          <WithoutTemplateSection className="crop_pdf_container">
                            <div className="new_template_text_one">
                              <label htmlFor="tempalte_name">
                                Enter New Template Name
                              </label>
                              <input
                                type="text"
                                value={newTemplateName}
                                name="template_name"
                                onChange={(e) =>
                                  setNewTemplateName(e.target.value)
                                }
                              />
                              <label htmlFor="name">Name</label>
                              <input
                                type="text"
                                name="name"
                                value={templateName}
                                onChange={(e) => {}}
                              />
                              <label htmlFor="name">Number</label>
                              <input
                                type="text"
                                name="name"
                                value={templateNumber}
                                onChange={(e) => {}}
                              />
                            </div>
                            {progress !== 100 && (
                              <ProgressBar progress={progress} />
                            )}
                            <div
                              ref={containerRef}
                              style={{ width: "100%", height: "100vh" }}
                            />
                            <div className="slds-card__footer">
                              <button
                                style={{ justifyContent: "center" }}
                                className="slds-button"
                                onClick={() => _selectTitleAndNumber("title")}
                              >
                                {isSelectionTitleLoading ? (
                                  <CircularProgress color="inherit" />
                                ) : (
                                  "Select Title"
                                )}
                              </button>
                              <button
                                style={{ justifyContent: "center" }}
                                className="slds-button"
                                onClick={() => _selectTitleAndNumber("number")}
                              >
                                {isSelectionNumberLoading ? (
                                  <CircularProgress color="inherit" />
                                ) : (
                                  " Select Number"
                                )}
                              </button>
                            </div>
                          </WithoutTemplateSection>
                        )}
                      </>
                    )}
                  </ImagesCss>
                )}

                {activeStep === 3 && (
                  <div className="Finished">
                    <div>
                      <CheckCircleOutline />
                    </div>
                    <h3>finish</h3>
                    {setTimeout(() => {
                      _handleClose();
                    }, 4000)}
                  </div>
                )}
              </DialogComponent>
            )}
          </>
        )}

        {openMenu && (
          <>
            <Stack direction="row" spacing={2}>
              <Paper sx={{ height: 320, maxWidth: "100%" }}>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{ "aria-labelledby": "basic-button" }}
                >
                  <div
                    className="dropdown-list"
                    onClick={(e) => {
                      handleOpenExportDialog("basic-menu");
                      handleCloseMenu();
                    }}
                  >
                    <div className="text-with-icon-1">
                      <img className="share" src="/download.svg" alt="export" />{" "}
                      <div className="label-1 poppins-normal-abbey-14px">
                        {" "}
                        Export
                      </div>
                    </div>
                    <div className="text-with-icon-1">
                      <img className="share" src="share.svg" alt="share" />{" "}
                      <div className="label-1 poppins-normal-abbey-14px">
                        {" "}
                        Share
                      </div>
                    </div>
                    <div
                      className="text-with-icon-1"
                      onClick={(e) => {
                        setActionCalledFor("basic-menu");
                        setDeleteOpen(true);
                        handleCloseMenu();
                      }}
                    >
                      <img className="share" src="/delete.svg" alt="export" />{" "}
                      <div className="label-1 poppins-normal-abbey-14px">
                        {" "}
                        Delete
                      </div>
                    </div>
                    <div
                      className="text-with-icon-1"
                      onClick={(e) => {
                        setEditSetAssignPopup(true);
                      }}
                    >
                      <img className="pencil" src="/Pencil.svg" alt="Pencil" />{" "}
                      <div className="label-2 poppins-normal-abbey-14px">
                        Edit Set
                      </div>
                    </div>
                    <div className="text-with-icon-1">
                      <img
                        className="download-source"
                        src="/download source.svg"
                        alt="download source"
                      />
                      <div className="label-3 poppins-normal-abbey-14px">
                        {" "}
                        Download Source File
                      </div>
                    </div>
                    <div className="text-with-icon-1">
                      <img
                        className="submit-for-review"
                        src="submit for review.svg"
                        alt="submit for review"
                      />

                      <div className="submit-for-review-1 poppins-normal-abbey-14px">
                        Submit for Review
                      </div>
                    </div>{" "}
                  </div>
                </Menu>
              </Paper>
            </Stack>
          </>
        )}
        {activeExportDialog && (
          <ExportFileDialog
            setLoading={setLoading}
            open={activeExportDialog}
            activeStep={activeExportStep}
            handleClose={handleCloseExportDialog}
            gotoStepTwo={gotoExportStepTwo}
            downloadFiles={handleExportDownloadClick}
            selectedFiles={selectedFiles}
          />
        )}
        {showUploadProgress && (
          <FileUploadProgress
            uploadQueueFiles={completedFiles}
            _handleCloseProcess={_handleCloseProcess}
          />
        )}
        {editSetAssignPopup && (
          <AssignmentDialog
            credential={credential}
            setSpinner={setSpinner}
            setActiveTab={onActiveTabChange}
            setEditSetAssignPopup={(val)=>{
              refreshFolderFiles(sourceFolderDetail?.id);
              setEditSetAssignPopup(val)
              setSelectedMenuRow(null);
            }}
            selectedItems={selectedItems}
            attachRow={selectedMenuRow}
            editSetAssignPopup={editSetAssignPopup}
            handleClose={handleCloseMenu}
            updateTableState={updateStateTableDataWithRow}
            selected_ids={selected_ids}
          />
        )}
        {movePopup && (
          <MoveDialog
            projectId={props?.projectId}
            movePopup={movePopup}
            handleClose={closeMove}
            handleSave={onClickMoveItems}
            currentFolderName={sourceFolderDetail}
          />
        )}
        {shareOpen && (
          <ShareDialog
            open={shareOpen}
            handleClose={handleShareClose}
            currentFileId={currentFileId}
          />
        )}
        {shareFiles && (
          <ShareFiles
            open={shareFiles}
            handleClose={handleShareClose}
            currentFileId={selectedMenuRow}
          />
        )}
        {review && (
          <ReviewModel
            open={review}
            credential={credential}
            handleClose={handleReviewClose}
            selectedFiles={selectedFiles}
            folderName={sourceFolderDetail?.name}
            workflowTemplateOptions={workflowTemplateOptions}
          />
        )}
      </div>
    </DataTableDesign>
  );
}

export class Uploader {
  constructor(projectId, folderId, options) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.projectId = projectId;
    this.folderId = folderId;
    this.chunkSize = options.chunkSize || 1024 * 1024 * 5;
    // number of parallel uploads
    //console.log("options", options.file);
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.file = options.file;
    let fileName1 = this.file.name;
    let pos = fileName1.lastIndexOf(".");
    let fileExtension = fileName1.substring(pos + 1);
    if (fileExtension) {
      fileExtension = fileExtension.toLowerCase();
      if (!fileName1.toLowerCase().endsWith("." + fileExtension)) {
        fileName1 += "." + fileExtension;
      }
    }
    this.fileName = fileName1;
    this.fileExtension = fileExtension;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = null;
    this.fileKey = null;
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
  }

  // starting the multipart upload request
  start(handleFileProcess) {
    this.completeMethodCall = handleFileProcess;
    this.initialize();
  }

  async initialize() {
    try {
      // adding the the file extension (if present) to fileName
      // initializing the multipart request
      const videoInitializationUploadInput = new FormData();
      videoInitializationUploadInput.append("name", this.fileName);
      const initializeReponse = await axios.post(
        `${fetchUrl}/uploads/initializeMultipartUpload/${this.projectId}/${this.folderId}`,
        videoInitializationUploadInput
      );
      //data returned
      const returnData = initializeReponse.data;

      this.fileId = returnData.fileId;
      this.fileKey = returnData.fileKey;
      // retrieving the pre-signed URLs
      const numberOfparts = Math.ceil(this.file.size / this.chunkSize);

      const body = new FormData();
      body.append("fileId", this.fileId);
      body.append("fileKey", this.fileKey);
      body.append("parts", numberOfparts);
      const urlsResponse = await axios.post(
        `${fetchUrl}/uploads/getMultipartPreSignedUrls`,
        body
      );
      const newParts = urlsResponse.data.parts;
      this.parts.push(...newParts);
      this.sendNext();
    } catch (error) {
      await this.complete(error);
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;
    if (activeConnections >= this.threadsQuantity) {
      return;
    }
    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }
      return;
    }
    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);
      const sendChunkStarted = () => {
        this.sendNext();
      };
      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          this.parts.push(part);
          this.complete(error);
        });
    }
  }

  // terminating the multipart upload request on success or failure
  async complete(error) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
    } catch (error) {
      this.onErrorFn(error);
    }
  }

  // finalizing the multipart upload request on success by calling
  // the finalization API
  async sendCompleteRequest() {
    if (this.fileId && this.fileKey) {
      const body = new FormData();
      body.append("fileId", this.fileId);
      body.append("fileKey", this.fileKey);
      body.append("parts", JSON.stringify(this.uploadedParts));
      body.append("fileName", this.fileName);
      body.append("fileExt", this.fileExtension);
      await axios
        .post(
          `${fetchUrl}/uploads/finalizeMultipartUpload/${this.projectId}/${this.folderId}`,
          body
        )
        .then(async () => {
          toast.success("File Uploaded");
          this.completeMethodCall("success", this.file);
        })
        .catch((error) => {
          console.log(error);
          toast.error("File Uploaded Failed", this.file);
          this.completeMethodCall("failed");
        });
    }
  }
  sendChunk(chunk, part, sendChunkStarted) {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error("Failed chunk upload"));
            return;
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // calculating the current progress of the multipart upload request
  handleProgress(part, event) {
    if (this.file) {
      if (
        event.type === "progress" ||
        event.type === "error" ||
        event.type === "abort"
      ) {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      });
    }
  }

  // uploading a part through its pre-signed URL
  upload(file, part, sendChunkStarted) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        // - 1 because PartNumber is an index starting from 1 and not 0
        const xhr = (this.activeConnections[part.PartNumber - 1] =
          new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(
          this,
          part.PartNumber - 1
        );

        xhr.upload.addEventListener("progress", progressListener);

        xhr.addEventListener("error", progressListener);
        xhr.addEventListener("abort", progressListener);
        xhr.addEventListener("loadend", progressListener);

        xhr.open("PUT", part.signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // retrieving the ETag parameter from the HTTP headers
            const ETag = xhr.getResponseHeader("ETag");
            if (ETag) {
              const uploadedPart = {
                PartNumber: part.PartNumber,
                // removing the " enclosing carachters from
                // the raw ETag
                ETag: ETag.replaceAll('"', ""),
              };
              this.uploadedParts.push(uploadedPart);
              resolve(xhr.status);
              delete this.activeConnections[part.PartNumber - 1];
            }
          }
        };

        xhr.onerror = (error) => {
          reject(error);
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.onabort = () => {
          reject(new Error("Upload canceled by user"));
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.send(file);
      }
    });
  }

  onProgress(onProgress) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError) {
    this.onErrorFn = onError;
    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });
    this.aborted = true;
  }
}
export const prepareAmazonRecordRow = async (file, cred) => {
  //console.log("file ::", file?.Document_Reviews__r);
  let jsonMarkup = file?.Markup_Json__c;
  let markupJs = await getXMLJSFromMakrupXML(jsonMarkup, cred);
  let markupWrapper = getWrapperFromAnnotaitionXMLJSON(markupJs , cred);
  
  return {
    id: file?.Id,
    name: file?.Document_Number__c,
    title: file?.Document_Title__c,
    set: file.Set__c ? file.Set__r.Name : "-",
    setId: file.Set__c,
    version: file?.Document_File_Versions__r?.records?.length /*Record_Version__c */,
    lastUpdated: moment(file.LastModifiedDate).format("DD MMM YYYY HH:mm"),
    updatedBy: file.LastModifiedBy?.Name,
    updatedById: file.LastModifiedById,
    markupJSON: jsonMarkup,
    markupCount: markupWrapper.users_markup_counts,
    thumbnail: null,
    location: "",
    shopDrawing: file?.Shop_Drawing_Type__c,
    discipline: file.Discipline__c ? `${file.Discipline__r.Designator__c} (${file.Discipline__r.Name})`: "-",
    disciplineId: file.Discipline__c ,
    // reviewStatus: file?.Document_Reviews__r?.records[0].Review__r.Stage__c ? file?.Document_Reviews__r?.records[0].Review__r.Stage__c : "-",
    type: "file",
    folderId: file?.Folder__c,
    filePath: file?.Folder__r?.Folder_Path__c,
    amazonPath: file?.Folder__r?.S3_Folder_Path__c,
    attObj: file,
    url: file.url,
    fileExt: file?.File_Extension__c,
    ...file,
  };
};