import React from "react";
import moment from 'moment';
import dragIcon from 'assets/images/dragIcon.svg';
import showIcon from 'assets/images/showIcon.svg';
import hideIcon from 'assets/images/hideIcon.svg'

import Draggable from "react-draggable";
const FileVisibilityDialog = ({
  open,
  filesToCompare,
  handleClose,
  setDocVisibility,
  selectedFiles
}) => {
  return (
    <div style={{ position: "absolute", top: '2rem', left: "2rem" }}>
      {open && (
        <Draggable handle="#draggable-dialog-title" scale={1}>
          <div className="side-info">
            <div className="content" style={{width: "320px"}}>
              <div id = "draggable-dialog-title" className="side-header">
                <div className="title-and-close">
                  <div className="markups">Compared Document</div>
                  <div onClick={handleClose} className="close-wrapper">
                    <img
                      className="img"
                      alt="Close"
                      src="close.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="markup-toolbar">
                <div className="markup-toolbar-2">
                  <p className="text-wrapper-4">Please select a sheet to reposition</p>
                  <div className="actions">
                    <div className="drag-wrapper">
                      <img
                        className="img"
                        alt="Drag"
                        src={dragIcon}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="sheets" style={{width: "100%"}}>
                <div className="item" style={{width: "100%"}}>
                  <div className="content-2" style={{width: "100%"}}>
                    <div className="icon-and-details" style={{width: "92%"}}>
                      <div className="rectangle" />
                      <div className="tool-and-created" style={{width: "80%"}}>
                        <div className="text-wrapper-5" style={{width: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{selectedFiles[0]?.Document_Number__c} (V{selectedFiles[0]?.Version_Number__c ? selectedFiles[0]?.Version_Number__c : selectedFiles[0]?.version})</div>
                        <div className="text-wrapper-4">{selectedFiles[0].set}</div>
                        <div className="text-wrapper-4">{moment(selectedFiles[0].CreatedDate).format("MMM DD YYYY")}</div>
                      </div>
                    </div>
                    <div style={{width: "20%"}}>
                    <img
                      className="img"
                      alt="Show"
                      src={showIcon}
                    />
                    </div>
                  </div>
                </div>
                <div className="content-wrapper" style={{width: "100%"}}>
                <div className="content-2" style={{width: "100%"}}>
                    <div className="icon-and-details" style={{width: "92%"}}>
                      <div className="rectangle-2" />
                      <div className="tool-and-created" style={{width: "80%"}}>
                        <div className="text-wrapper-5" style={{width: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{selectedFiles[1].Document_Number__c} (V{selectedFiles[1]?.Version_Number__c ? selectedFiles[1]?.Version_Number__c : selectedFiles[1]?.version})</div>
                        <div className="text-wrapper-4">{selectedFiles[1].set}</div>
                        <div className="text-wrapper-4">{moment(selectedFiles[1].CreatedDate).format("MMM DD YYYY")}</div>
                      </div>
                    </div>
                    <div style={{width: "20%"}}>
                    <img
                      className="img"
                      alt="Show"
                      src={hideIcon}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      )}
    </div>
  );
};

FileVisibilityDialog.propTypes = {};

export default FileVisibilityDialog;